import { ISura } from '../_interface/sura';

export class Sura {
  _sura: ISura = {
    email: '',
    paid: false,
    customerId: '',
    ab: '',
    planId: ''
  }

  constructor() {
    if (localStorage.getItem('sura')) {
      this._sura = JSON.parse(this.decrypt(localStorage.getItem('sura') || ''));
    }
  }

  set paid(paid) {
    this._sura.paid = paid;
    this.save();
  }
  set ab(ab) {
    this._sura.ab = ab;
    this.save();
  }
  set planId(ab) {
    this._sura.planId = ab;
    this.save();
  }
  set customerId(customerId) {
    this._sura.customerId = customerId;
    this.save();
  }
  set email(email) {
    this._sura.email = email;
    this.save();
  }
  get ab() {
    return this._sura.ab;
  }
  get planId() {
    return this._sura.planId;
  }
  get paid() {
    return this._sura.paid;
  }
  get customerId() {
    return this._sura.customerId;
  }
  get email() {
    return this._sura.email;
  }

  save() {
    localStorage.setItem('sura', this.encrypt(JSON.stringify(this._sura)));
  }

  decrypt(data: string): any {
    try {
      return JSON.parse(unescape(atob(atob(atob(data)))));
    } catch (e) {
      try {
        return atob(atob(atob(unescape(data))));
      } catch (e) {

      }
    }
  }

  encrypt(data: any): string {
    try {
      return btoa(btoa(btoa(escape(JSON.stringify(data)))));
    } catch (e) {
      try {
        return btoa(btoa(btoa(escape(data))));
      } catch (err) {
        return '';
      }
    }
  }
}