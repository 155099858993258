<div class="template-colors template {{theme}}" *ngIf="dataLoaded">
  <div class="site-section main-container">
    <div class="card text-white rounded-0 b-0 content-image" [ngStyle]="{'background-image': 'url('+getCover()+')'}">
    </div>
    <div class="content-buttons" *ngIf="!isGuest">
      <button class="btn btn-uniko-sm uniko-secondary" (click)="onEdit()">
        <i class="material-icons">
          edit
        </i>
      </button>
    </div>
    <div class="event-title">
      <p class="grettings">{{ getEventString(couple.weddingData) }}</p>
      <div *ngIf="isCouple(couple.weddingData)" class="title">
        <div>{{ couple.weddingData.nameP1 }}</div>
        <div>&</div>
        <div>{{ couple.weddingData.nameP2 }}</div>
      </div>
      <div *ngIf="!isCouple(couple.weddingData)" class="title">
        <div>{{ couple.weddingData.nameP1 }}</div>
      </div>
      <h5 class="date" *ngIf="couple.weddingData.date">{{ couple.weddingData.date | date : 'dd.MM.yyyy' }}</h5>
      <p class="m-0 p-0 mt-3 state-text">{{ state | uppercase }}</p>
    </div>
  </div>
  <div class="site-supersection history-container">
    <app-info [isGuest]="isGuest" (edit)="onEdit()" [couple]="couple"></app-info>
  </div>
  <div class="site-section section-shadow col-auto p-0">
    <svg class="template-ornament cake-ornament" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 2'>
      <path d='M0 0 L64 2 L64 0 Z' />
    </svg>
    <svg class="template-ornament gold-ornament" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 2'>
      <path d='M0 0 C0 2 8 2 12 2 C18 2 32 0 40 0 C50 0 56 2 64 2 L64 0 Z' />
    </svg>
    <app-history [isGuest]="isGuest"></app-history>
  </div>
  <div class="site-section section-shadow col-auto p-0" *ngIf="isCouple(couple.weddingData)">
    <app-hotels [isGuest]="isGuest"></app-hotels>
  </div>
  <div class="site-supersection section-shadow cake-exclusive">
    <svg class="template-ornament cake-ornament flip-shadow" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 2'>
      <path d='M0 0 L0 2 L64 2 Z' />
    </svg>
    <div class="site-section col-auto">
      <app-gift-table></app-gift-table>
    </div>
    <div class="site-section section-shadow col-auto">
      <app-gallery [isGuest]="isGuest"></app-gallery>
    </div>
    <div class="site-section section-shadow col-auto">
      <app-questions [isGuest]="isGuest"></app-questions>
    </div>
  </div>
  <input #coverInput type="file" accept="image/*" id="cover" (change)="openCropper($event)" style="display: none;">
</div>

<app-modal [isOpen]="isOpen" (onClosed)="onClose()" (onAction)="update()" (lockedAction)="isOpenCropper">
  <div class="row test">
    <div class="col-md-12 1" *ngIf="!isOpenCropper">
      <div (click)="onUpload($event, 'cover')" class="mt-4 mb-4 A"
        style="float: left; width:100%; margin-right: 2.2rem !important;" *ngIf="!showCroppedImage">
        <i (click)="onUpload($event, 'cover')" class="fas fa-plus iconoAgregar"></i>
        <div [ngStyle]="{
                  'background-image': 'url('+cover+')'
              }" class="rounded-0 image-cover">
        </div>
      </div>
      <div (click)="onUpload($event, 'cover')" class="mt-4 mb-4 B"
        style="float: left; width:100%; margin-right: 2.2rem !important;" *ngIf="showCroppedImage">
        <i (click)="onUpload($event, 'cover')" class="fas fa-plus iconoAgregar"></i>
        <div [ngStyle]="{
                  'background-image': 'url('+croppedImage+')'
              }" class="rounded-0 image-cover">
        </div>
      </div>
    </div>
    <div class="col-md-12 2" *ngIf="isOpenCropper">
      <div class="text-center">
        <button class="btn mr-2" (click)="onCloseCropper()">Cancelar</button>
        <button class="btn btn-uniko" (click)="saveCropp()">Confirmar recorte</button>
      </div>
      <div class="mt-4 mb-4">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="3 / 1"
          [resizeToWidth]="1200" [resizeToHeight]='450' [cropperMinWidth]="1200" [cropperMinHeight]="450"
          [onlyScaleDown]="true" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)"
          (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
        </image-cropper>
      </div>
      
    </div>
    <div class="col-md-12" *ngIf="dataLoaded">
      <form [formGroup]="dataForm">
        <div class="row">
          <div class="{{isCouple(couple.weddingData)?'col-12 col-md':'col-12 col-md-6'}}">
            <div class="form-group text-left">
              <label class="label-control">{{ getEventString(couple.weddingData) }}</label>
              <input [class.submit]="submit" formControlName="name" type="name" class="form-control"
                placeholder="{{isCouple(couple.weddingData)?'Tu Nombre':'Nombre del festejado'}}">
            </div>
          </div>
          <div class="col-12 col-md-auto" *ngIf="isCouple(couple.weddingData)">
            <span class="text-div">&</span>
          </div>
          <div class="col-12 col-md" *ngIf="isCouple(couple.weddingData)">
            <div class="form-group">
              <label class="label-control"></label>
              <input [class.submit]="submit" formControlName="name2" type="text" maxlength="60" class="form-control"
                placeholder="Nombre de tu pareja">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12 col-lg-12">
            <div class="form-group text-left">
              <label class="label-control">Fecha y hora</label>
              <div class="row mx-0">
                <div class="col-12 col-md-6 p-2 site-datepicker-field">
                  <mat-form-field style=" height: 30px; border: 0px;">
                    <input matInput type="text" mask="d0/M0/0000" formControlName="shortDate" (change)="handleManualDateChange('input',$event)">
                    <input [matDatepicker]="sitePicker" type="hidden" formControlName="date" style="display: none;" (dateChange)="handleManualDateChange('event',$event)">
                    <mat-hint class="small text-special">MM/DD/AAAA</mat-hint>
                    <mat-datepicker #sitePicker></mat-datepicker>
                  </mat-form-field>
                  <button class="ml-1 btn calendar-button" (click)="sitePicker.open()"><i class="fa fa-calendar"></i></button>
                </div>
                <div class="col-12 col-md-6 p-2 site-time-picker">
                  <app-time-picker [time]="timeDate" (onChange)="handleTimeChange($event)"></app-time-picker>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12">
            <div class="form-group text-left">
              <label class="label-control">Título del evento</label>
              <input formControlName="title" type="text" class="form-control" placeholder="Título del evento">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12">
            <div class="form-group text-left">
              <label class="label-control">Descripción</label>
              <textarea class="form-control" formControlName="description"></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12">
            <div class="form-group text-left">
              <label class="label-control ">Dirección o lugar</label>
              <input ngx-google-places-autocomplete formControlName="placeName" autocorrect="off" autocapitalize="off"
                spellcheck="off" class="form-control" placeholder="Dirección"
                (onAddressChange)="handleAddressChange($event)" />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</app-modal>