export const COUNTRIES_PHONE = [
    {"codigo": "004", "phone": "0093", "iso": "AF", "iso3": "AFG", "name": "Afeganistão", "nameFormal": "República Islâmica do Afeganistão"},
      {"codigo": "008", "phone": "0355", "iso": "AL", "iso3": "ALB", "name": "Albânia", "nameFormal": "República da Albânia"},
      {"codigo": "012", "phone": "0213", "iso": "DZ", "iso3": "DZA", "name": "Algéria", "nameFormal": "República Democrática Popular da Algéria"},
      {"codigo": "016", "phone": "1684", "iso": "AS", "iso3": "ASM", "name": "Samoa Americana", "nameFormal": "Território de Samoa Americana"},
      {"codigo": "020", "phone": "0376", "iso": "AD", "iso3": "AND", "name": "Andorra", "nameFormal": "Principado Andorra"},
      {"codigo": "024", "phone": "0244", "iso": "AO", "iso3": "AGO", "name": "Angola", "nameFormal": "República de Angola"},
      {"codigo": "660", "phone": "1264", "iso": "AI", "iso3": "AIA", "name": "Anguilla", "nameFormal": "Anguilla"},
      {"codigo": "010", "phone": "0672", "iso": "AQ", "iso3": "ATA", "name": "Antártida", "nameFormal": "Antártida"},
      {"codigo": "028", "phone": "1268", "iso": "AG", "iso3": "ATG", "name": "Antigua e Barbuda", "nameFormal": "Antigua e Barbuda"},
      {"codigo": "032", "phone": "0054", "iso": "AR", "iso3": "ARG", "name": "Argentina", "nameFormal": "República Argentina"},
      {"codigo": "051", "phone": "0374", "iso": "AM", "iso3": "ARM", "name": "Armênia", "nameFormal": "República da Armênia"},
      {"codigo": "533", "phone": "0297", "iso": "AW", "iso3": "ABW", "name": "Aruba", "nameFormal": "Aruba"},
      {"codigo": "036", "phone": "0061", "iso": "AU", "iso3": "AUS", "name": "Austrália", "nameFormal": "Comunidade da Austrália"},
      {"codigo": "040", "phone": "0043", "iso": "AT", "iso3": "AUT", "name": "Áustria", "nameFormal": "República da Áustria"},
      {"codigo": "031", "phone": "0994", "iso": "AZ", "iso3": "AZE", "name": "Azerbaijão", "nameFormal": "República do Azerbaijão"},
      {"codigo": "044", "phone": "1242", "iso": "BS", "iso3": "BHS", "name": "Bahamas", "nameFormal": "Comunidade de Bahamas"},
      {"codigo": "048", "phone": "0973", "iso": "BH", "iso3": "BHR", "name": "Bahrein", "nameFormal": "Reino do Bahrein"},
      {"codigo": "050", "phone": "0880", "iso": "BD", "iso3": "BGD", "name": "Bangladesh", "nameFormal": "República Popular de Bangladesh"},
      {"codigo": "052", "phone": "0246", "iso": "BB", "iso3": "BRB", "name": "Barbados", "nameFormal": "Barbados"},
      {"codigo": "112", "phone": "0375", "iso": "BY", "iso3": "BLR", "name": "Bielorrússia", "nameFormal": "República da Bielorrússia"},
      {"codigo": "056", "phone": "0032", "iso": "BE", "iso3": "BEL", "name": "Bélgica", "nameFormal": "Reino da Bélgica"},
      {"codigo": "084", "phone": "0501", "iso": "BZ", "iso3": "BLZ", "name": "Belize", "nameFormal": "Belize"},
      {"codigo": "204", "phone": "0229", "iso": "BJ", "iso3": "BEN", "name": "Benin", "nameFormal": "República do Benin"},
      {"codigo": "060", "phone": "1441", "iso": "BM", "iso3": "BMU", "name": "Bermuda", "nameFormal": "Bermuda"},
      {"codigo": "064", "phone": "0975", "iso": "BT", "iso3": "BTN", "name": "Butão", "nameFormal": "Reino do Butão"},
      {"codigo": "068", "phone": "0591", "iso": "BO", "iso3": "BOL", "name": "Bolívia", "nameFormal": "Estado Plurinacional da Bolívia"},
      {"codigo": "070", "phone": "0387", "iso": "BA", "iso3": "BIH", "name": "Bósnia e Herzegovina", "nameFormal": "Bósnia e Herzegovina"},
      {"codigo": "072", "phone": "0267", "iso": "BW", "iso3": "BWA", "name": "Botswana", "nameFormal": "República da Botswana"},
      {"codigo": "074", "phone": "0047", "iso": "BV", "iso3": "BVT", "name": "Ilha Bouvet", "nameFormal": "Ilha Bouvet"},
      {"codigo": "076", "phone": "0055", "iso": "BR", "iso3": "BRA", "name": "Brasil", "nameFormal": "República Federativa do Brasil"},
      {"codigo": "086", "phone": "0246", "iso": "IO", "iso3": "IOT", "name": "Território Britânico do Oceano Índico", "nameFormal": "Território Britânico do Oceano Índico"},
      {"codigo": "096", "phone": "0673", "iso": "BN", "iso3": "BRN", "name": "Brunei", "nameFormal": "Estado do Brunei Darussalam"},
      {"codigo": "100", "phone": "0359", "iso": "BG", "iso3": "BGR", "name": "Bulgária", "nameFormal": "República da Bulgária"},
      {"codigo": "854", "phone": "0226", "iso": "BF", "iso3": "BFA", "name": "Burkina Faso", "nameFormal": "Burkina Faso"},
      {"codigo": "108", "phone": "0257", "iso": "BI", "iso3": "BDI", "name": "Burundi", "nameFormal": "República do Burundi"},
      {"codigo": "116", "phone": "0855", "iso": "KH", "iso3": "KHM", "name": "Camboja", "nameFormal": "Reino do Camboja"},
      {"codigo": "120", "phone": "0237", "iso": "CM", "iso3": "CMR", "name": "Camarões", "nameFormal": "República de Camarões"},
      {"codigo": "124", "phone": "0001", "iso": "CA", "iso3": "CAN", "name": "Canadá", "nameFormal": "Canadá"},
      {"codigo": "132", "phone": "0238", "iso": "CV", "iso3": "CPV", "name": "Cabo Verde", "nameFormal": "República do Cabo Verde"},
      {"codigo": "136", "phone": "1345", "iso": "KY", "iso3": "CYM", "name": "Ilhas Cayman", "nameFormal": "Ilhas Cayman"},
      {"codigo": "140", "phone": "0236", "iso": "CF", "iso3": "CAF", "name": "República Centro-Africana", "nameFormal": "República Centro-Africana"},
      {"codigo": "148", "phone": "0235", "iso": "TD", "iso3": "TCD", "name": "Chade", "nameFormal": "República do Chade"},
      {"codigo": "152", "phone": "0056", "iso": "CL", "iso3": "CHL", "name": "Chile", "nameFormal": "República do Chile"},
      {"codigo": "156", "phone": "0086", "iso": "CN", "iso3": "CHN", "name": "China", "nameFormal": "República Popular da China"},
      {"codigo": "162", "phone": "0061", "iso": "CX", "iso3": "CXR", "name": "Ilha Christmas", "nameFormal": "Território da Ilha Christmas"},
      {"codigo": "166", "phone": "0672", "iso": "CC", "iso3": "CCK", "name": "Ilhas Cocos (Keeling)", "nameFormal": "Território das Ilhas Cocos (Keeling)"},
      {"codigo": "170", "phone": "0057", "iso": "CO", "iso3": "COL", "name": "Colômbia", "nameFormal": "República da Colômbia"},
      {"codigo": "174", "phone": "0269", "iso": "KM", "iso3": "COM", "name": "Comores", "nameFormal": "União das Comores"},
      {"codigo": "178", "phone": "0242", "iso": "CG", "iso3": "COG", "name": "Congo", "nameFormal": "República do Congo"},
      {"codigo": "180", "phone": "0242", "iso": "CD", "iso3": "COD", "name": "Congo (DR)", "nameFormal": "República Democrática do Congo"},
      {"codigo": "184", "phone": "0682", "iso": "CK", "iso3": "COK", "name": "Ilhas Cook", "nameFormal": "Ilhas Cook"},
      {"codigo": "188", "phone": "0506", "iso": "CR", "iso3": "CRI", "name": "Costa Rica", "nameFormal": "República da Costa Rica"},
      {"codigo": "384", "phone": "0225", "iso": "CI", "iso3": "CIV", "name": "Costa do Marfim", "nameFormal": "República da Costa do Marfim"},
      {"codigo": "191", "phone": "0385", "iso": "HR", "iso3": "HRV", "name": "Croácia", "nameFormal": "República da Croácia"},
      {"codigo": "192", "phone": "0053", "iso": "CU", "iso3": "CUB", "name": "Cuba", "nameFormal": "República de Cuba"},
      {"codigo": "196", "phone": "0357", "iso": "CY", "iso3": "CYP", "name": "Chipre", "nameFormal": "República do Chipre"},
      {"codigo": "203", "phone": "0420", "iso": "CZ", "iso3": "CZE", "name": "República Tcheca", "nameFormal": "República Tcheca"},
      {"codigo": "208", "phone": "0045", "iso": "DK", "iso3": "DNK", "name": "Dinamarca", "nameFormal": "Reino da Dinamarca"},
      {"codigo": "262", "phone": "0253", "iso": "DJ", "iso3": "DJI", "name": "Djibuti", "nameFormal": "República do Djibuti"},
      {"codigo": "212", "phone": "1767", "iso": "DM", "iso3": "DMA", "name": "Dominica", "nameFormal": "Comunidade da Dominica"},
      {"codigo": "214", "phone": "1809", "iso": "DO", "iso3": "DOM", "name": "República Dominicana", "nameFormal": "República Dominicana"},
      {"codigo": "218", "phone": "0593", "iso": "EC", "iso3": "ECU", "name": "Equador", "nameFormal": "República do Equador"},
      {"codigo": "818", "phone": "0020", "iso": "EG", "iso3": "EGY", "name": "Egito", "nameFormal": "República Árabe do Egito"},
      {"codigo": "222", "phone": "0503", "iso": "SV", "iso3": "SLV", "name": "El Salvador", "nameFormal": "República El Salvador"},
      {"codigo": "226", "phone": "0240", "iso": "GQ", "iso3": "GNQ", "name": "Guiné Equatorial", "nameFormal": "República do Guiné Equatorial"},
      {"codigo": "232", "phone": "0291", "iso": "ER", "iso3": "ERI", "name": "Eritreia", "nameFormal": "Estado da Eritreia"},
      {"codigo": "233", "phone": "0372", "iso": "EE", "iso3": "EST", "name": "Estônia", "nameFormal": "República da Estônia"},
      {"codigo": "231", "phone": "0251", "iso": "ET", "iso3": "ETH", "name": "Etiópia", "nameFormal": "República Democrática Federal da Etiópia"},
      {"codigo": "238", "phone": "0500", "iso": "FK", "iso3": "FLK", "name": "Ilhas Malvinas", "nameFormal": "Ilhas Malvinas"},
      {"codigo": "234", "phone": "0298", "iso": "FO", "iso3": "FRO", "name": "Ilhas Faroe", "nameFormal": "Ilhas Faroe"},
      {"codigo": "242", "phone": "0679", "iso": "FJ", "iso3": "FJI", "name": "Fiji", "nameFormal": "República do Fiji"},
      {"codigo": "246", "phone": "0358", "iso": "FI", "iso3": "FIN", "name": "Finlândia", "nameFormal": "República da Finlândia"},
      {"codigo": "250", "phone": "0033", "iso": "FR", "iso3": "FRA", "name": "França", "nameFormal": "República Francesa"},
      {"codigo": "254", "phone": "0594", "iso": "GF", "iso3": "GUF", "name": "Guiana Francesa", "nameFormal": "Guiana Francesa"},
      {"codigo": "258", "phone": "0689", "iso": "PF", "iso3": "PYF", "name": "Polinésia Francesa", "nameFormal": "Polinésia Francesa"},
      {"codigo": "260", "phone": "0033", "iso": "TF", "iso3": "ATF", "name": "Terras Austrais e Antárticas Francesas", "nameFormal": "Território das Terras Austrais e Antárticas Francesas"},
      {"codigo": "266", "phone": "0241", "iso": "GA", "iso3": "GAB", "name": "Gabão", "nameFormal": "República Gabonesa"},
      {"codigo": "270", "phone": "0220", "iso": "GM", "iso3": "GMB", "name": "Gâmbia", "nameFormal": "República da Gâmbia"},
      {"codigo": "268", "phone": "0995", "iso": "GE", "iso3": "GEO", "name": "Geórgia", "nameFormal": "Geórgia"},
      {"codigo": "276", "phone": "0049", "iso": "DE", "iso3": "DEU", "name": "Alemanha", "nameFormal": "República Federal da Alemanha"},
      {"codigo": "288", "phone": "0233", "iso": "GH", "iso3": "GHA", "name": "Gana", "nameFormal": "Repúblia de Gana"},
      {"codigo": "292", "phone": "0350", "iso": "GI", "iso3": "GIB", "name": "Gibraltar", "nameFormal": "Gibraltar"},
      {"codigo": "300", "phone": "0030", "iso": "GR", "iso3": "GRC", "name": "Grécia", "nameFormal": "República Helênica"},
      {"codigo": "304", "phone": "0299", "iso": "GL", "iso3": "GRL", "name": "Groelândia", "nameFormal": "Groelândia"},
      {"codigo": "308", "phone": "1473", "iso": "GD", "iso3": "GRD", "name": "Granada", "nameFormal": "Granada"},
      {"codigo": "312", "phone": "0590", "iso": "GP", "iso3": "GLP", "name": "Guadalupe", "nameFormal": "Guadalupe"},
      {"codigo": "316", "phone": "1671", "iso": "GU", "iso3": "GUM", "name": "Guão", "nameFormal": "Território do Guão"},
      {"codigo": "320", "phone": "0502", "iso": "GT", "iso3": "GTM", "name": "Guatemala", "nameFormal": "República da Guatemala"},
      {"codigo": "324", "phone": "0224", "iso": "GN", "iso3": "GIN", "name": "Guiné", "nameFormal": "República do Guiné"},
      {"codigo": "624", "phone": "0245", "iso": "GW", "iso3": "GNB", "name": "Guiné-Bissau", "nameFormal": "República da Guiné-Bissau"},
      {"codigo": "328", "phone": "0592", "iso": "GY", "iso3": "GUY", "name": "Guiana", "nameFormal": "República Cooperativa da Guiana"},
      {"codigo": "332", "phone": "0509", "iso": "HT", "iso3": "HTI", "name": "Haiti", "nameFormal": "República do Haiti"},
      {"codigo": "334", "phone": "0672", "iso": "HM", "iso3": "HMD", "name": "Ilhas Heard e McDonald", "nameFormal": "Território das Ilhas Heard e McDonald"},
      {"codigo": "336", "phone": "0039", "iso": "VA", "iso3": "VAT", "name": "Vaticano", "nameFormal": "Estado da Cidade do Vaticano"},
      {"codigo": "340", "phone": "0504", "iso": "HN", "iso3": "HND", "name": "Honduras", "nameFormal": "República de Honduras"},
      {"codigo": "344", "phone": "0852", "iso": "HK", "iso3": "HKG", "name": "Hong Kong", "nameFormal": "Região Administrativa Especial de Hong Kong da República Popular da China"},
      {"codigo": "348", "phone": "0036", "iso": "HU", "iso3": "HUN", "name": "Hungria", "nameFormal": "Hungria"},
      {"codigo": "352", "phone": "0354", "iso": "IS", "iso3": "ISL", "name": "Islândia", "nameFormal": "Islândia"},
      {"codigo": "356", "phone": "0091", "iso": "IN", "iso3": "IND", "name": "Índia", "nameFormal": "República da Índia"},
      {"codigo": "360", "phone": "0062", "iso": "ID", "iso3": "IDN", "name": "Indonésia", "nameFormal": "República da Indonésia"},
      {"codigo": "364", "phone": "0098", "iso": "IR", "iso3": "IRN", "name": "Iran", "nameFormal": "República Islâmica do Iran"},
      {"codigo": "368", "phone": "0964", "iso": "IQ", "iso3": "IRQ", "name": "Iraque", "nameFormal": "República do Iraque"},
      {"codigo": "372", "phone": "0353", "iso": "IE", "iso3": "IRL", "name": "Irlanda", "nameFormal": "Irlanda"},
      {"codigo": "376", "phone": "0972", "iso": "IL", "iso3": "ISR", "name": "Israel", "nameFormal": "Estado de Israel"},
      {"codigo": "380", "phone": "0039", "iso": "IT", "iso3": "ITA", "name": "Italia", "nameFormal": "República Italiana"},
      {"codigo": "388", "phone": "1876", "iso": "JM", "iso3": "JAM", "name": "Jamaica", "nameFormal": "Jamaica"},
      {"codigo": "392", "phone": "0081", "iso": "JP", "iso3": "JPN", "name": "Japão", "nameFormal": "Japão"},
      {"codigo": "400", "phone": "0962", "iso": "JO", "iso3": "JOR", "name": "Jornânia", "nameFormal": "Reino Hachemita da Jordânia"},
      {"codigo": "398", "phone": "0007", "iso": "KZ", "iso3": "KAZ", "name": "Cazaquistão", "nameFormal": "República do Cazaquistão"},
      {"codigo": "404", "phone": "0254", "iso": "KE", "iso3": "KEN", "name": "Quênia", "nameFormal": "República do Quênia"},
      {"codigo": "296", "phone": "0686", "iso": "KI", "iso3": "KIR", "name": "Kiribati", "nameFormal": "República do Kiribati"},
      {"codigo": "408", "phone": "0850", "iso": "KP", "iso3": "PRK", "name": "Coreia do Norte", "nameFormal": "República Democrática Popular da Coreia"},
      {"codigo": "410", "phone": "0082", "iso": "KR", "iso3": "KOR", "name": "Coreia do Sul", "nameFormal": "República da Coreia"},
      {"codigo": "414", "phone": "0965", "iso": "KW", "iso3": "KWT", "name": "Kuwait", "nameFormal": "Estado do Kuwait"},
      {"codigo": "417", "phone": "0996", "iso": "KG", "iso3": "KGZ", "name": "Quirguistão", "nameFormal": "República Quirguiz"},
      {"codigo": "418", "phone": "0856", "iso": "LA", "iso3": "LAO", "name": "Laos", "nameFormal": "República Democrática Popular Lau"},
      {"codigo": "428", "phone": "0371", "iso": "LV", "iso3": "LVA", "name": "Letônia", "nameFormal": "República da Letônia"},
      {"codigo": "422", "phone": "0961", "iso": "LB", "iso3": "LBN", "name": "Líbano", "nameFormal": "República Libanesa"},
      {"codigo": "426", "phone": "0266", "iso": "LS", "iso3": "LSO", "name": "Lesoto", "nameFormal": "Reino do Lesoto"},
      {"codigo": "430", "phone": "0231", "iso": "LR", "iso3": "LBR", "name": "Libéria", "nameFormal": "República da Libéria"},
      {"codigo": "434", "phone": "0218", "iso": "LY", "iso3": "LBY", "name": "Líbia", "nameFormal": "Líbia"},
      {"codigo": "438", "phone": "0423", "iso": "LI", "iso3": "LIE", "name": "Liechtenstein", "nameFormal": "Principado de Liechtenstein"},
      {"codigo": "440", "phone": "0370", "iso": "LT", "iso3": "LTU", "name": "Lituânia", "nameFormal": "República da Lituânia"},
      {"codigo": "442", "phone": "0352", "iso": "LU", "iso3": "LUX", "name": "Luxemburgo", "nameFormal": "Grão-Ducado do Luxemburgo"},
      {"codigo": "446", "phone": "0853", "iso": "MO", "iso3": "MAC", "name": "Macao", "nameFormal": "Macao"},
      {"codigo": "807", "phone": "0389", "iso": "MK", "iso3": "MKD", "name": "Macedônia", "nameFormal": "República da Macedônia"},
      {"codigo": "450", "phone": "0261", "iso": "MG", "iso3": "MDG", "name": "Madagascar", "nameFormal": "República de Madagascar"},
      {"codigo": "454", "phone": "0265", "iso": "MW", "iso3": "MWI", "name": "Malawi", "nameFormal": "República de Malawi"},
      {"codigo": "458", "phone": "0060", "iso": "MY", "iso3": "MYS", "name": "Malásia", "nameFormal": "Malásia"},
      {"codigo": "462", "phone": "0960", "iso": "MV", "iso3": "MDV", "name": "Maldivas", "nameFormal": "Reública de Maldivas"},
      {"codigo": "466", "phone": "0223", "iso": "ML", "iso3": "MLI", "name": "Mali", "nameFormal": "República do Mali"},
      {"codigo": "470", "phone": "0356", "iso": "MT", "iso3": "MLT", "name": "Malta", "nameFormal": "República de Malta"},
      {"codigo": "584", "phone": "0692", "iso": "MH", "iso3": "MHL", "name": "Ilhas Marshall", "nameFormal": "República das Ilhas Marshall"},
      {"codigo": "474", "phone": "0596", "iso": "MQ", "iso3": "MTQ", "name": "Martinica", "nameFormal": "Martinica"},
      {"codigo": "478", "phone": "0222", "iso": "MR", "iso3": "MRT", "name": "Mauritânia", "nameFormal": "República Islâmica da Mauritânia"},
      {"codigo": "480", "phone": "0230", "iso": "MU", "iso3": "MUS", "name": "Maurício", "nameFormal": "República de Maurício"},
      {"codigo": "175", "phone": "0269", "iso": "YT", "iso3": "MYT", "name": "Mayotte", "nameFormal": "Departamento de Mayotte"},
      {"codigo": "484", "phone": "0052", "iso": "MX", "iso3": "MEX", "name": "México", "nameFormal": "Estados Unidos Mexicanos"},
      {"codigo": "583", "phone": "0691", "iso": "FM", "iso3": "FSM", "name": "Micronésia", "nameFormal": "Estados Federados da Micronesia"},
      {"codigo": "498", "phone": "0373", "iso": "MD", "iso3": "MDA", "name": "Moldova", "nameFormal": "República de Moldova"},
      {"codigo": "492", "phone": "0377", "iso": "MC", "iso3": "MCO", "name": "Mônaco", "nameFormal": "Principado de Mônaco"},
      {"codigo": "496", "phone": "0976", "iso": "MN", "iso3": "MNG", "name": "Mongólia", "nameFormal": "Mongólia"},
      {"codigo": "500", "phone": "1664", "iso": "MS", "iso3": "MSR", "name": "Montserrat", "nameFormal": "Montserrat"},
      {"codigo": "504", "phone": "0212", "iso": "MA", "iso3": "MAR", "name": "Marrocos", "nameFormal": "Reino de Marrocos"},
      {"codigo": "508", "phone": "0258", "iso": "MZ", "iso3": "MOZ", "name": "Moçambique", "nameFormal": "República de Moçambique"},
      {"codigo": "104", "phone": "0095", "iso": "MM", "iso3": "MMR", "name": "Birmânia", "nameFormal": "República da União de Myanmar"},
      {"codigo": "516", "phone": "0264", "iso": "NA", "iso3": "NAM", "name": "Namíbia", "nameFormal": "República da Namíbia"},
      {"codigo": "520", "phone": "0674", "iso": "NR", "iso3": "NRU", "name": "Nauru", "nameFormal": "República de Nauru"},
      {"codigo": "524", "phone": "0977", "iso": "NP", "iso3": "NPL", "name": "Nepal", "nameFormal": "República Democrática Federativa do Nepal"},
      {"codigo": "528", "phone": "0031", "iso": "NL", "iso3": "NLD", "name": "Holanda", "nameFormal": "Holanda"},
      {"codigo": "530", "phone": "0599", "iso": "AN", "iso3": "ANT", "name": "Antilhas Holandesas", "nameFormal": "Antilhas Holandesas"},
      {"codigo": "540", "phone": "0687", "iso": "NC", "iso3": "NCL", "name": "Nova Caledônia", "nameFormal": "Nova Caledônia"},
      {"codigo": "554", "phone": "0064", "iso": "NZ", "iso3": "NZL", "name": "Nova Zelândia", "nameFormal": "Nova Zelândia"},
      {"codigo": "558", "phone": "0505", "iso": "NI", "iso3": "NIC", "name": "Nicarágua", "nameFormal": "República da Nicarágua"},
      {"codigo": "562", "phone": "0227", "iso": "NE", "iso3": "NER", "name": "Niger", "nameFormal": "República do Niger"},
      {"codigo": "566", "phone": "0234", "iso": "NG", "iso3": "NGA", "name": "Nigéria", "nameFormal": "República Federativa da Nigéria"},
      {"codigo": "570", "phone": "0683", "iso": "NU", "iso3": "NIU", "name": "Niue", "nameFormal": "Niue"},
      {"codigo": "574", "phone": "0672", "iso": "NF", "iso3": "NFK", "name": "Ilha Norfolk", "nameFormal": "Território da Ilha Norfolk"},
      {"codigo": "580", "phone": "1670", "iso": "MP", "iso3": "MNP", "name": "Ilhas Marianas do Norte", "nameFormal": "Comunidade das Ilhas Marianas do Norte"},
      {"codigo": "578", "phone": "0047", "iso": "NO", "iso3": "NOR", "name": "Noruega", "nameFormal": "Reino da Noruega"},
      {"codigo": "512", "phone": "0968", "iso": "OM", "iso3": "OMN", "name": "Omã", "nameFormal": "Sultanato de Omã"},
      {"codigo": "586", "phone": "0092", "iso": "PK", "iso3": "PAK", "name": "Paquistão", "nameFormal": "República Islâmica do Paquistão"},
      {"codigo": "585", "phone": "0680", "iso": "PW", "iso3": "PLW", "name": "Palau", "nameFormal": "República de Palau"},
      {"codigo": "275", "phone": "0970", "iso": "PS", "iso3": "PSE", "name": "Palestina", "nameFormal": "Estado da Palestina"},
      {"codigo": "591", "phone": "0507", "iso": "PA", "iso3": "PAN", "name": "Panamá", "nameFormal": "República do Panamá"},
      {"codigo": "598", "phone": "0675", "iso": "PG", "iso3": "PNG", "name": "Papua-Nova Guiné", "nameFormal": "Estado Independente da Papua-Nova Guiné"},
      {"codigo": "600", "phone": "0595", "iso": "PY", "iso3": "PRY", "name": "Paraguai", "nameFormal": "República do Paraguai"},
      {"codigo": "604", "phone": "0051", "iso": "PE", "iso3": "PER", "name": "Peru", "nameFormal": "República do Peru"},
      {"codigo": "608", "phone": "0063", "iso": "PH", "iso3": "PHL", "name": "Filipinas", "nameFormal": "República das Filipinas"},
      {"codigo": "612", "phone": "0672", "iso": "PN", "iso3": "PCN", "name": "Ilhas Picárnia", "nameFormal": "Ilhas Picárnia"},
      {"codigo": "616", "phone": "0048", "iso": "PL", "iso3": "POL", "name": "Polônia", "nameFormal": "República da Polônia"},
      {"codigo": "620", "phone": "0351", "iso": "PT", "iso3": "PRT", "name": "Portugal", "nameFormal": "República Portuguesa"},
      {"codigo": "630", "phone": "1787", "iso": "PR", "iso3": "PRI", "name": "Porto Rico", "nameFormal": "Comunidade do Porto Rico"},
      {"codigo": "634", "phone": "0974", "iso": "QA", "iso3": "QAT", "name": "Catar", "nameFormal": "Estado do Catar"},
      {"codigo": "638", "phone": "0262", "iso": "RE", "iso3": "REU", "name": "Reunião", "nameFormal": "Polônia"},
      {"codigo": "642", "phone": "0040", "iso": "RO", "iso3": "ROM", "name": "Romênia", "nameFormal": "Romênia"},
      {"codigo": "643", "phone": "0070", "iso": "RU", "iso3": "RUS", "name": "Rússia", "nameFormal": "Federação Russa"},
      {"codigo": "646", "phone": "0250", "iso": "RW", "iso3": "RWA", "name": "Ruanda", "nameFormal": "República da Ruanda"},
      {"codigo": "654", "phone": "0290", "iso": "SH", "iso3": "SHN", "name": "Santa Helena", "nameFormal": "Saint Helena"},
      {"codigo": "659", "phone": "1869", "iso": "KN", "iso3": "KNA", "name": "São Cristóvão", "nameFormal": "São Cristóvão"},
      {"codigo": "662", "phone": "1758", "iso": "LC", "iso3": "LCA", "name": "Santa Lúcia", "nameFormal": "Santa Lúcia"},
      {"codigo": "666", "phone": "0508", "iso": "PM", "iso3": "SPM", "name": "São Pedro e Miquelon", "nameFormal": "Coletividade Territorial de São Pedro e Miquelon"},
      {"codigo": "670", "phone": "1784", "iso": "VC", "iso3": "VCT", "name": "São Vicente e Granadinas", "nameFormal": "São Vicente e Granadinas"},
      {"codigo": "882", "phone": "0684", "iso": "WS", "iso3": "WSM", "name": "Samoa", "nameFormal": "Estado Independente de Samoa"},
      {"codigo": "674", "phone": "0378", "iso": "SM", "iso3": "SMR", "name": "São Marino", "nameFormal": "República de São Marino"},
      {"codigo": "678", "phone": "0239", "iso": "ST", "iso3": "STP", "name": "Sao Tomé e Príncipe", "nameFormal": "República Democrática de Sao Tomé e Príncipe"},
      {"codigo": "682", "phone": "0966", "iso": "SA", "iso3": "SAU", "name": "Arábia Saudita", "nameFormal": "Reino da Arábia Saudita"},
      {"codigo": "686", "phone": "0221", "iso": "SN", "iso3": "SEN", "name": "Senegal", "nameFormal": "República do Senegal"},
      {"codigo": "688", "phone": "0381", "iso": "CS", "iso3": "SRB", "name": "Sérvia e Montenegro", "nameFormal": "União Estatal de Sérvia e Montenegro"},
      {"codigo": "690", "phone": "0248", "iso": "SC", "iso3": "SYC", "name": "Seicheles", "nameFormal": "República das Seicheles"},
      {"codigo": "694", "phone": "0232", "iso": "SL", "iso3": "SLE", "name": "República da Serra Leoa", "nameFormal": "República da Serra Leoa"},
      {"codigo": "702", "phone": "0065", "iso": "SG", "iso3": "SGP", "name": "Singapura", "nameFormal": "República da Singapura"},
      {"codigo": "703", "phone": "0421", "iso": "SK", "iso3": "SVK", "name": "Eslováquia", "nameFormal": "República Eslovaca"},
      {"codigo": "705", "phone": "0386", "iso": "SI", "iso3": "SVN", "name": "Eslovênia", "nameFormal": "República da Eslovênia"},
      {"codigo": "090", "phone": "0677", "iso": "SB", "iso3": "SLB", "name": "Ilhas Salomão", "nameFormal": "Ilhas Salomão"},
      {"codigo": "706", "phone": "0252", "iso": "SO", "iso3": "SOM", "name": "Somália", "nameFormal": "República da Somália"},
      {"codigo": "710", "phone": "0027", "iso": "ZA", "iso3": "ZAF", "name": "África do Sul", "nameFormal": "República da África do Sul"},
      {"codigo": "239", "phone": "0500", "iso": "GS", "iso3": "SGS", "name": "Ilhas Geórgia do Sul e Sandwich do Sul", "nameFormal": "Ilhas Geórgia do Sul e Sandwich do Sul"},
      {"codigo": "724", "phone": "0034", "iso": "ES", "iso3": "ESP", "name": "España", "nameFormal": "Reino da Espanha"},
      {"codigo": "144", "phone": "0094", "iso": "LK", "iso3": "LKA", "name": "Sri Lanka", "nameFormal": "República Democrática Socialista do Sri Lanka"},
      {"codigo": "736", "phone": "0249", "iso": "SD", "iso3": "SDN", "name": "Sudão", "nameFormal": "República do Sudão"},
      {"codigo": "740", "phone": "0597", "iso": "SR", "iso3": "SUR", "name": "Suriname", "nameFormal": "República do Suriname"},
      {"codigo": "744", "phone": "0047", "iso": "SJ", "iso3": "SJM", "name": "Esvalbarde", "nameFormal": "Esvalbarde"},
      {"codigo": "748", "phone": "0268", "iso": "SZ", "iso3": "SWZ", "name": "Suazilândia", "nameFormal": "Reino da Suazilândia"},
      {"codigo": "752", "phone": "0046", "iso": "SE", "iso3": "SWE", "name": "Suécia", "nameFormal": "Reino da Suécia"},
      {"codigo": "756", "phone": "0041", "iso": "CH", "iso3": "CHE", "name": "Suiça", "nameFormal": "Confederação Suiça"},
      {"codigo": "760", "phone": "0963", "iso": "SY", "iso3": "SYR", "name": "Síria", "nameFormal": "República Árabe Síria"},
      {"codigo": "158", "phone": "0886", "iso": "TW", "iso3": "TWN", "name": "Taiwan", "nameFormal": "Taiwan"},
      {"codigo": "762", "phone": "0992", "iso": "TJ", "iso3": "TJK", "name": "Tajiquistão", "nameFormal": "República do Tajiquistão"},
      {"codigo": "834", "phone": "0255", "iso": "TZ", "iso3": "TZA", "name": "Tanzânia", "nameFormal": "República Unida da Tanzânia"},
      {"codigo": "764", "phone": "0066", "iso": "TH", "iso3": "THA", "name": "Tailândia", "nameFormal": "Reino da Tailândia"},
      {"codigo": "626", "phone": "0670", "iso": "TL", "iso3": "TLS", "name": "Timor-Leste", "nameFormal": "República Democrática de Timor-Leste"},
      {"codigo": "768", "phone": "0228", "iso": "TG", "iso3": "TGO", "name": "Togo", "nameFormal": "República Togolesa"},
      {"codigo": "772", "phone": "0690", "iso": "TK", "iso3": "TKL", "name": "Toquelau", "nameFormal": "Toquelau"},
      {"codigo": "776", "phone": "0676", "iso": "TO", "iso3": "TON", "name": "Tonga", "nameFormal": "Reino de Tonga"},
      {"codigo": "780", "phone": "1868", "iso": "TT", "iso3": "TTO", "name": "Trinidad e Tobago", "nameFormal": "República da Trinidad e Tobago"},
      {"codigo": "788", "phone": "0216", "iso": "TN", "iso3": "TUN", "name": "Tunísia", "nameFormal": "República da Tunísia"},
      {"codigo": "792", "phone": "0090", "iso": "TR", "iso3": "TUR", "name": "Turquia", "nameFormal": "República da Turquia"},
      {"codigo": "795", "phone": "7370", "iso": "TM", "iso3": "TKM", "name": "Turcomenistão", "nameFormal": "Turcomenistão"},
      {"codigo": "796", "phone": "1649", "iso": "TC", "iso3": "TCA", "name": "Ilhas Turks e Caicos", "nameFormal": "Ilhas Turks e Caicos"},
      {"codigo": "798", "phone": "0688", "iso": "TV", "iso3": "TUV", "name": "Tuvalu", "nameFormal": "Tuvalu"},
      {"codigo": "800", "phone": "0256", "iso": "UG", "iso3": "UGA", "name": "Uganda", "nameFormal": "República de Uganda"},
      {"codigo": "804", "phone": "0380", "iso": "UA", "iso3": "UKR", "name": "Ucrânia", "nameFormal": "Ucrânia"},
      {"codigo": "784", "phone": "0971", "iso": "AE", "iso3": "ARE", "name": "Emirados Árabes", "nameFormal": "Emirados Árabes Unidos"},
      {"codigo": "826", "phone": "0044", "iso": "GB", "iso3": "GBR", "name": "Reino Unido", "nameFormal": "Reino Unido da Grã-Bretanha e Irlanda do Norte"},
      {"codigo": "840", "phone": "0001", "iso": "US", "iso3": "USA", "name": "Estados Unidos", "nameFormal": "Estados Unidos da América"},
      {"codigo": "581", "phone": "0001", "iso": "UM", "iso3": "UMI", "name": "Ilhas Menores Distantes dos Estados Unidos", "nameFormal": "Ilhas Menores Distantes dos Estados Unidos"},
      {"codigo": "858", "phone": "0598", "iso": "UY", "iso3": "URY", "name": "Uruguai", "nameFormal": "República Oriental do Uruguai"},
      {"codigo": "860", "phone": "0998", "iso": "UZ", "iso3": "UZB", "name": "Uzbequistão", "nameFormal": "República do Uzbequistão"},
      {"codigo": "548", "phone": "0678", "iso": "VU", "iso3": "VUT", "name": "Vanuatu", "nameFormal": "República de Vanuatu"},
      {"codigo": "862", "phone": "0058", "iso": "VE", "iso3": "VEN", "name": "Venezuela", "nameFormal": "República Bolivariana da Venezuela"},
      {"codigo": "704", "phone": "0084", "iso": "VN", "iso3": "VNM", "name": "Vietnam", "nameFormal": "República Socialista do Vietnam"},
      {"codigo": "092", "phone": "1284", "iso": "VG", "iso3": "VGB", "name": "Ilhas Virgens Inglesas", "nameFormal": "Ilhas Virgens"},
      {"codigo": "850", "phone": "1340", "iso": "VI", "iso3": "VIR", "name": "Ilhas Virgens (USA)", "nameFormal": "Ilhas Virgens dos Estados Unidos"},
      {"codigo": "876", "phone": "0681", "iso": "WF", "iso3": "WLF", "name": "Wallis e Futuna", "nameFormal": "Wallis e Futuna"},
      {"codigo": "732", "phone": "0212", "iso": "EH", "iso3": "ESH", "name": "Saara Ocidental", "nameFormal": "Saara Ocidental"},
      {"codigo": "887", "phone": "0967", "iso": "YE", "iso3": "YEM", "name": "Iêmen", "nameFormal": "República do Iêmen"},
      {"codigo": "894", "phone": "0260", "iso": "ZM", "iso3": "ZMB", "name": "Zâmbia", "nameFormal": "República do Zâmbia"},
      {"codigo": "716", "phone": "0263", "iso": "ZW", "iso3": "ZWE", "name": "Zimbábue", "nameFormal": "República do Zimbábue"}
]