import { Component, OnInit, Inject, Input } from '@angular/core';
import { AnswerService } from 'src/app/services/answer.service';
import { LayoutService } from 'src/app/services/layout.service';
import { AccountService } from 'src/app/services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Button, CurrentUser } from 'src/app/_models/data';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification.service';
import { ICoupleInformation } from 'src/app/_interface/coupleInformation';
import { URL_RESOURCES } from 'src/environments/environment';

@Component({
  selector: 'app-shared-guestbook',
  templateUrl: './shared-guestbook.component.html',
  styleUrls: ['./shared-guestbook.component.scss']
})
export class SharedGuestbookComponent implements OnInit {
  @Input() couple: ICoupleInformation;
  imgDefault = 'https://uniko-prod.s3-us-west-2.amazonaws.com/guest-gif/WhatsApp+Video+2019-08-02+at+16.25.42.mp4?type=video';
  default2 = 'https://uniko-prod.s3-us-west-2.amazonaws.com/guest-gif/giphy.gif';
  guestBooks: any[] = [];
  isGuest: boolean = false;
  buttons: Array<Button> = [];
  sendMesageForm: FormGroup;
  sendAnswerForm: FormGroup;
  isOpenEdit: boolean = false;
  isOpenDelete: boolean = false;
  submitAnswer: boolean = false;
  isOpen: boolean = false;
  selectedFile: any;
  file: any;
  submit: boolean = false;
  guestBook: any;
  load: boolean = false;
  targetGuest: any;
  eventType: string = 'WEDDING';
  publicAssets = URL_RESOURCES.ASSETS;
  eventUrl:string;
  constructor(
    private answerService: AnswerService,
    private layoutService: LayoutService,
    private coupleService: AccountService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private notification: NotificationService,
  ) {
    this.isGuest = this.layoutService.isGuest();
    this.eventUrl = this.activatedRoute.parent.snapshot.params["eventUrl"];
    console.log(this.eventUrl);
  }

  ngOnInit() {
    this.get();
    this.sendMesageForm = this.formBuilder.group({
      question: ['', Validators.required],
      names: ['', Validators.required],
      email: ['', Validators.required]
    });
    this.sendAnswerForm = this.formBuilder.group({
      answer: ['', Validators.required],
      question: ['']
    });
  }

  openDeleteModal(_guest) {
    this.isOpenDelete = true;
    this.targetGuest = _guest;
  }

  closeModalDelete() {
    this.isOpenDelete = false;
    this.targetGuest = null;
  }

  getImageToVideo(guestBook) {
    const isExist = guestBook.img.length ? true : false;
    const link = guestBook.img === this.imgDefault ? this.default2 : guestBook.img;
    const existType = link.search("type");
    if (existType > 10) {
      try {
        const type = link.slice(existType);
        const typeArray = type.split('=');
        const _type = atob(typeArray[1]);
        if (_type.search('video') >= 0 || (typeArray[1].search('video') >= 0)) {
          return `<video src="${link}" controls=""></video>`;
        } else {
          return `<img src="${link}">`;
        }
      } catch (e) {
        return isExist ? `<img src="${link}">` : '';
      }
    } else {
      return isExist ? `<img src="${link}">` : '';
    }
  }

  async upload(event, type: string) {
    if (event.target.files && event.target.files[0]) {
      if (this.checkFileSize(event)) {
        this.notification.warning("El tamaño del archivo no debe exceder los 5 MB");
        document.getElementById('new-gift-image').nodeValue = '';
        return
      }
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async (data) => {
        console.log(data);
        this.selectedFile = event.target.files[0];
        this.file = data.target['result'];
      };
    }
  }

  checkFileSize(fileEvent: any) {
    const file = fileEvent.target.files[0];
    return (file.size / 1024 / 1024) > 5;
  }

  onUpload(event: Event, element: string) {
    event.stopPropagation();
    event.preventDefault();
    document.getElementById(element).click();
  }

  modalAdd(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    const _this: any = this['parent'];
    _this.add()
  }


  async get(): Promise<void> {
    try {
      this.spinner.show();
      const couple = this.layoutService.getCoupleInformation();
      this.couple = couple?couple: await this.ClientForEventInfo();
      const auth = await this.coupleService.getPlanPermisions(this.couple.id);
      this.layoutService.authorization.info = auth;
      console.log('-------AUTH-------');
      console.log(auth);
      console.log('------------------');
      if (this.isGuest && !this.layoutService.authorization.verify('GUEST_BOOK')) {
        this.layoutService.redirect();
        return;
      }
      this.guestBooks = await this.answerService.getGuestbook(this.couple.fakeid);
      this.load = true;
      console.log('-------BOOK-------');
      console.log(this.guestBooks);
      console.log('------------------');
      if (this.isGuest && this.activatedRoute.snapshot.queryParams['comment']) {
        this.isOpen = true;
      }

      this.eventType = this.couple.weddingData.type ? this.couple.weddingData.type : this.eventType;
    } catch (err) {
      //this.router.navigate(['/search']);
      this.notification.error(err);
    } finally {
      this.spinner.hide();
    }
  }

  async ClientForEventInfo() {
    const user = this.layoutService.getCurrentUser();
    return await this.layoutService.isGuest() ?
      this.coupleService.findOneRestrict(this.eventUrl) :
      this.coupleService.getPublic(user.id);
  }

  async remove(_guest) {
    try {
      this.spinner.show();
      this.isOpenDelete = false;
      const result = await this.answerService.remove(_guest.id);
      if (result) {
        await this.answerService.getGuestbook(
          this.layoutService.getCoupleInformation().fakeid
        );
        const couple = await this.coupleService.get(this.couple.id);
        this.couple = couple;
        this.layoutService.setCoupleInformation(this.couple);
        this.guestBooks = await this.answerService.getGuestbook(this.couple.fakeid);
      }
    } catch (e) {
      this.notification.error(e);
    } finally {
      this.spinner.hide();
    }
  }


  async changePublic(event: Event, guest) {
    try {
      this.spinner.show();
      guest.isPublic = !guest.isPublic;
      const _guest = await this.answerService.isShow(guest.isPublic, guest.id);
      console.log(_guest);
    } catch (err) {
      this.notification.error(err);
    } finally {
      this.spinner.hide();
    }
  }

  closeModal() {
    this.isOpenEdit = false;
  }

  closeModalGuest() {
    this.isOpen = false;
  }

  onEdit(event: Event, guest: any) {
    this.guestBook = guest;
    this.isOpenEdit = true;
    this.sendAnswerForm.patchValue({
      answer: '',
      question: guest.comment
    });
    this.sendAnswerForm.controls['question'].disable();
  }

  async saveAnswer(): Promise<void> {
    try {
      this.spinner.show();
      this.submitAnswer = true;
      if (this.sendAnswerForm.invalid) {
        return;
      }
      await this.answerService.addAnswer(
        this.guestBook.id,
        this.sendAnswerForm.value.answer,
        this.guestBook.isPublic
      );
      this.guestBooks = await this.answerService.getGuestbook(this.couple.fakeid);
      this.layoutService.setCoupleInformation(this.couple);
      this.isOpenEdit = false;
    } catch (err) {
      console.log(err);
      this.notification.error(err);
    } finally {
      this.spinner.hide();
    }
  }

}
