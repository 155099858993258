import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Session } from './../_models/data';
import { URL_RESOURCES } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };
  constructor(private http: HttpClient) {
  }

  async login(
    email: string,
    password: string
  ): Promise<Session | undefined> {
    const body = {
      email: email,
      password: password
    };
    return await this.http.post<Session>(
      `${URL_RESOURCES.API_UNIKO}/coupleAccounts/login?include=user`,
      body, this.httpOptions
    ).toPromise();
  }

  async recovery(
    email: string,
  ): Promise<Session | undefined> {
    const body = {
      email: email
    };
    return await this.http.post<Session>(
      `${URL_RESOURCES.API_UNIKO}/coupleAccounts/reset`,
      body
    ).toPromise();
  }

  async reset(
    password: string,
  ): Promise<any> {
    const body = {
      password: password
    };
    return await this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/processresetpassword`,
      body
    ).toPromise();
  }

  async getMenu(couple?: string): Promise<any> {
    const query: any = {
      coupleId: couple
    }
    return await this.http.get<any>(
      `${URL_RESOURCES.API_UNIKO}/coupleMenu/list`,
      {
        params: query
      }
    ).toPromise();
  }

  logout() {

  }
}
