<nav class="navbar navbar-light bg-uniko p-content justify-content-center">
  <div class="col-12 col-lg-auto p-0" *ngIf="buttons || menu">
    <ul class="nav align-middle row mx-0 action-list">
      <li class="nav-item txtBtns hidden-tablet col-auto" *ngIf="buttons.length || checkMenu()">
        {{guest?'También puede interesarle:':'También puede interesarles:'}}
      </li>
      <li *ngFor="let button of buttons" class="col-6 col-md-auto">
        <a href="" [routerLink]="button.url?button.url:''" class="font-w" *ngIf='button.url'>
          <i class="fas fa {{ button._class }} mr-1" style="font-size: 11px;"></i>
          {{ button.label }}
        </a>
        <a href="" (click)="button.onClick($event)" class="font-w" *ngIf='!button.url'>
          <i class="fas fa {{ button._class }} mr-1" style="font-size: 11px;"></i>
          {{ button.label }}
        </a>
      </li>
      <li *ngIf="checkMenu()" class="col-6 col-md-auto">
        <a href="#" (click)="onActive($event)" class="font-w">
          <i class="material-icons white mr-1">{{ menu.active?'visibility_off':'visibility' }}</i>
          {{ menu.active?'Ocultar':'Mostrar' }} Sección
        </a>
      </li>
    </ul>
    <form class="form-inline">

    </form>
  </div>
  <div class="" style="height: 30px;" *ngIf="!buttons.length || !checkMenu()"></div>
</nav>
<!--<div class="menu-action-mobile" *ngIf="buttons.length || checkMenu()">
  <div class="button-mobile" [class.cols-1]="getButtonsLengt() === 1" [class.cols-2]="getButtonsLengt() === 2"
    [class.cols-3]="getButtonsLengt() === 3" [class.cols-4]="getButtonsLengt() === 4"
    [class.cols-5]="getButtonsLengt() === 5" *ngFor="let button of buttons">
    <div class="col-12 p-0" *ngIf='button.url' [routerLink]="button.url">
      <i class="fas fa {{ button._class }} mr-1" style="font-size: 11px;"></i>
      {{ button.label }}
    </div>
    <div (click)="button.onClick($event)" *ngIf='!button.url' class="col-12 p-0">
      <i class="fas fa {{ button._class }} mr-1" style="font-size: 11px;"></i>
      {{ button.label }}
    </div>
  </div>
  <div class="button-mobile" [class.cols-1]="getButtonsLengt() === 1" [class.cols-2]="getButtonsLengt() === 2"
    [class.cols-3]="getButtonsLengt() === 3" [class.cols-4]="getButtonsLengt() === 4"
    [class.cols-5]="getButtonsLengt() === 5">
    <div *ngIf="checkMenu()" class="col-12 p-0">
      <i class="material-icons white mr-1">{{ menu.active?'visibility_off':'visibility' }}</i>
      {{ menu.active?'Ocultar':'Mostrar' }} Sección
    </div>
  </div>
</div>-->