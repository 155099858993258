import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-cart',
  templateUrl: './modal-cart.component.html',
  styleUrls: ['./modal-cart.component.scss']
})
export class ModalCartComponent {
  @Input() isOpen: boolean;
  @Input() textButton: string = 'Guardar'
  @Output() onAction = new EventEmitter<string>();
  @Output() onClosed = new EventEmitter<string>();

  constructor() { }


  onAccept() {
    this.onAction.emit('ok');
  }

  onClose() {
    this.onClosed.emit();
  }


}
