import { Component, OnInit, Inject, Input } from '@angular/core';
import { IFrequencyQuestions } from 'src/app/_interface/frequencyQuestion';
import { NgxSpinnerService } from 'ngx-spinner';
import { QuestionsService } from 'src/app/services/questions.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LayoutService } from 'src/app/services/layout.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-pending',
  templateUrl: './pending.component.html',
  styleUrls: ['../public/public.component.scss']
})
export class PendingComponent implements OnInit {
  @Input() faqs: IFrequencyQuestions[] = [];
  dataForm: FormGroup;
  submit: boolean = false;
  currentFaq: IFrequencyQuestions;
  isOpen: boolean = false;
  isOpenDelete: boolean = false;
  selectedFile: any;
  file: any;
  currentFile: any;
  previousImage= false;
  constructor(
    private spinner: NgxSpinnerService,
    private notification: NotificationService,
    private frequentQuestionService: QuestionsService,
    private formBuilder: FormBuilder,
    private layoutService: LayoutService,
    private coupleService: AccountService

  ) {
    this.onForm();
  }

  ngOnInit() {
  }

  onForm() {
    this.dataForm = this.formBuilder.group({
      question: [this.currentFaq ? this.currentFaq.question : '', Validators.required],
      answer: [this.currentFaq ? this.currentFaq.answer : '', Validators.required]
    });
  }

  existImg(preg) {
    return preg.image && preg.image ? true : false;
  }

  getInputImage(file, blob, image?) {
    if(image){
      const isExist = image && image.length ? true : false;
      const link = image ? image : '';
      const existType = link.search("type");
      if (existType > 10) {
        try {
          const type = link.slice(existType);
          const typeArray = type.split('=');
          const _type = atob(typeArray[1]);
          if (_type.search('video') >= 0 || (typeArray[1].search('video') >= 0)) {
            return '<i class="fa fa-video"></i>';
          } else {
            return `<img src="${link}" class="input-media">`;
          }
        } catch (e) {
          return isExist ? '<i class="fa fa-file"></i>' : '';
        }
      } else {
        return isExist ? '<i class="fa fa-video"></i>' : '';
      }
    }
    if(!file || !blob){
      return '';
    }
    const {type} = file; 
    const fileTypeArray = type.split('/');
    let content ='';
    switch(fileTypeArray[0]){
      case 'video':
        content = '<i class="fa fa-video"></i>'
        break;
      case 'image':
        content = `<img src="${blob}" class="input-media">`;
        break;
      default:
        content = '<i class="fa fa-file"></i>'
    }
    return content;
  }

  getImageToVideo(preg) {
    const isExist = preg.image && preg.image.length ? true : false;
    const link = preg.image ? preg.image : '';
    const existType = link.search("type");
    if (existType > 10) {
      try {
        const type = link.slice(existType);
        const typeArray = type.split('=');
        const _type = atob(typeArray[1]);
        if (_type.search('video') >= 0 || (typeArray[1].search('video') >= 0)) {
          return `<video src="${link}" controls=""></video>`;
        } else {
          return `<img src="${link}">`;
        }
      } catch (e) {
        return isExist ? `<img src="${link}">` : '';
      }
    } else {
      return isExist ? `<img src="${link}">` : '';
    }
  }

  closeModal() {
    this.isOpen = false;
    this.isOpenDelete = false;
  }

  onUpload(event: Event, element: string) {
    event.stopPropagation();
    event.preventDefault();
    document.getElementById(element).click();
  }

  checkFileSize(fileEvent: any) {
    const file = fileEvent.target.files[0];
    return (file.size / 1024 / 1024) <= 5;
  }

  checkFileType(fileEvent: any) {
    const validTypes = ['video', 'image']
    const file = fileEvent.target.files[0];
    const fileTypeArray = file.type.split('/');
    return (validTypes.indexOf(fileTypeArray[0]) > -1);
  }

  async uploadPending(event) {
    if (event.target.files && event.target.files[0]) {
      const validSize = this.checkFileSize(event);
      const validType = this.checkFileType(event);

      if (!validSize || !validType) {
        this.notification.warning(
          !validSize?"El tamaño del archivo no debe exceder los 5 MB":"Formato invalido"
        );
        document.getElementById('image-upload-q-pending').nodeValue = '';
        return
      }
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async (data) => {
        this.selectedFile = event.target.files[0];
        this.file = data.target['result'];
      };
    }
  }

  openEdit(faq: IFrequencyQuestions) {
    this.currentFaq = faq;
    this.onForm();
    this.isOpen = true;
    this.isOpenDelete = false;
    this.file = faq.image;
    this.previousImage = !!(faq.image && faq.image != '');
  }

  openRemove(faq) {
    this.currentFaq = faq;
    this.isOpen = false;
    this.isOpenDelete = true;
  }

  removeQuestion() {
    this.trash(this.currentFaq.id);
  }

  removePhoto(){
    this.selectedFile = null;
    this.file = null;
  }

  async trash(id: string): Promise<void> {
    try {
      this.spinner.show();
      await this.frequentQuestionService.delelte(id);
      this.faqs = this.faqs.filter(data => data['id'] !== id);
      this.isOpenDelete = false;
    } catch (e) {
      this.notification.error(e);
    } finally {
      this.spinner.hide();
    }
  }

  async save(): Promise<void> {
    try {
      this.submit = true;
      this.spinner.show();
      if (this.dataForm.invalid) {
        return;
      }
      let image = this.currentFaq.image || '';
      if (this.selectedFile) {
        const result = await this.coupleService.addCoverPhotoGuest(this.layoutService.getCoupleInformation().id, this.selectedFile);
        image = result?result.url:'';
      }
      if(!this.file && this.previousImage){
        image = '';
      }

      await this.frequentQuestionService.frequentQuestion(
        this.dataForm.value.question,
        this.dataForm.value.answer,
        this.currentFaq.id,
        image
      );
      this.layoutService.emitCoupleInformationUpdate();
      const faq = this.faqs.find(data => data.id === this.currentFaq.id);
      faq.answer = this.dataForm.value.answer;
      faq.question = this.dataForm.value.question;
      this.faqs = this.faqs.filter(data => data['id'] !== faq.id);
      this.isOpen = false;
    } catch (e) {
      this.notification.error(e);
    } finally {
      this.spinner.hide();
    }
  }

}
