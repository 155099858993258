import { Component, OnInit, Input } from '@angular/core';
import { IFrequencyQuestions } from 'src/app/_interface/frequencyQuestion';
import { LayoutService } from 'src/app/services/layout.service';
import { AccountService } from 'src/app/services/account.service';
import { ICoupleInformation } from 'src/app/_interface/coupleInformation';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification.service';
import { QuestionsService } from 'src/app/services/questions.service';


@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {
  @Input() couple: ICoupleInformation;
  quickLock: boolean = false;
  @Input() isGuest: boolean = false;
  faqs: any[] = [];
  constructor(
    private coupleService: AccountService,
    private layoutService: LayoutService,
    private notification: NotificationService,
    private spinner: NgxSpinnerService,
    private frequentQuestionService: QuestionsService,
  ) {
    this.couple = this.layoutService.getCoupleInformation();
    this.layoutService.coupleEmitter.subscribe(data => {
      this.couple = this.layoutService.getCoupleInformation();
    });
  }

  ngOnInit() {
    this.getFaqs();
  }

  async getFaqs(){
    const allFaqs = await this.frequentQuestionService.getBycouple(this.couple.id);
    this.faqs = allFaqs.filter(data => data.public);
  }

  existImg(preg) {
    return preg && preg.image ? true : false;
  }

  isCouple(event) {
    switch (event.type) {
      case 'WEDDING':
        return true;
      case 'BABY':
        return false;
      default:
        return true;
    }
  }

  getImageToVideo(preg) {
    const isExist = preg.image && preg.image.length ? true : false;
    const link = preg.image ? preg.image : '';
    const existType = link.search("type");
    if (existType > 10) {
      try {
        const type = link.slice(existType);
        const typeArray = type.split('=');
        const _type = atob(typeArray[1]);
        if (_type.search('video') >= 0 || (typeArray[1].search('video') >= 0)) {
          return `<video src="${link}" controls=""></video>`;
        } else {
          return `<img src="${link}">`;
        }
      } catch (e) {
        return isExist ? `<img src="${link}">` : '';
      }
    } else {
      return isExist ? `<img src="${link}">` : '';
    }
  }

  async updateView() {
    try {
      this.quickLock = true;
      let consult = await this.coupleService.updateSiteView(this.couple.id, { viewHelp: 1 });
      console.log("help", this.couple.viewHelp);
      if (consult == "ok") {
        this.couple.viewHelp = !this.couple.viewHelp;
        this.layoutService.coupleInformation.info = this.couple;
      }
      this.quickLock = false;
    } catch (e) {
      this.notification.error(e);
    } finally {
      this.spinner.hide();
    }
  }
}
