<div class="footer_top_holder">
  <div class="footer_top">
    <div class="container">
      <div class="container_inner">
        <div class="four_columns clearfix text-center text-md-left">
          <div class="column1 footer_col1">
            <div class="column_inner">
              <div id="text-2" class="widget widget_text marginds">
                <div class="textwidget">
                  <p class="m-b-m-2">Atención a clientes y contrataciones: <a class="color-text" href="callto:5512091334">55 34 48 38 40</a></p>
                  <p class="m-b-m-2">Lunes-Viernes 8am-8pm y Sábado 10am-6pm</p>
                </div>
              </div>
            </div>
          </div>
          <div class="column2 footer_col2">
            <div class="column_inner">
              <div id="nav_menu-3" class="widget widget_nav_menu">
                <div class="menu-footer-col-2-container margind-20">
                  <ul id="menu-footer-col-2" class="menu">
                    <li id="menu-item-2231"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2231">
                      <a class="color-text" target="_blank" href="https://uniko.co/contacto">Atención
                        a Parejas e Invitados</a></li>
                    <li id="menu-item-2230"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2230">
                      <a class="color-text" target="_blank" href="https://uniko.co/uniko-y-tus-invitados-bodas">Uniko y Tus
                        Invitados</a></li>
                    <li id="menu-item-2229"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2229">
                      <a class="color-text" href="https://uniko.co/planes">Precios</a></li>
                    <li id="menu-item-2470"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-2470">
                      <a class="color-text" [routerLink]="['/politica-de-privacidad']">Política de
                        Privacidad</a></li>
                    <li id="menu-item-2471"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2471">
                      <a class="color-text" [routerLink]="['/terminos-y-condiciones']">Términos
                        y Condiciones</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="column3 footer_col3">
            <div class="column_inner">
              <div id="nav_menu-2" class="widget widget_nav_menu">
                <div class="menu-footer-container margind-20">
                  <ul id="menu-footer" class="menu">
                    <li id="menu-item-1741"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1741">
                      <a class="color-text" target="_blank" href="https://uniko.co/web-de-boda/">Web de
                        Boda</a></li>
                    <li id="menu-item-1738"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1738">
                      <a class="color-text" target="_blank" href="https://uniko.co/mesa-de-regalos-de-uniko/">Mesa de
                        Regalos</a></li>
                    <li id="menu-item-1742"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1742">
                      <a class="color-text" target="_blank" href="https://uniko.co/wedding-academy-uniko-bodas">Wedding Academy</a></li>
                    <li id="menu-item-1743"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1743">
                      <a class="color-text" target="_blank" href="https://uniko.co/wedding-coching-uniko-bodas">Wedding Coaching</a>
                    </li>
                    <li id="menu-item-1744"
                      class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1744">
                      <a class="color-text"
                        href="https://evento.uniko.co/onboarding/register?utm_source=organic&amp;utm_medium=homecrearcuentafooter&amp;utm_campaign=2022">Crear
                        Una Cuenta Gratis</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="column4 footer_col4">
            <div class="column_inner marginds">
              <span class="q_social_icon_holder normal_social" data-color="#ffffff" data-hover-color="#303030"><a
                  itemprop="url" href="https://www.instagram.com/uniko.bodas/" target="_blank">
                  <i class="fab fa-instagram" style="color:#ffffff"></i></a></span><span
                class="q_social_icon_holder normal_social" data-color="#ffffff" data-hover-color="#303030"><a
                  itemprop="url" href="https://www.facebook.com/mesaderegalosunikodf/?ref=br_rs" target="_blank"><i
                    class="fab fa-facebook-square" style="color: #ffffff;margin: 0 0 0 7px;"></i></a></span> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row m-0 -p-0">
  <div class="col-6 m-0 p-0">
    <div class="m-0 p-0" style="width:100%; height:50px; background-color: #000000; color: #ffffff;">
      <p class="m-0 p-0"
        style="text-align: right; font-size:10px; padding-top:24px !important; padding-right: 7px !important;">
        Powered by</p>
    </div>
  </div>
  <div class="col-6 m-0 p-0">
    <div class="m-0 p-0"
      style="width:100%; height:50px; padding-top:10px !important; padding-left: 7px !important; background-color: #000000; color: #ffffff;">
      <img src="{{publicAssets}}/logo.png" style="height:25px;">
    </div>
  </div>
</div>