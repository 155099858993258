import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {
  @Input() time: string;
  @Output() onChange = new EventEmitter<string>();
  timeForm: FormGroup;
  hour: number;
  minute: number;
  showMeridian: boolean;
  meridian = true; //true: AM,false:PM
  maxHour = 24;
  maxHourMeridian = 12;
  hourList: string[];
  minuteList: string[];
  timeMask: string;
  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.setTime();
    this.baseFormBuild();
  }

  setTime(){
    if(this.time){
      const timeArray = this.time.split(':');
      this.hour = parseInt(timeArray[0]);
      this.minute = parseInt(timeArray[1]);
      this.meridian = this.hour<=12;
      this.changeHourToMeridian();
      this.timeMask = `${this.hour<10?'0':''}${this.hour}${this.minute}`;
    }
  }

  baseFormBuild(){
    this.timeForm = this.formBuilder.group({
      time: [this.timeMask, [Validators.pattern(/^(0?[1-9]|1[012])([0-5]\d)$/)]],
    });
  }

  changeMade (){
    const finalHour = this.timeForm.value.time.substr(0, 2);
    const finalMinute = this.timeForm.value.time.substr(2, 2);
    const time = `${this.exportHourNoMeridian(finalHour)}:${finalMinute}`; 
    console.log("Exp: ",this.timeForm.value.time,time);
    this.onChange.emit(time);
  }

  hourArrayBuilder(){
    this.hourList = [];
    const limit = this.maxHourMeridian;
    console.log(limit);
    for (let i = 1; i <= limit; i++) {
      const hourString = i<10?`0${i}`:`${i}`;
      this.hourList.push(hourString);
      console.log(".");
    }
    console.log("hours ready:", this.hourList);
  }
  minuteArrayBuilder(){
    this.minuteList = [];
    for (let i = 0; i < 60; i++) {
      const minuteString = i<10?`0${i}`:`${i}`;
      this.minuteList.push(minuteString);
    }
    console.log("minutes ready:", this.minuteList);
  }

  changeMeridian(){
    this.meridian = !this.meridian;
    this.changeMade();
  }

  changeHourToMeridian(){
    this.hour = this.hour > 12?  this.hour - 12:this.hour;
  }

  exportHourNoMeridian(finalHour){
    const newHour = !this.meridian?parseInt(finalHour)+12:parseInt(finalHour);
    return newHour<10?`0${newHour}`:newHour;!this.meridian?parseInt(finalHour)+12:parseInt(finalHour);
  }


}
