import {
  Component,
  OnInit,
  Input
} from '@angular/core';
import { Button } from './../../_models/data';
import { NgxSpinnerService } from 'ngx-spinner';
import { LayoutService } from 'src/app/services/layout.service';
import { MenuService } from 'src/app/services/menu.service';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss']
})
export class ActionMenuComponent implements OnInit {
  @Input() buttons: Array<Button>;
  @Input() menu: any;
  @Input() guest: any;
  constructor(
    private layoutService: LayoutService,
    private userService: UserService,
    private menuService: MenuService,
    private spinner: NgxSpinnerService,
    private notification: ToastrService
  ) {
    //console.log(this.buttons);
  }

  ngOnInit() {
    console.log(this.buttons);
  }

  checkMenu() {
    if (this.menu && !this.guest) {
      return this.layoutService.authorization.verify(this.menu._key);
    }
    return false
  }

  getButtonsLengt() {
    return this.buttons.length + (this.checkMenu() ? 1 : 0);
  }

  async onActive(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    try {
      this.spinner.show();
      if (this.menu && this.checkMenu()) {
        this.menu.active = !this.menu.active;
        await this.menuService.setActive(this.menu.id, this.menu.active)
      }
    } catch (e) {
      this.notification.error(<string>e);
    } finally {
      this.spinner.hide();
    }
  }

}
