import { Component, OnInit } from '@angular/core';
import { URL_RESOURCES } from 'src/environments/environment';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  terms: string = 'uniko/terminos_y_condiciones.pdf';;
  constructor() { }

  ngOnInit(): void {
  }

  getFile() {
    return `${URL_RESOURCES.DOCS}/${this.terms}`;
  }

}
