<div class="domain-share-container">
  <div class="row mb-3">
    <div class="col-12 text-center mb-5">
      <h5 class="txt-uniko">{{isCouple?'Compartan su Historia':'Comparte tu Historia'}}</h5>
      <p class="">
        {{isCouple?'Muestren a familiares y amigos su gran historia de amor por medio de sus redes sociales.':'Comparte la llegada del nuevo miembro de la familia por medio de sus redes sociales.'}}
      </p>
      <input readonly style="border: 0px;width: -webkit-fill-available;text-align: center;" type="text" id="foo"
        [value]="textlink">
    </div>
    <div class="col-12 text-center">
      <ul class="sharing-ul">
        <li class="mb-4" (click)="what()"><i class="fab fa-whatsapp"></i> Whatsapp</li>
        <li class="mb-4 clipboard" (click)="copyMessage()" data-clipboard-target="#foo"><i class="fas fa-link"></i>
          Copiar Url</li>
      </ul>
    </div>
  </div>
</div>