import { Component, OnInit, Input } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';
import { AccountService } from 'src/app/services/account.service';
import { ICoupleInformation } from 'src/app/_interface/coupleInformation';
import * as dayjs from 'dayjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { CatalogService } from 'src/app/services/catalog.service';
import { NotificationService } from 'src/app/services/notification.service';


@Component({
  selector: 'app-gift-table',
  templateUrl: './gift-table.component.html',
  styleUrls: ['./gift-table.component.scss']
})
export class GiftTableComponent implements OnInit {
  @Input() couple: ICoupleInformation;
  stores: any[] = [];
  gifts: any[] = [];
  giftReceived: number = 0;
  date: any;
  hour: any;
  dataForm: FormGroup;
  isOpen: boolean = false;
  diffDate: any = {
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00'
  }
  time: any;
  quickLock: boolean = false;
  isGuest: boolean;
  currentItemForDeletion:any;
  isOpenDelete;
  constructor(
    private layoutService: LayoutService,
    private coupleService: AccountService,
    private formBilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private notification: NotificationService,
    private catalogService: CatalogService,
  ) {
    this.isGuest = this.layoutService.getCurrentUser().id ? false : true;
    this.couple = this.layoutService.getCoupleInformation();
    this.layoutService.coupleEmitter.subscribe(data => {
      this.couple = this.layoutService.getCoupleInformation();
      this.gifts = this.couple.productsRegistryList;
      this.getInfo();
    });
    this.layoutService.itemDeletionEmitter.subscribe(itemId => {
      this.currentItemForDeletion = itemId;
      this.isOpenDelete = true;
    });
  }

  ngOnInit() {
    this.getInfo();
  }

  async getInfo() {
    this.getTotalRecived();
    this.catalogs();
    this.diffDateNow();
    this.dataForm = this.formBilder.group({
      email: ['', Validators.required]
    });
  }

  onClose() {
    this.isOpen = false;
  }

  getEventCouple(event) {
    switch (event.type) {
      case 'WEDDING':
        return true;
        break;
      default:
        return true;
    }
  }

  async saveEmail() {
    try {
      this.spinner.show();
      this.notification.success('Se le notificara por email cuando este cerca los 10 días');
      this.isOpen = false;
    } catch (e) {
      this.notification.error(<string>e);
    } finally {
      this.spinner.hide();
    }
  }

  openModal() {
    this.isOpen = true;
  }

  async catalogs(): Promise<void> {
    try {
      this.stores = await this.coupleService.getStores();
    } catch (e) {
      console.log(e);
    } finally {

    }
  }

  diffDateNow() {
    setInterval(() => {
      if (this.couple.weddingData.date) {
        const date = dayjs(this.couple.weddingData.date).add(30, 'days');
        const diffDays = dayjs(date).diff(dayjs(), 'day');
        this.diffDate = {
          days: diffDays
        }
      }
    }, 1000)
  }

  async updateView() {
    try {
      this.spinner.show();
      this.quickLock = true;
      let consult = await this.coupleService.updateSiteView(this.couple.id, { viewGifts: 1 });
      if (consult == "ok") {
        this.couple.viewGifts = !this.couple.viewGifts;
        this.layoutService.coupleInformation.info = this.couple;
      }
      this.quickLock = false;
    } catch (e) {
      this.notification.error(<string>e);
    } finally {
      this.spinner.hide();
    }
  }

  getNameStore(id: string): string {
    const store = this.stores.find(_store => _store.id == id);
    let name = store ? store.name : '';
    return name;
  }

  getTotalRecived() {
    this.giftReceived = this.gifts.reduce((total, gift) => {
      return total + gift.received;
    }, 0);
  }

  daysClose() {

  }

  async commitRemoval(): Promise<void> {
    try {
      if(!this.currentItemForDeletion){
        this.isOpenDelete = false;
      }
      const idArt = this.currentItemForDeletion;
      this.spinner.show();
      const couple = this.layoutService.getCoupleInformation();
      let _couple = await this.coupleService.get(couple.id);
      _couple.productsRegistryList = couple.productsRegistryList.filter(function (obj) {
        return obj.id != idArt;
      });
      await this.coupleService.setProductsList(_couple.id, _couple.experience, _couple.productsRegistryList);
      this.layoutService.setCoupleInformation(_couple);
      this.gifts = _couple.productsRegistryList;
      this.catalogService.catalogChanges();
      this.notification.success("Regalo eliminado");
      this.currentItemForDeletion = null;
      this.isOpenDelete = false;
    } catch (e) {
      this.notification.error(e);
    } finally {
      this.spinner.hide();
    }
  }

  async updateLite(): Promise<void> {
    try {
      this.couple = await this.coupleService.updateRegister(this.couple);
      this.layoutService.setCoupleInformation(this.couple);
    } catch (e) {
      console.log(e);
    } finally {
    }
  }
}
