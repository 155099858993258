import { Component, OnInit, Input } from '@angular/core';
import { IFrequencyQuestions } from 'src/app/_interface/frequencyQuestion';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification.service';
import { QuestionsService } from 'src/app/services/questions.service';
import { LayoutService } from 'src/app/services/layout.service';
import { ICoupleInformation } from 'src/app/_interface/coupleInformation';

@Component({
  selector: 'app-shared-questions',
  templateUrl: './shared-questions.component.html',
  styleUrls: ['./shared-questions.component.scss']
})
export class SharedQuestionsComponent implements OnInit {
  @Input() couple: ICoupleInformation;
  dataForm: FormGroup;
  submit: boolean = false;
  faqs: IFrequencyQuestions[] = [];
  faqsPublics: IFrequencyQuestions[] = [];
  faqsSolicited: IFrequencyQuestions[] = [];
  isPublic: boolean = true;
  selectedFile: any;
  file: any;
  eventType: string = 'WEDDING';
  isGuest: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private notification: NotificationService,
    private frequentQuestionService: QuestionsService,
    private layoutService: LayoutService,
  ) {
    this.isGuest = this.layoutService.isGuest();

  }

  ngOnInit(): void {
    this.getInfo();
    this.layoutService.coupleEmitter.subscribe(data => {
      this.couple = this.layoutService.getCoupleInformation();
      this.getInfo();
    });
  }

  getTypeEventString() {
    let type;
    switch (this.eventType) {
      case 'WEDDING':
        type = 'respecto a nuestra Boda'
        break;
      case 'BABY':
        type = 'para el baby shower'
        break;
      case 'BIRTHDAY':
        type = 'respecto a mi Cumpleaños'
        break;
    }
    return type;
  }

  async getInfo(): Promise<void> {
    try {
      this.spinner.show();
      this.faqs = await this.frequentQuestionService.getBycouple(this.couple.id);
      this.eventType = this.couple.weddingData.type ? this.couple.weddingData.type : this.eventType;
      this.faqsPublics = this.faqs.filter(data => data.public);
      this.faqsSolicited = this.faqs.filter(data => !data.public);
    } catch (e) {
      this.notification.error(e);
    } finally {
      this.spinner.hide();
    }
  }
}
