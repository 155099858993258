import { Component, OnInit } from '@angular/core';
import { Button, CurrentUser } from '../_models/data';
import { NotificationService } from '../services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../services/account.service';
import { LayoutService } from '../services/layout.service';
import { AnswerService } from '../services/answer.service';
import { COUNTRIES_PHONE } from '../_const/country-phones';
import { CatalogService } from '../services/catalog.service';
import { KEYS } from 'src/environments/environment';
import { OrderService } from '../services/order.service';
import { Token } from '@angular/compiler';
import { IPlan } from '../_interface/plan';
import { UserService } from '../services/user.service';
import { ICoupleInformation } from '../_interface/coupleInformation';
import { Options, ChangeContext } from '@angular-slider/ngx-slider';

declare var Conekta: any;
declare var $: any;
declare var PAYPAL: any;

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit {

  paymentForm: FormGroup;
  paymentOptionsForm: FormGroup;
  accountForm: FormGroup;
  isOpen: boolean;
  complete: boolean = false;
  buttons: Array<Button> = [];
  guest: boolean = false;
  years: number[] = [];
  countries = COUNTRIES_PHONE;
  months: string[] = [];
  monthsMSI: any[] = [];
  commision: any;
  type: any = {
    type: '',
    card: 16,
    cvc: 3
  }
  types: any = [{
    type: 'visa',
    card: 16,
    cvc: 3
  }, {
    type: 'mastercard',
    card: 16,
    cvc: 3
  }, {
    type: 'amex',
    card: 15,
    cvc: 4
  }];

  plan: any;
  boughtPlan: any;
  currentInstallment: 1;
  extraLabel: string = "";
  sliderOptions: Options = {
    showSelectionBar: true,
    showTicks: true,
    showTicksValues: true,
    disabled: false,
    getSelectionBarColor: (): string => {
      return "#fc7667";
    },
    getTickColor: (): string => {
      return "#e9ecef";
    },
    stepsArray: [
      { value: 1 },
      { value: 3 },
      { value: 6 }
    ]
  };
  sliderMessages: any = {
    1: "Difiere tu pago",
    3: "3 msi",
    6: "6 msi"
  }

  couple: ICoupleInformation;

  isRegistered: boolean = false;
  isLogged: boolean = false;
  oldAmount: number;
  couponActive: boolean = false;
  installmentsActive: boolean = true;
  planNameId: string = this.route.snapshot.queryParams["nameId"];

  constructor(
    private answerService: AnswerService,
    private layoutService: LayoutService,
    private coupleService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private notification: NotificationService,
    private catalogsService: CatalogService,
    private orderService: OrderService,
    private userService: UserService
  ) {
    this.planNameId = this.route.snapshot.params["nameId"];
    this.guest = this.layoutService.isGuest();
    const year = new Date().getFullYear();
    this.years = Array.from({ length: 11 }, (v, i) => year + i)
    this.months = Array.from({ length: 12 }, (v, i) => String(i + 1));
  }

  ngOnInit() {
    if (!this.guest) {
      this.router.navigate(["site"]);
    }
    this.prepareForms();
    this.getPlans();
  }

  prepareForms() {
    this.accountForm = this.formBuilder.group({
      email: ['', this.verifyEmail],
      password: ['', Validators.required],
      phone: ['', Validators.required],
      country: ['0052', Validators.required]
    });
    this.paymentOptionsForm = this.formBuilder.group({
      discountCode: [''],
      installments: [1],
    });
    this.paymentForm = this.formBuilder.group({
      numberCard: ['', Validators.required],
      nameCard: ['', Validators.required],
      exp_year: [new Date().getFullYear(), Validators.required],
      exp_month: [String(new Date().getMonth() + 1), Validators.required],
      cvc: ['', Validators.required],
      acceptTerms: [false, Validators.required]
    });
  }

  getEmailErrors() {
    return this.accountForm.get('email').errors['email'];
  }

  switchForms(off: boolean = false) {
    if (off) {
      this.accountForm = this.formBuilder.group({
        email: [this.accountForm.value.email, this.verifyEmail],
        password: ['', Validators.required],
      });
    } else {
      this.accountForm = this.formBuilder.group({
        email: [this.accountForm.value.email, this.verifyEmail],
        password: ['', Validators.required],
        phone: ['', Validators.required],
        country: ['0052', Validators.required]
      });
    }

  }

  updateOldPrice() {
    this.oldAmount = <number>JSON.parse(JSON.stringify(this.plan.amount));
  }

  getOldPaymentLabel() {
    const basePrice = this.oldAmount;
    return basePrice + " " + this.plan.currency;
  }

  getPaymentLabel() {
    const basePrice = this.plan.amount;
    const installments = this.paymentOptionsForm.value.installments
    this.extraLabel = this.paymentOptionsForm.value.installments > 1 ? " / mes" : "";
    const newPrice = (basePrice / installments);
    return newPrice;
  }

  verifyEmail(control: AbstractControl) {
    const emails = control.value;
    let error = null;
    if (!emails.length) {
      return { ...error, email: 'El correo es requerido' };
    }
    const valid = /^\w+([\.-]|[+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
    if (!valid.test(emails)) {
      error = { ...error, email: 'El email es inválido' };
    }
    return error;
  }

  async verifyEmailRegistry() {
    const email = this.accountForm.value.email;
    this.isRegistered = await this.coupleService.verifyMail(email);
    this.switchForms(this.isRegistered);
    //return await this.coupleService.verifyMail(email);
  }

  async getPlans() {
    try {
      this.spinner.show();
      const plans = await this.catalogsService.plans();
      this.plan = plans.find(plan => plan.nameId === this.planNameId);
      if (!this.plan) {
        this.notification.warning("No encontramos ese plan");
        this.redirectBackup();
      }
    } catch (e) {
      this.notification.error(e);
    } finally {
      this.spinner.hide();
      /*if (this.plan && !this.plan.isSuscription && !this.plan.isBasic) {
        this.getCode();
      }*/
    }
  }

  async getCode() {
    try {
      const code = await this.orderService.getCode(
        this.layoutService.coupleInformation.info.id
      );
      if (code) {
        if (code.status === 'APPROVED') {
          this.updateOldPrice();
          this.paymentOptionsForm.patchValue({
            'discountCode': code.code
          });
          this.paymentOptionsForm.get('discountCode').disable();
          if (code.discountCode.type === 'percent') {
            this.plan.amount = this.plan.amount - (this.plan.amount * (code.discountCode.amount / 100));
          } else {
            this.plan.amount = this.plan.amount - code.discountCode.amount;
          }
          this.couponActive = true;
          this.shutOffInstallments();
        }
      }
    } catch (e) {
      this.notification.error(e);
    }
  }

  async validateCode() {
    try {
      this.spinner.show();
      const code = await this.orderService.applyCode(
        this.layoutService.coupleInformation.info.id,
        this.paymentOptionsForm.value.discountCode
      )
      /*if (this.method === 'paypal-plus') {
        this.loadPaypalPlus();
      }*/
      await this.getCode();
    } catch (e) {
      this.notification.error(e);
    } finally {
      this.spinner.hide();
    }
  }

  validCard() {
    const first = this.paymentForm.value.numberCard.slice(0, 1);
    switch (first) {
      case '4':
        this.type = this.types.find(data => data.type == 'visa');
        break;
      case '3':
        this.type = this.types.find(data => data.type == 'amex');
        break;
      case '5':
        this.type = this.types.find(data => data.type == 'mastercard');
        break;
      default:
    }
  }

  goToAccount() {
    if (this.isRegistered) {
      this.router.navigate(['/profile', 'planes']);
    } else {
      this.router.navigate(['/onboarding', 'boda', '1']);
    }
  }

  redirectBackup() {
    if (this.guest) {
      this.router.navigate(['/search']);
    } else {
      this.router.navigate(['/site']);
    }
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  async planRegister() {
    try {
      this.spinner.show();
      this.markFormGroupTouched(this.paymentForm);
      //this.submitted = true;
      if (this.accountForm.invalid || this.paymentForm.invalid || this.paymentOptionsForm.invalid) {
        ;
        return;
      }

      if (!this.paymentForm.value.acceptTerms) {
        return;
      }

      if (!this.isLogged) {
        //console.log("Is not Logged");
        return;
      }

      if (this.paymentOptionsForm.value.discountCode != "") {
        this.paymentOptionsForm.patchValue({ "installments": 1 });
      }

      const token = {
        card: {
          number: this.paymentForm.value.numberCard,
          name: this.paymentForm.value.nameCard,
          exp_year: this.paymentForm.value.exp_year,
          exp_month: this.paymentForm.value.exp_month,
          cvc: this.paymentForm.value.cvc
        }
      }

      const response = await this.createToken(token);

      const plan: IPlan = {
        nameCard: this.paymentForm.value.nameCard,
        lastCard: `${this.type.type == 16 ? '**** **** ****' : '**** ******'} ${this.paymentForm.value.numberCard.slice(this.type.type == 16 ? 12 : 10, this.type.type == 16 ? 16 : 15)}`,
        token: response.id,
        conektaPlanId: this.plan.conektaPlanId,
        id: this.plan.id,
        name: this.plan.name,
        amount: this.plan.amount,
        date: new Date(),
        type: this.type.type,
        method: "card"
      }

      const planCouple = await this.coupleService.getPlanPermisions(this.couple.id);
      this.layoutService.plan.info = plan;
      this.layoutService.authorization.info = planCouple;

      const payment = {
        pay: this.layoutService.plan.info.token,
        planId: this.layoutService.plan.info.id,
        products: [],
        payer: {
          fullname: this.layoutService.plan.info.nameCard,
          phone: this.couple.phoneNumber1,
          email: this.couple.email
        },
        otro: {
          pay_type: this.layoutService.plan.info.type
        },
        method: this.plan.method === 'paypal' ? 'paypal' : 'cc',
        address: {},
        msi: this.paymentOptionsForm.value.installments,
        birthdate: {}
      }

      if (plan.method === 'paypal') {
        const response = await this.orderService.makePaypalPaymentAccount(
          this.couple.id,
          payment
        );
        //window.location.href = response.href;
      } else {
        const response = await this.orderService.makeCardPaymentAccount(
          this.couple.id,
          payment
        );
      }
      this.boughtPlan = await this.coupleService.getPlan(this.layoutService.getCoupleInformation().id);
      this.complete = true;
    } catch (e) {
      this.notification.error(e);
    } finally {
      this.spinner.hide();
    }
  }

  async accountRegister() {
    try {
      this.spinner.show();
      this.markFormGroupTouched(this.accountForm);
      if (this.accountForm.invalid) {
        return;
      }

      Conekta.setPublicKey(KEYS.CONEKTA_GUEST);
      this.layoutService.eventOnboarding('register');
      localStorage.clear();
      localStorage.setItem('type', 'guest');

      const registry = await this.coupleService.register(
        this.accountForm.value.email,
        this.accountForm.value.phone,
        this.accountForm.value.country,
        this.accountForm.value.password
      );
      const couple = registry.coupleAccount;

      const data = {
        created: registry.token.created,
        id: registry.token.id,
        role: 'couple',
        ttl: registry.token.ttl,
        user: couple
      }

      const current = new CurrentUser(data);
      this.layoutService._setCurrentUser(current);
      this.layoutService.setCoupleInformation(current.user);
      //FORCE ONBOARDING STEP 1
      this.layoutService.eventOnboarding('1');
      couple.onboardingStep = 1;
      await this.coupleService.updateRegister(couple);
      this.couple = await this.coupleService.get(couple.id);
      this.sliderOptions = Object.assign({}, this.sliderOptions, { disabled: false });
      this.isLogged = true;
    } catch (e) {
      this.notification.error(e);
      console.log(e);
    } finally {
      this.spinner.hide();
    }
  }

  shutOffInstallments(cancel?: boolean) {
    this.paymentOptionsForm.patchValue({ "installments": 1 });
    this.sliderOptions = Object.assign({}, this.sliderOptions, { disabled: !cancel });
  }

  async accountLogin(): Promise<void> {
    try {
      this.spinner.show();
      this.markFormGroupTouched(this.accountForm);
      if (this.accountForm.invalid) {
        return;
      }
      const user = await this.userService.login(
        this.accountForm.value.email,
        this.accountForm.value.password
      );
      const current = new CurrentUser(user);
      this.layoutService._setCurrentUser(current);
      this.layoutService.setCoupleInformation(current.user);
      const couple = this.layoutService.getCoupleInformation();
      const havePlan = couple.payInformationData.isPaid || couple.payInformationData.isPlan || couple.payInformationData.isPremium;
      if (havePlan) {
        this.notification.success("En hora buena, ¡ya cuentas con un plan!");
        this.router.navigate(['', 'profile', 'planes']);
      }
      this.couple = await this.coupleService.get(couple.id);
      this.isLogged = true;
    } catch (e) {
      this.notification.error(e);
    } finally {
      this.spinner.hide();
    }
  }

  async createToken(card): Promise<any> {
    Conekta.setPublicKey(KEYS.CONEKTA_ACCOUNT);
    return new Promise((resolve, reject) => {
      Conekta.token.create(card, (token: Token) => {
        return resolve(token);
      }, (error) => {
        reject(error.message_to_purchaser);
      })
    });
  }


  onUserChangeEnd(changeContext: ChangeContext): void {
    if (changeContext.value > 1) {
      this.paymentOptionsForm.controls['discountCode'].disable();
      this.installmentsActive = true;
    } else {
      this.paymentOptionsForm.controls['discountCode'].enable();
      this.installmentsActive = false;
    }
  }

}
