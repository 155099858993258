import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../services/account.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.scss']
})
export class ReferralsComponent implements OnInit {

  @Input() menuKey: string;
  name: string;
  fullname: string;
  constructor(
    private spinner: NgxSpinnerService,
    private coupleService: AccountService,
    private notification: NotificationService,
    private route: ActivatedRoute
  ) {
    this.name = this.route.snapshot.params['name'];
    this.getReferal();
  }

  ngOnInit() {

  }

  mapMenuSelected(key) {
    if (this.menuKey) {
      return this.menuKey == key;
    }
    return false;
  }

  async getReferal() {
    try {
      this.spinner.show();
      const data = await this.coupleService.getReferal(this.name);
      console.log(data);
      this.fullname = data.referal.fullName;
    } catch (e) {

    } finally {
      this.spinner.hide();
    }
  }

}
