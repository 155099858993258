import { Component, OnInit, ElementRef, ViewChild, NgZone, Input } from '@angular/core';
import { Button, ImageSnippet } from 'src/app/_models/data';
import { LayoutService } from 'src/app/services/layout.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from 'src/app/services/account.service';
import { NotificationService } from 'src/app/services/notification.service';
import { CatalogService } from 'src/app/services/catalog.service';
import 'dayjs/locale/es';
import { MenuService } from 'src/app/services/menu.service';
import { IFrequencyQuestions } from 'src/app/_interface/frequencyQuestion';
import { QuestionsService } from 'src/app/services/questions.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageCroppedEvent, LoadedImage, base64ToFile } from 'ngx-image-cropper';
import { ICoupleInformation } from 'src/app/_interface/coupleInformation';
import { URL_RESOURCES } from 'src/environments/environment';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-shared-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss']
})
export class SiteComponent implements OnInit {
  eventPlace: string;
  @Input() couple: ICoupleInformation;
  @ViewChild('search', { read: ElementRef }) public searchElementRef: ElementRef;
  @ViewChild('coverInput', { read: ElementRef }) inputImageField: ElementRef;
  gpsaddress: any = null;
  buttons: Array<Button> = [];
  isOpen: boolean = false;
  isOpenCropper: boolean = false;
  isOpenShare: boolean = false;
  submit: boolean = false;
  dataForm: FormGroup;
  isGuest: boolean = false;
  states: any[] = [];
  cover: string;
  selectedFile: any;
  state: any;
  faqs: IFrequencyQuestions[] = [];
  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCroppedImage: boolean = false;
  file: any;
  preview: boolean = false;
  theme: string = '';
  zoneDate: string;
  publicAssets = URL_RESOURCES.ASSETS;
  eventUrl: string = '';
  dataLoaded: boolean = false;
  datepickerDate: any;
  shortDate: any;
  newTime:any;
  minDate: any;
  timeDate: any;
  
  constructor(
    private layoutService: LayoutService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private coupleService: AccountService,
    private notification: NotificationService,
    private catalogsService: CatalogService,
    private frequentQuestionService: QuestionsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.eventUrl = this.activatedRoute.parent.snapshot.params["eventUrl"];
    this.layoutService.coupleEmitter.subscribe(isUpdate => {
      this.couple = this.layoutService.getCoupleInformation();
      this.getCouple();
    });
    this.couple = this.layoutService.getCoupleInformation();
    this.isGuest = this.layoutService.getCurrentUser().id ? false : true;
  }

  handleAddressChange(address: Address) {
    if (address.geometry === undefined || address.geometry === null) {
      return;
    }
    this.gpsaddress = address;
    this.eventPlace = address.name;
  }


  ngOnInit() {
    this.getCouple();
  }

  getEventString(event) {
    let type;
    switch (event.type) {
      case 'WEDDING':
        type = 'Nuestra Boda'
        break;
      case 'BABY':
        type = 'Mi Baby Shower'
        break;
      case 'BIRTHDAY':
        type = 'Mi Cumpleaños'
        break;
    }
    return type;
  }

  isCouple(event) {
    switch (event.type) {
      case 'WEDDING':
        return true;
      case 'BABY':
        return false;
      default:
        return true;
    }
  }

  setButtons() {
    const share = new Button(
      'Compartir',
      this.screenShared,
      'fa-share-alt',
      null,
      this
    );
    if (this.preview) {
      const themes = new Button(
        'Guardar tema',
        this.saveTheme,
        'fa-save',
        null,
        this
      );
      const back = new Button(
        'ver mas temas',
        this.goToThemes,
        'fa-palette',
        null,
        this
      );
      this.buttons = [share, themes, back];
    } else {
      const themes = new Button(
        'Temas',
        this.goToThemes,
        'fa-palette',
        null,
        this
      );
      this.buttons = [share, themes];
    }
  }

  goToThemes(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this['parent'].router.navigate(['/', 'site', 'themes']);
  }

  saveTheme(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this['parent'].setTheme();
  }

  screenShared(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this['parent'].isOpenShare = true;
  }

  onForm() {
    this.dataForm = this.formBuilder.group({
      name: [this.couple.weddingData.nameP1, Validators.required],
      name2: [this.couple.weddingData.nameP2],
      title: [this.couple.weddingData.titleMessage],
      shortDate:[this.shortDate],
      date: [this.datepickerDate],
      description: [this.couple.weddingData.welcomeMessage],
      placeName: [this.couple.weddingData.placeName]
    });
    this.onAddress();
  }

  onAddress() {
    //this.searchElementRef.nativeElement.value = this.couple.weddingData.address;
    this.dataForm.patchValue({
      placeName: this.couple.weddingData.address,
    });
  }

  async ClientForEventInfo(id) {
    return await this.layoutService.isGuest() ?
      this.coupleService.findOneRestrict(this.eventUrl) :
      this.coupleService.getPublic(id);
  }

  async getCouple(): Promise<void> {
    try {
      if(this.dataLoaded) return;
      
      if(this.couple && this.couple.id){
        console.log(`-----${this.couple.id}-----`);
        this.isGuest = this.layoutService.isGuest();
        //this.couple = this.layoutService.getCoupleInformation();
        this.spinner.show();
        this.couple = await this.ClientForEventInfo(this.couple.id);
        /*if (this.couple.weddingData.date) {
          let momentDate = dayjs(this.couple.weddingData.date).tz('America/Mexico_City');
          this.zoneDate = momentDate.format('DD.MM.YYYY');
          //this.couple.weddingData.date = new Date(momentDate.format());
        }*/
        const theme = this.activatedRoute.snapshot.params['theme'];
        if (theme) {
          this.preview = true;
          this.theme = theme == "uniko" ? "" : theme;
        } else {
          this.theme = this.couple.theme;
        }
        this.cover = this.couple.weddingData.coverPhoto ? this.couple.weddingData.coverPhoto : null;
        this.layoutService.setCoupleInformation(this.couple);
        this.getDate();
        this.setButtons();
        this.getFaqs();
        this.getStates();
        this.onForm();
        this.dataLoaded = true;
      }
    } catch (e) {
      console.log("Site: ", e);
      this.notification.error(e)
    } finally {
      this.spinner.hide();
    }
  }

  getDate(){
    if (this.couple.weddingData.date) {
      this.minDate = DateTime.now().plus({ days: 30}).setLocale('es-MX').toJSDate();
      const baseDate =  this.layoutService.formatDateForRead(this.couple.weddingData.date, this.couple.dateIssueFlag).setLocale('es-MX');
      this.datepickerDate = this.layoutService.formatJavaDateRead(this.couple.weddingData.date, this.couple.dateIssueFlag);
      this.shortDate = baseDate.toFormat("dd/MM/yyyy");
      this.timeDate = baseDate.toFormat('t');
    }
  }

  message(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  async setTheme() {
    try {
      this.spinner.show();
      let couple = this.couple;
      couple.theme = this.theme;
      const _couple = await this.coupleService.updateRegister(couple);
      this.layoutService.setCoupleInformation(this.couple);
      this.notification.success('Tema guardado');
    } catch (e) {
      console.log("Site: ", e);
      this.notification.error(e);
    } finally {
      this.spinner.hide();
      this.router.navigate(['/', 'site']);
    }
  }

  getCover() {
    if (this.cover == './../../assets/img/imgPrinNovios.jpg') {
      this.cover = null;
    }
    if (this.cover && this.cover != '') {
      return this.cover;
    } else {
      this.theme = this.theme ? this.theme : '';
      const typeEvent = this.couple.weddingData.type ? this.couple.weddingData.type : 'WEDDING';
      let baseImg = `${this.publicAssets}/site/site_default`;
      baseImg += (this.theme && this.theme != '') ? '_' + this.theme : this.theme;
      switch (typeEvent) {
        case 'WEDDING':
          baseImg += '_wedding.jpg';
          break;
        case 'BABY':
          baseImg += '_baby.jpg';
          break;
        default:
          baseImg += '_wedding.jpg';
      }
      return baseImg;
    }
  }


  onUpload(event: Event, element: string) {
    event.stopPropagation();
    event.preventDefault();
    document.getElementById(element).click();
  }

  setState() {
    const state = this.states.find(_state => _state.id === this.couple.stateId);
    this.state = state ? state.name : '';
    console.log(state);
  }

  openCropper(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async () => {
        //  await this.uploadCoverPhoto(url, file);
        // this.selectedFile = new ImageSnippet(url, file);
        this.isOpenCropper = true;
        this.imageChangedEvent = event;
      };
    }
  }

  async uploadCropper(file) {
    try {
      this.selectedFile = new ImageSnippet('', file);
      const coverPhoto = await this.coupleService.uploadCover(
        this.couple.id,
        this.selectedFile.file
      );
      this.cover = coverPhoto.url;
    } catch (e) {
      console.log("Site: ", e);
      this.notification.error(e);
      console.log(e);
    } finally {
    }
  }

  async upload(event, type: string) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      const file = event.target.files[0];
      const url = event.target['result'];
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = async () => { // called once readAsDataURL is completed
        await this.uploadCoverPhoto(url, file);
      };
    }
  }

  saveCropp() {
    this.showCroppedImage = true;
    this.isOpenCropper = false;
  }

  async uploadCoverPhoto(url, file): Promise<void> {
    try {
      this.selectedFile = new ImageSnippet(url, file);
      const coverPhoto = await this.coupleService.uploadCover(
        this.couple.id,
        this.selectedFile.file
      );
      this.cover = coverPhoto.url;
    } catch (e) {
      console.log("Site: ", e);
      this.notification.error(e);
    } finally {
    }
  }

  onEdit() {
    this.isOpen = true;
    this.getCouple();
  }

  async getStates(): Promise<void> {
    try {
      this.states = await this.catalogsService.states();
      this.setState();
    } catch (e) {
      console.log("Site: ", e);
      this.notification.error(e);
    }
  }

  onClose() {
    this.isOpen = false;
    this.isOpenCropper = false;
    this.showCroppedImage = false;
  }
  onCloseCropper() {
    this.isOpenCropper = false;
  }

  async onActive(active?: any): Promise<void> {

  }

  async getFaqs(): Promise<void> {
    try {
      this.faqs = await this.frequentQuestionService.getBycouple(
        this.couple.id
      );
    } catch (e) {
      console.log("Site: ", e);
      this.notification.error(e);
    }
  }

  getCity() {
    let city = this.gpsaddress.address_components.filter(items => items.types.includes('locality') || items.types.includes('sublocality')).map(function (obj) {
      return obj.long_name;
    });
    return city[0];
  }

  formatData() {
    if (this.gpsaddress && this.gpsaddress.formatted_address) {
      this.couple.weddingData.address = this.gpsaddress.formatted_address;
      this.couple.weddingData.city = this.getCity();
      this.couple.weddingData.gpsaddress = this.gpsaddress;
    }
  }

  async update(type?: string): Promise<void> {
    try {
      this.spinner.show();
      this.submit = true;
      console.log(this.dataForm.get('date').errors);
      if(this.isOpenCropper){
        this.notification.warning("Se esta editando la imagen");
        return;
      }
      if (this.dataForm.invalid) {
        return;
      }
      const couple = this.couple;
      this.formatData();
      if (this.showCroppedImage) {
        await this.uploadCropper(this.file);
        this.couple.weddingData.coverPhoto = this.cover;
      }
      if (this.dataForm.value.date) {
        
        const baseTimeArray = this.timeDate.split(":");
        const shortDateArray = this.shortDate.split("/");
        const day = shortDateArray[0];
        const month = shortDateArray[1];
        const year = shortDateArray[2];

        const newDate = this.layoutService.formatDateForSave(year,month,day,baseTimeArray[0],baseTimeArray[1]);
        couple.weddingData.dateText = newDate.minus({hours:6}).toFormat('DDDD t');
        couple.weddingData.date = newDate.toISO();
        console.log("This date should be the time +6 hours", couple.weddingData.date);
        console.log("This date should be the exact time",couple.weddingData.dateText);
      } else {
        couple.weddingData.date = null;
        couple.weddingData.dateText = "Sin Fecha";
      }

      couple.weddingData.nameP1 = this.dataForm.value.name;
      couple.weddingData.nameP2 = this.dataForm.value.name2;
      couple.weddingData.titleMessage = this.dataForm.value.title;
      couple.weddingData.welcomeMessage = this.dataForm.value.description;
      couple.weddingData.placeName = this.gpsaddress ? this.gpsaddress.name : this.dataForm.value.placeName;
      couple.stateId = this.dataForm.value.state;
      this.couple = await this.coupleService.updateRegister(couple);
      this.isOpen = false;
      this.layoutService.setCoupleInformation(this.couple);
      this.cover = this.couple.weddingData.coverPhoto;
      this.notification.success('Datos actualizados');
    } catch (e) {
      console.log("Site: ", e);
      this.notification.error(e);
    } finally {
      this.spinner.hide();
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.file = base64ToFile(this.croppedImage);
    console.log(this.file);
  }
  imageLoaded(event: LoadedImage) {
    //this.file = event.transformed.image;
  }
  cropperReady() {
    this.inputImageField.nativeElement.value = null;
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  
  handleTimeChange(time){
    this.timeDate = time;
  }

  handleManualDateChange(source, input){
    if(source=='input'){
      const dateString = this.dataForm.value.shortDate;
      const days = dateString.substr(0, 2);
      const month = dateString.substr(2, 2);
      const year = dateString.substr(4, dateString.length-4);
      const patchDate = DateTime.now().toUTC().set({year: year, month: month, day: days, hour: 6, minute: 0, second:0, millisecond:0}).setLocale("es-MX");
      const baseTimeArray = this.timeDate.split(":");
      const testDate = this.layoutService.formatInputDatepicker(year, month, days, baseTimeArray[0], baseTimeArray[1]);
      this.shortDate = patchDate.toFormat("dd/MM/yyyy");
      this.dataForm.patchValue({date: testDate});
    }else{
      const javaDate = DateTime.fromJSDate(input.value);
      const patchDate = DateTime.now().toUTC().set({year: javaDate.year, month: javaDate.month, day: javaDate.day, hour: 0, minute: 0, second:0, millisecond:0 }).setLocale("es-MX");
      this.dataForm.patchValue({shortDate: patchDate.toFormat("dd/MM/yyyy")});
    }
  }
}
