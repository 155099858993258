import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit {
  @Input() content: any;
  @Input() templateId: any;
  @Input() loadComplete: boolean;
  constructor() { }

  ngOnInit() {

  }

  get img() {
    console.log(this.content.backgroundTemplate);
    let tmpImg;
    if (Array.isArray(this.content.backgroundTemplate)) {
      tmpImg = this.content.backgroundTemplate.find(data => data.select);
    } else {
      tmpImg = this.content.backgroundTemplate;
    }
    return `${this.content.name}${tmpImg ? tmpImg.index : ''}.png`;
  }

  get getHeight() {
    if (!this.loadComplete || !this.content) {
      return 500;
    }
    let elm = document.getElementById('ornate-container');
    return elm.offsetHeight;
  }

  get getWidth() {
    if (!this.loadComplete || !this.content) {
      return 500;
    }
    let elm = document.getElementById('ornate-container');
    return elm.offsetWidth;
  }

  get background() {
    let color;
    if (Array.isArray(this.content.backgroundTemplate)) {
      color = this.content.colors.find(data => data.select);
    } else {
      color = this.content.colors;
    }
    return color ? color.color : '#fff';
  }

  get ornateColor() {
    let color;
    if (Array.isArray(this.content.backgroundTemplate)) {
      color = this.content.backgroundTemplate.find(data => data.select);
    } else {
      color = this.content.backgroundTemplate;
    }
    return color ? color.color : '#fff';
  }

  specialTemplate(template?) {
    const specialTemplates = ["3", "5", "2", "4"];
    const flag = specialTemplates.indexOf(this.templateId) >= 0 ? true : false;
    return flag;
  }

}
