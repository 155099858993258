<div class="row mb-5">
  <div class="col-12 col-md-3 mb-3">
    <div class="cardArticulo card border d-none d-sm-block">
      <ul class="list-group list-group-flush notranslate">
        <li class="list-group-item p-2 pr-3" (click)="filterPriceOpen = !filterPriceOpen">
          <span style="font-size:14px;">PRECIO</span>
          <i id="flechaPrecio" class="fas float-right" [class.fa-chevron-down]="!filterPriceOpen"
            [class.fa-chevron-up]="filterPriceOpen" style="margin-top:4px;"></i>
        </li>
        <li [attr.data-toggle]="filterPriceOpen?'down':'up'" class="list-group-item custom-slider">
          <ngx-slider class="slider-uniko" [(value)]="minValue" [(highValue)]="maxValue" [options]="sliderOptions">
          </ngx-slider>
          <p class="text-lg-center mb-0 font-weight-bold" style="font-size:.8rem;">
            {{ minValue | currency:'MXN' }} - {{ maxValue | currency:'MXN' }}</p>
        </li>
        <li class="list-group-item p-2 pr-3" (click)="filterCategoriesOpen = !filterCategoriesOpen">
          <span style="font-size:14px;">CATEGORÍAS</span>
          <i id="flechaCategoria" class="fas float-right" [class.fa-chevron-down]="!filterCategoriesOpen"
            [class.fa-chevron-up]="filterCategoriesOpen" style="margin-top:4px;"></i>
        </li>
        <li [attr.data-toggle]="filterCategoriesOpen?'down':'up'" class="list-group-item custom-slider">
          <ul class="list-unstyled text-secondary" style="font-size:14px;">
            <li *ngFor="let category of categories" (click)="setCategory(category)">
              {{
              category.name
              }}
            </li>
          </ul>
        </li>
        <li class="list-group-item p-2 pr-3" (click)="filterStoresOpen = !filterStoresOpen">
          <span style="font-size:14px;">TIENDAS</span>
          <i id="flechaTienda" class="fas float-right" [class.fa-chevron-down]="!filterStoresOpen"
            [class.fa-chevron-up]="filterStoresOpen" style="margin-top:4px;"></i>
        </li>
        <li [attr.data-toggle]="filterStoresOpen?'down':'up'" class="list-group-item custom-slider">
          <ul class="list-unstyled text-secondary" style="font-size:14px;">
            <li *ngFor="let store of stores" (click)="setStore(store)">
              {{ store.name }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="d-sm-none">
      <div class="row mobile-fitlers ">
        <div class="col-6 filter-button p-0">
          <div class="_text" (click)="isOpenFilterContainer=true">Filtrar por</div>
        </div>
        <div class="col-6 filter-button p-0">
          <div class="_text" (click)="isOpenFilter=true"><i class="fas mr-2 fa-sliders-h"></i> Ordenar por
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-9" *ngIf="giftList.length && ready">
    <div class="row mx-0">
      <div class="col-12 p-0">
        <div class="row mx-0 p-2 justify-content-between">
          <div class="col-12 col-md p-0">
            <button type="button"
              class="btn lineaColor btn-outline-danger btn-sm mr-2 pt-0 pb-0 text-secondaryBco hidden-mobile"
              style="font-size:12px;" *ngIf="!storesSelected.length && !categoriesSelected.length">
              Todo
            </button>
            <button type="button"
              class="btn lineaColor btn-outline-danger btn-sm mr-2 pt-0 pb-0 text-secondaryBco hidden-mobile"
              style="font-size:12px;" *ngFor="let store of storesSelected">
              <i class="fas fa-times mr-2" (click)="removeStore(store)"></i>{{ store.name }}
            </button>
            <button type="button"
              class="btn lineaColor btn-outline-danger btn-sm mr-2 pt-0 pb-0 text-secondaryBco hidden-mobile"
              style="font-size:12px;" *ngFor="let category of categoriesSelected">
              <i class="fas fa-times mr-2" (click)="removeCategory(category)"></i>{{ category.name }}
            </button>
          </div>
          <div class="col-12 col-md-auto p-0">
            <button type="button" (click)="colapsa('collapseOrden')" class="btn btn-outline-dark rmHover hidden-mobile"
              style="font-size:12px; font-weight:500;">
              <i class="fas mr-2 fa-sliders-h"></i>Ordenar por
              <input type="hidden" id="domCurrentOrder" [value]="currentOrder">
              <div id="collapseOrden" [attr.data-toggle]="'down'"
                style="position:absolute; display:none; z-index:9999; margin-left:-97px; margin-top:4px;">
                <ul class="list-group ulEstilos">
                  <li [class]="currentOrder==1?'list-group-item active':'list-group-item'" (click)="setPreviewOrder(1)">
                    Precio de menor a mayor</li>
                  <li [class]="currentOrder==2?'list-group-item active':'list-group-item'" (click)="setPreviewOrder(2)">
                    Precio de mayor a menor</li>
                  <li [class]="currentOrder==3?'list-group-item active':'list-group-item'" (click)="setPreviewOrder(3)" *ngIf="!isCatalog">
                    Preferidos de los novios</li>
                </ul>
              </div>
            </button>
            <button type="button" (click)="colapsa('collapseOrden')"
              class="btn btn-outline-dark ml-2 rmHover hidden-mobile" style="font-size:12px; font-weight:500;">
              <i class="fas fa-sliders-h"></i>
              <input type="hidden" id="domCurrentOrder" [value]="currentOrder">
              <div id="collapseOrden" [attr.data-toggle]="'down'"
                style="position:absolute; display:none; z-index:9999; margin-left:-97px; margin-top:4px;">
                <ul class="list-group ulEstilos">
                  <li [class]="currentOrder==1?'list-group-item active':'list-group-item'" (click)="setPreviewOrder(1)">
                    Precio de menor a mayor</li>
                  <li [class]="currentOrder==2?'list-group-item active':'list-group-item'" (click)="setPreviewOrder(2)">
                    Precio de mayor a menor</li>
                  <li [class]="currentOrder==3?'list-group-item active':'list-group-item'" (click)="setPreviewOrder(3)" *ngIf="!isCatalog">
                    Preferidos de los novios</li>
                </ul>
              </div>
            </button>
            <button type="button" (click)="colapsa2('collapseCurrency', 'collapseOrden')" *ngIf="isGuest"
              class="btn btn-outline-dark ml-2 rmHover hidden-mobile" style="font-size:12px; font-weight:500;">
              <i class="fas mr-2 fa-funnel-dollar"></i> {{ currency }}
              <input type="hidden" id="domCurrentOrder" [value]="currentOrder">
              <div id="collapseCurrency" [attr.data-toggle]="'down'"
                style="position:absolute; display:none; z-index:9999; margin-left:-97px; margin-top:4px;">
                <ul class="list-group ulEstilos">
                  <li [class]="currency == 'MXN' ? 'list-group-item active':'list-group-item'"
                    (click)="setCurrency('MXN')">MXN</li>
                  <li [class]="currency == 'USD' ? 'list-group-item active':'list-group-item'"
                    (click)="setCurrency('USD')">USD</li>
                  <li [class]="currency == 'EUR' ? 'list-group-item active':'list-group-item'"
                    (click)="setCurrency('EUR')">EUR</li>
                  <li [class]="currency == 'CAD' ? 'list-group-item active':'list-group-item'"
                    (click)="setCurrency('CAD')">CAD</li>
                </ul>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 p-0">
        <div *ngIf="!isGuest && !isCatalog" class="lista-articulos" dragula="ITEMSCTLG" [(dragulaModel)]="giftList">
          <app-gift class="col-6 col-sm-6 col-md-4 p-2" [isGuest]="isGuest" [lock]="lockDrag" [art]="art"
            [category]="getNameCategory(art.categoriesIds)" [hidden]="!dynamicFilter(art.id)" [isPaidPlan]="isPaidPlan"
            [store]="getNameStore(art.storeId)" [index]="i" *ngFor="let art of giftList; let i = index;">
          </app-gift>
        </div>
        <div *ngIf="isGuest || isCatalog" class="lista-articulos">
          <app-gift class="col-6 col-sm-6 col-md-4 p-2" [isGuest]="isGuest" [isCatalog]="isCatalog" [art]="art"
            [category]="getNameCategory(art.categoriesIds)" [hidden]="!dynamicFilter(art.id)"
            [store]="getNameStore(art.storeId)" [index]="i" [couple]="couple" [isPaidPlan]="isPaidPlan"
            *ngFor="let art of giftList; let i = index;">
          </app-gift>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-9" *ngIf="!giftList.length && ready">
    <div class="text-center col-12 mt-3 mb-5">
      <img src="{{publicAssets}}/regalo.png" class="rounded" style="width:100px; height:100px;" alt="boleto">
    </div>
    <div class="col-12">
      <h4 class="title2 text-center" style="color: #9e9e9e;">
        {{isCatalog?'Estamos cargando los productos':'¡Ya pueden comenzar su mesa!'}}
      </h4>
    </div>
    <div class="col-12 text-center">
      <p *ngIf="!isGuest && !isCatalog" class="title1 col-centered text-wrap"
        style="width:60%; font-size: .8rem; color: #333333;">
        Empezar su
        mesa de regalos nunca ha sido tan sencillo. Agreguen y creen los regalos que les gustaría
        recibir.
      </p>
    </div>
    <div class="col-12 mt-5 text-center" *ngIf="!isCatalog">
      <button class="btn btn-uniko btn-md col-centered" (click)="iniMesa()" type="button">
        Agregar regalos
      </button>
    </div>
  </div>
</div>

<div class="menu-white" *ngIf="isOpenFilterContainer && ready">
  <div>
    <div class="row">
      <div class="col-12 text-right">
        <button (click)="isOpenFilterContainer = false" class="btn my-2 my-sm-0" type="button">
          <i class="material-icons">
            clear
          </i>
        </button>
      </div>
    </div>
  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item p-2 pr-3" (click)="filterPriceOpen = !filterPriceOpen">
      <span class="title-filters">
        PRECIO
      </span>
      <i id="flechaPrecio" class="fas float-right" [class.fa-chevron-down]="!filterPriceOpen"
        [class.fa-chevron-up]="filterPriceOpen" style="margin-top:4px;">
      </i>
    </li>
    <li [attr.data-toggle]="filterPriceOpen?'down':'up'" class="list-group-item custom-slider"
      [class.hidden]="!filterPriceOpen">
      <ngx-slider class="slider-uniko" [(value)]="minValue" [(highValue)]="maxValue" [options]="sliderOptions">
      </ngx-slider>
      <p class="text-lg-center mb-0 font-weight-bold" style="font-size:.8rem;">{{ minValue | currency:'MXN' }} -
        {{ maxValue | currency:'MXN' }}</p>
    </li>
    <li class="list-group-item p-2 pr-3" (click)="filterStoresOpen = !filterStoresOpen">
      <span class="title-filters">
        TIENDAS
      </span>
      <i id="flechaTienda" class="fas float-right" [class.fa-chevron-down]="!filterStoresOpen"
        [class.fa-chevron-up]="filterStoresOpen" style="margin-top:4px;">
      </i>
    </li>
    <li [attr.data-toggle]="filterStoresOpen?'down':'up'" class="list-group-item custom-slider"
      [class.hidden]="!filterStoresOpen">
      <ul class="list-unstyled text-secondary" style="font-size:14px;">
        <li *ngFor="let store of stores" (click)="setStore(store); isOpenFilterContainer=false">
          {{ store.name }}
        </li>
      </ul>
    </li>
    <li class="list-group-item p-2 pr-3" (click)="filterCategoriesOpen = !filterCategoriesOpen">
      <span class="title-filters">
        CATEGORÍAS
      </span>
      <i id="flechaCategoria" class="fas float-right" [class.fa-chevron-down]="!filterCategoriesOpen"
        [class.fa-chevron-up]="filterCategoriesOpen" style="margin-top:4px;">
      </i>
    </li>
    <li [attr.data-toggle]="filterCategoriesOpen?'down':'up'" class="list-group-item custom-slider"
      [class.hidden]="!filterCategoriesOpen">
      <ul class="list-unstyled text-secondary" style="font-size:14px;">
        <li *ngFor="let category of categories" (click)="setCategory(category); isOpenFilterContainer=false">
          {{
          category.name
          }}
        </li>
      </ul>
    </li>
  </ul>
</div>

<div class="menu-white" *ngIf="isOpenFilter && ready">
  <div>
    <div class="row">
      <div class="col-12 text-right">
        <button (click)="isOpenFilter = false" class="btn my-2 my-sm-0" type="button">
          <i class="material-icons">
            clear
          </i>
        </button>
      </div>
    </div>
  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item p-2 pr-3">
      <span class="h1">
        Ordenar por
      </span>
      <i (click)="isOpenFilter=false" id="flechaPrecio" class="fas fa-times float-right" style="margin-top:4px;"></i>
    </li>
    <li class="list-group-item p-2 pr-3" (click)="setPreviewOrder(1); isOpenFilter = false;">
      <span class="title-filters upper">
        Precio de menor a mayor
      </span>
    </li>
    <li class="list-group-item p-2 pr-3" (click)="setPreviewOrder(2); isOpenFilter = false;">
      <span class="title-filters upper">
        Precio de mayor a menor
      </span>
    </li>
    <li class="list-group-item p-2 pr-3" (click)="setPreviewOrder(3); isOpenFilter = false;" *ngIf="!isCatalog">
      <span class="title-filters upper">
        Preferidos de los novios
      </span>
    </li>
  </ul>
</div>

<app-modal (onClosed)="isOpenDelete=false;" [isButtonShow]="false" [isOpen]="isOpenDelete">
  <div class="containerPrincipal dialog-delete">
    <div class="row">
      <div class="col-12 pr-0">
        <h1 class="title2 mb-5 colorUniko" style="font-size:1.4rem;">¿Están seguros que desean eliminar este
          regalo?</h1>
        <p class="align-middle" style="margin-bottom: 0px !important;">
          <small class="font-weight-bold">Antes de continuar</small>
        </p>
        <p class="mb-3">
          Este regalo no aparecerá más en tu mesa, si cambias de parecer podrás accesar a “Agregar Regalos”
          volver a agregarlo
        </p>
      </div>
      <div class="col-12 p-20 text-center" style="padding: 10px;">
        <button mat-button (click)="isOpenDelete=false;" class="btn float-left">
          No, Cancelar
        </button>
        <button mat-button (click)="commitRemoval()" class="btn text-danger float-right">
          Sí, eliminarlo
        </button>
      </div>
    </div>
  </div>
</app-modal>