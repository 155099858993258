import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { URL_RESOURCES } from 'src/environments/environment';
import { LayoutService } from 'src/app/services/layout.service';
import Clipboard from './../../../assets/clipboard.js-2.0.4';


@Component({
  selector: 'app-sharing',
  templateUrl: './sharing.component.html',
  styleUrls: ['./sharing.component.scss']
})
export class SharingComponent implements OnInit {

  couple: any;
  @Input() isOpen: boolean = false;
  @Input() customUrl: string = "";
  textlink: string = '';
  isCouple: boolean = true;
  constructor(
    private notification: ToastrService,
    private layoutService: LayoutService
  ) {
    this.isCouple = this.getCouple(this.layoutService.coupleInformation.info.weddingData.type);
    this.couple = this.layoutService.getCoupleInformation();
    this.layoutService.coupleEmitter.subscribe(data => {
      this.couple = this.layoutService.getCoupleInformation();
      this.setTextLink();
    });
    this.setTextLink();
  }

  setTextLink(){
    if (this.couple.domine) {
      this.textlink = this.couple.domine;
    } else {
      this.textlink = `${URL_RESOURCES.UNIKO}/${this.couple.url}/`;
    }
  }

  ngOnInit() {
    try {
      this.textlink = this.textlink + this.customUrl;
      console.log(this.textlink);
      new Clipboard(".clipboard");
    } catch (e) {

    }
  }

  getCouple = (type) => {
    switch (type) {
      case 'BABY':
        return false;
      case 'WEDDING':
      default:
        return true;
    }
  }

  what() {
    window.open(
      `https://wa.me/?text=${encodeURI(this.textlink)}`,
      "Diseño Web", "width=300, height=200");
  }

  copyMessage() {
    this.notification.success('Copiado al porta papeles');
  }

}
