import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { LayoutService } from '../services/layout.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private userService: UserService, private spinner: NgxSpinnerService, private router: Router, private layoutService: LayoutService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(<any>catchError(err => {
            if (err.status === 401) {
                if (localStorage.getItem('currentUser')) {
                    this.userService.logout();
                    this.layoutService.clear();
                    location.reload();
                }
            }

            const error = err.status === 0 ? "Se ha perdido la conexión"
                : (
                    (
                        err.error && err.error.error && err.error.error.details
                            ? err.error.error.details[0].message
                            : err.error.message ||
                            err.error.error.message ||
                            err.statusText
                    )
                );

            if (err.error && err.error.error && err.error.error.forceRedirect) {
                if (err.error.error.forcedUrl) {
                    this.router.navigate(['/', err.error.error.forcedUrl, 'checkout', 'cart']);
                }
                //return throwError(error);
            }

            console.log(error);
            return throwError(error);
        }))
    }
}