import { ISaveDate } from "../_interface/sateDate";

export class SaveDate {
    protected _saveDate: ISaveDate;
    constructor() {

    }

    get saveDate() {
        return this._saveDate;
    }

    set(saveDate: ISaveDate) {
        this._saveDate = saveDate;
    }
}