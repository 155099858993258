import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_RESOURCES } from 'src/environments/environment';
import { ISaveDate } from "../_interface/sateDate";

@Injectable({
  providedIn: 'root'
})
export class SaveDateService {

  constructor(
    private http: HttpClient
  ) { }

  async getSaveDate(copleAccountId: string): Promise<ISaveDate> {
    return this.http.get<ISaveDate>(
      `${URL_RESOURCES.API_UNIKO}/saveDates/couple/${copleAccountId}`
    ).toPromise();
  }

  async setSaveDate(saveDate: ISaveDate): Promise<ISaveDate> {
    return this.http.post<ISaveDate>(
      `${URL_RESOURCES.API_UNIKO}/saveDates/add`,
      saveDate
    ).toPromise();
  }
}
