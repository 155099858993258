import { Component, OnInit, Input, ElementRef, ViewChild, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { LayoutService } from 'src/app/services/layout.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from 'src/app/services/account.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ICoupleInformation } from 'src/app/_interface/coupleInformation';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MapGeocoder } from '@angular/google-maps';
import { HttpClient } from '@angular/common/http';
import { URL_RESOURCES } from 'src/environments/environment';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'app-hotels',
  templateUrl: './hotels.component.html',
  styleUrls: ['./hotels.component.scss']
})
export class HotelsComponent implements OnInit {
  dataForm: FormGroup;
  plans: FormArray;
  isOpen: boolean = false;
  isOpenInfo: boolean = false;
  mapAddress: any;
  hoteleGps: any[] = []
  @ViewChild('search') public searchElementRef: ElementRef;
  submitted: boolean = false;
  showMap: boolean = false;
  hotelsList: any[] = [];
  @Input() isGuest: boolean = false;
  @Input() couple: ICoupleInformation;
  quickLock: boolean = false;
  latitude: number = 51.678418;
  longitude: number = 7.809007;
  zoom = 11;
  gpsaddress: any;
  hotel: any;
  mapOptions: any;
  apiLoaded: Observable<boolean>;
  publicAssets = URL_RESOURCES.ASSETS;
  hotelMarkerOptions: google.maps.MarkerOptions = { draggable: false };
  eventMarkerOptions: google.maps.MarkerOptions = { draggable: false };
  constructor(
    private layoutService: LayoutService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private coupleService: AccountService,
    private notification: NotificationService,
    private httpClient: HttpClient
  ) {
    this.isGuest = this.layoutService.isGuest();
    this.couple = this.layoutService.getCoupleInformation();
    this.layoutService.coupleEmitter.subscribe(data => {
      this.couple = this.layoutService.getCoupleInformation();
      this.loadMaps();
    });
    this.getHotels();

  }

  ngOnInit() {
    this.onForm();
    this.loadMaps();
  }

  get plan() {
    return this.dataForm.get('plan') as FormArray;
  }

  get eventHaveAddress() {
    return !!(this.couple && this.couple.weddingData.gpsaddress);
  }

  get eventMarkerPosition() {
    if (this.couple && this.couple.weddingData.gpsaddress) {
      return this.couple.weddingData.gpsaddress.geometry.location;
    }
    return {};
  }

  get markerIcon() {
    return { url: this.getIconByTheme() }
  }

  onClosedInfo() {
    this.isOpenInfo = false;
  }

  getIconByTheme() {
    const theme = this.couple.theme;
    switch (theme) {
      case 'theme_gold':
        return `${this.publicAssets}/corazon_gold.svg`
        break;
      case 'theme_cake':
        return `${this.publicAssets}/corazon_cake.svg`
        break;
      default:
        return `${this.publicAssets}/corazon.svg`
    }
  }

  private async setCurrentLocation() {
    try {
      this.spinner.show();
      if (navigator.geolocation && !this.couple.weddingData.gpsaddress) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.zoom = 13;
        });
      } else if (this.couple.weddingData.gpsaddress) {
        this.latitude = this.couple.weddingData.gpsaddress.geometry.location.lat;
        this.longitude = this.couple.weddingData.gpsaddress.geometry.location.lng;
        this.zoom = 13;
        this.mapOptions = {
          center: { lat: this.latitude, lng: this.longitude },
          zoom: this.zoom,
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.spinner.hide();
    }
  }

  loadMaps() {
    try{
    this.apiLoaded = this.httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyC-cNfPK1guu4XvoxSpktEIkg_xvf7-gHU', 'callback')
      .pipe(
        map((res) => true),
        catchError(() => of(false)),
      );
    }catch(error){
      console.log("Exception", error);  
      this.apiLoaded = of(false);
    }
    this.setCurrentLocation();
  }

  async removeHotel() {
    try {
      this.spinner.show();
      await this.coupleService.delHotel(this.couple.id, this.hotel.id);
      this.isOpenInfo = false;
      this.getHotels();
    } catch (e) {
      this.notification.error(e);
    } finally {
      this.spinner.hide();
    }
  }

  confirmDelHotel(hotel: any, planId?: string) {
    this.isOpenInfo = true;
    this.hotel = hotel;
    if (planId) {
      this.hotel.removePlanId = planId;
    }
  }

  async removePlan(id: string) {
    try {
      this.isOpenInfo = true;
    } catch (e) {
      this.notification.error(e);
    } finally {
      this.spinner.hide();
    }
  }

  add() {
    this.isOpen = true;
    this.submitted = false;
    this.dataForm.reset();
    this.onForm();
    this.onAddress();
  }

  onClosed() {
    this.isOpen = false;
  }

  onForm() {
    this.dataForm = this.formBuilder.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      address: ['', Validators.required],
      plan: new FormArray([])
    });
  }

  onAddress() {
    this.searchElementRef.nativeElement.value = '';

  }


  async getHotels(): Promise<void> {
    try {
      this.spinner.show();
      this.hotelsList = await this.coupleService.getHotels(this.layoutService.getCoupleInformation().id);
      this.hoteleGps = this.hotelsList.filter(data => data.gpsaddress);
    } catch (e) {
      this.notification.error(e);
    } finally {
      this.spinner.hide();
    }
  }

  delPlan(event: Event, plan) {
    event.stopPropagation();
    event.preventDefault();
    this.plans.controls.splice(plan, 1);
  }

  async delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  getHotelLocation(gpsaddress) {
    if (gpsaddress && 'geometry' in gpsaddress) {
      const options = {
        lat: gpsaddress.geometry.location.lat,
        lng: gpsaddress.geometry.location.lng
      };
      return options;
    }
    return { lat: 0, lng: 0 };
  }

  async updateView(view) {
    try {
      this.spinner.show();
      this.quickLock = true;
      let consult = await this.coupleService.updateSiteView(this.couple.id, view);
      if (consult == "ok") {
        this.couple.viewHotel = !this.couple.viewHotel;
        this.layoutService.coupleInformation.info = this.couple;
      }
      this.quickLock = false;
    } catch (e) {
      this.notification.error(e);
    } finally {
      this.spinner.hide();
    }
  }

  handleAddressChange(address: Address) {
    if (address.geometry === undefined || address.geometry === null) {
      return;
    }
    this.dataForm.patchValue({
      address: address.formatted_address,
    });
    this.gpsaddress = address;
  }

  addPlan() {
    this.plans = this.dataForm.get('plan') as FormArray;
    this.plans.push(this.createPlan());
    console.log(this.plans);
  }

  createPlan(): FormGroup {
    return this.formBuilder.group({
      bed: ['1', Validators.required],
      price: [0, Validators.required],
      cupon: [''],
      name: ['', Validators.required]
    })
  }

  async update(): Promise<void> {
    try {
      this.spinner.show();
      this.submitted = true;
      if (this.dataForm.invalid) {
        return;
      }
      const hotel = {
        name: this.dataForm.value.name,
        phone: this.dataForm.value.phone,
        address: this.dataForm.value.address,
        plan: this.dataForm.value.plan,
        gpsaddress: this.gpsaddress
      }
      const data = await this.coupleService.setHotels(this.couple.id, hotel);
      this.isOpen = false;
      this.getHotels();
    } catch (e) {
      this.notification.error(e);
    } finally {
      this.spinner.hide();
    }
  }

  async updateLite(): Promise<void> {
    try {
      this.couple = await this.coupleService.updateRegister(this.couple);
      this.layoutService.setCoupleInformation(this.couple);
    } catch (e) {
      console.log(e);
    } finally {
    }
  }
}
