import { ICart } from '../_interface/cart';
import { EventEmitter, Output, Injectable } from '@angular/core';


@Injectable()
export class Cart {
    protected _cart: ICart = {
        products: [],
        total: 0,
        commission: 0,
        commissionMsi: 0,
        product: {},
        type: 'card'
    };
    @Output() cartEmitter: EventEmitter<boolean> = new EventEmitter();
    constructor() {
        this._cart.products = [];
        if (localStorage.getItem('cart')) {
            this._cart = this.decrypt(localStorage.getItem('cart') || '');
        }
    }

    get cart() {
        return this._cart;
    }

    get type() {
        return this._cart.type;
    }

    set type(type) {
        this._cart.type = type;
        this.setLocal();
    }

    get products() {
        return this._cart.products;
    }

    setCommisions(commisions?: number, commisionsMsi?: number) {
        this._cart.commission = commisions;
        this._cart.commissionMsi = commisionsMsi;
        this.cartEmitter.emit(true);
    }

    add(product: { id: any; quantity: any; }) {
        this.cart.product = product;
        const products = this.cart.products.find(data => data.id == product.id);
        if (products) {
            console.log(products.quantity, product.quantity);
            products.quantity += product.quantity;;
        } else {
            this.cart.products.push(product);
        }
        this.cartEmitter.emit(true);
        this.setLocal();
    }

    emitChanges(){
      this.cartEmitter.emit(true);
    }

    increment(product: { id: any; }) {
        this.cart.product = product;
        const products = this.cart.products.find(data => data.id == product.id);
        if (products) {
            products.quantity += 1;
        } else {
            this.cart.products.push(product);
        }
        this.setLocal();
    }

    reduce(product: { id: any; quantity: number; }) {
        this.cart.product = product;
        const products = this.cart.products.find(data => data.id == product.id);
        if (products) {
            products.quantity -= 1;
            if (product.quantity == 0) {
                this.remove(product);
            }
        }
        this.setLocal();
    }

    remove(product: { id: any; quantity?: number; }) {
        let index = 0;
        this.cart.products.forEach((data, _index) => {
            if (data.id == product.id) {
                index = _index;
            }
        });
        this.cart.products.splice(index, 1);
        this.setLocal();
    }

    get total() {
        return this.cart.products.reduce((total, data) => {
            return total + (data.quantity * data.price);
        }, 0);
    }

    get commission() {
        return this._cart.commission;
    }

    get commissionMsi() {
        return this._cart.commissionMsi;
    }

    get totalComission() {
        if (this._cart && this._cart.commission) {
            return this._cart.commission + (this._cart.commissionMsi ? this._cart.commissionMsi : 0);
        } else {
            return false;
        }
    }

    get totalItems() {
        return this.cart.products.reduce((total, data) => {
            return total + data.quantity;
        }, 0);
    }

    get productIds() {
        const ids: any[] = [];
        this.cart.products.forEach(product => {
            for (let x = 0; x < product.quantity; x++) {
                ids.push(product.id);
            }
        });
        return ids;
    }

    setLocal() {
        localStorage.setItem('cart', this.encrypt(this._cart));
    }

    decrypt(data: string): any {
        try {
            return JSON.parse(unescape(atob(atob(atob(data)))));
        } catch (e) {
            try {
                return atob(atob(atob(unescape(data))));
            } catch (e) {

            }
        }
    }

    encrypt(data: any): string {
        try {
            return btoa(btoa(btoa(escape(JSON.stringify(data)))));
        } catch (e) {
            try {
                return btoa(btoa(btoa(escape(data))));
            } catch (err) {
                return '';
            }
        }
    }

    clear() {
        localStorage.removeItem('cart');
    }


}