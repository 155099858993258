import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { URL_RESOURCES } from 'src/environments/environment';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  assistantId: string;
  assistantInfo: any;
  complete: boolean = false;
  options: any[] = [];
  publicAssets = URL_RESOURCES.ASSETS;
  constructor(
    private coupleService: AccountService,
    private spinner: NgxSpinnerService,
    private notification: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activateRoute: ActivatedRoute
  ) {
    activateRoute.params.subscribe(params => {
      this.assistantId = params['id'];
    });
  }

  async ngOnInit() {
    this.spinner.show();
    console.log(this.assistantId);
    await this.getAssistantInfo();
    console.log(this.assistantInfo);
    this.spinner.hide();
  }

  async getAssistantInfo() {
    try {
      this.assistantInfo = await this.coupleService.getAssistantInfo(this.assistantId);
      for (let i = 1; i <= this.assistantInfo.asistentes; i++) {
        this.options.push(i);
      }
    } catch (e) {
      this.notification.error(<string>e);
    }
  }

  async confirmAssistants() {
    try {
      this.spinner.show();
      const result = await this.coupleService.confirmAssistant(this.assistantId, this.assistantInfo.asistentesConfirm);
      console.log(result);
    } catch (e) {
      this.notification.error(<string>e);
    } finally {
      this.complete = true;
      this.spinner.hide();
    }
  }

}
