<div class="card-articulo card" id="{{ art.id }}">
  <div class="card-articulo-imagen noselect">
    <img (click)="isGuest?goTo(art.id):{}" [src]="getImage()" class="card-img-top" alt="...">
    <div *ngIf="!isGuest && !isCatalog" class="control-container">
      <span class="acomodoArt">
        <i *ngIf="!lock" class="fas fa-th handle"></i>
        <i *ngIf="lock" class="fas fa-spinner fa-spin"></i>
      </span>
      <i class="fas fa-trash-alt borrarArt" (click)="borrarArt( art.id )"></i>
      <i class="fas fa-pen editarArt" (click)="editarArt( art.id )"></i>
    </div>
  </div>
  <div class="card-articulo-contenido card-body">
    <div *ngIf="isCatalog" class="control-container mb-3 text-center">
      <button class="btn btn-uniko" (click)="addItem(art.id)" type="button">+ Agregar</button>
      <!--<button class="btn-view" (click)="goTo(art.id)" type="button">
        <i class="material-icons white">
          visibility
        </i>
      </button>-->
    </div>
    <div class="position-relative">
      <h5 class="cardArticulo-title card-title mb-2">{{ art.name }}</h5>
      <p class="cardArticulo-text card-text mb-2">{{ category || "&nbsp;" }}</p>
      <p class="cardArticulo-text card-text mt-2 row mx-0">
        <small class="col-12 col-lg-auto p-0 precioArticulo font-weight-bold notranslate">{{ formatPrice | currency:currency
          }}</small>
        <small class="col-12 col-lg p-0 font-weight-bold colorUniko text-right">{{ store || "&nbsp;" }}</small>
      </p>
    </div>
  </div>
  <div class="text-center catalog-hidden-controls" *ngIf="isGuest && !isCatalog">
    <button class="btn btn-uniko w-100" (click)="add(art)" [disabled]="!isPaidPlan">
      <div class="row mx-0 justify-content-center">
        <div class="col-12 col-md-auto px-0 text-center">
          <div class="iconCointainer position-relative d-inline">
            <i class="fas fa-shopping-bag regalarArt tb-secondary"></i>
            <i class="fas fa-plus regalarArtMas tb-secondary"></i>
          </div>
        </div>
        <div class="hidden-sm ml-2 px-0 col-12 col-md-auto d-flex align-items-center justify-content-center">Regalar</div>
      </div>
    </button>
  </div>
</div>