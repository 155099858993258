import { IOrder } from '../_interface/order';

export class Order {
  protected _order: IOrder;

  constructor() {
    if (localStorage.getItem('order')) {
      this._order = this.decrypt(localStorage.getItem('order') || '');
    }
  }

  get get() {
    return this._order;
  }

  set set(order: IOrder) {
    this._order = order;
    this.setLocal();
  }

  setLocal() {
    localStorage.setItem('order', this.encrypt(this._order));
  }

  decrypt(data: string): any {
    try {
      return JSON.parse(unescape(atob(atob(atob(data)))));
    } catch (e) {
      try {
        return atob(atob(atob(unescape(data))));
      } catch (e) {

      }
    }
  }

  encrypt(data: any): string {
    try {
      return btoa(btoa(btoa(escape(JSON.stringify(data)))));
    } catch (e) {
      try {
        return btoa(btoa(btoa(escape(data))));
      } catch (err) {
        return '';
      }
    }
  }

  clear() {
    localStorage.removeItem('order');
  }

}