<div class="container-fluid p-0 m-0">
  <div class="row m-0">
    <div class="col-12 col-centered text-center p-0">
      <div class="header-text">
        <div class="title-text">
          <p class="m-0 h1">{{ 'el diseño ideal' | uppercase }}</p>
          <h1 class="h2">Conozcan los diseños que<br>tenemos para ustedes</h1>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col-12">
      <p class="m-4 text-center" style="font-size: 1.2rem;">Creen momentos memorables para su evento y elijan el
        diseño que mejor los identifique.</p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-12 col-lg-8">
      <div class="row m-0 p-0">
        <div class="col-6 p-0 mb-3">
          <div class="card p-3" [class.theme-active]="isSelected && saveDate.templateId == 1">
            <img class="img-fluid rounded " routerLinkActive="active" routerLink="/save-date/viewer/1"
              src="{{publicAssets}}/templates/template1{{getType()}}.jpg">
            <p class="m-0 p-1 mt-2 txt-uniko" style="text-align: center; font-weight: 600; font-size: 12px;">
              {{ 'uniko' | uppercase }}</p>
          </div>
        </div>
        <div class="col-6 p-0 mb-3">
          <div class="card p-3" [class.theme-active]="isSelected && saveDate.templateId == 2">
            <img class="img-fluid rounded " routerLinkActive="active" routerLink="/save-date/viewer/2"
              src="{{publicAssets}}/templates/template2{{getType()}}.jpg">
            <p class="m-0 p-1 mt-2 txt-uniko" style="text-align: center; font-weight: 600; font-size: 12px;">
              {{ 'black & white' | uppercase }}</p>
          </div>
        </div>

        <div class="col-6 p-0 mb-3">
          <div class="card p-3" [class.theme-active]="isSelected && saveDate.templateId == 3">
            <img class="img-fluid rounded " routerLinkActive="active" routerLink="/save-date/viewer/3"
              src="{{publicAssets}}/templates/template3{{getType()}}.jpg">
            <p class="m-0 p-1 mt-2 txt-uniko" style="text-align: center; font-weight: 600; font-size: 12px;">
              {{ 'flower power' | uppercase }}</p>
          </div>
        </div>
        <div class="col-6 p-0 mb-3">
          <div class="card p-3" [class.theme-active]="isSelected && saveDate.templateId == 4">
            <img class="img-fluid rounded " routerLinkActive="active" routerLink="/save-date/viewer/4"
              src="{{publicAssets}}/templates/template4{{getType()}}.jpg">
            <p class="m-0 p-1 mt-2 txt-uniko" style="text-align: center; font-weight: 600; font-size: 12px;">
              {{ 'moonlight' | uppercase }}
            </p>
          </div>
        </div>
        <div class="col-6 p-0 mb-3">
          <div class="card p-3" [class.theme-active]="isSelected && saveDate.templateId == 5">
            <img class="img-fluid rounded " routerLinkActive="active" routerLink="/save-date/viewer/5"
              src="{{publicAssets}}/templates/template5{{getType()}}.jpg">
            <p class="m-0 p-1 mt-2 txt-uniko" style="text-align: center; font-weight: 600; font-size: 12px;">
              {{ 'billiant lettering' | uppercase }}</p>
          </div>
        </div>
        <div class="col-6 p-0 mb-3">
          <div class="card p-3" [class.theme-active]="isSelected && saveDate.templateId == 6">
            <img class="img-fluid rounded " routerLinkActive="active" routerLink="/save-date/viewer/6"
              src="{{publicAssets}}/templates/template6{{getType()}}.jpg">
            <p class="m-0 p-1 mt-2 txt-uniko" style="text-align: center; font-weight: 600; font-size: 12px;">
              {{ 'Imagen Propia' | uppercase }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <h3 class="m-3 text-center txt-uniko m-text">¡La aventura de sus vidas está<br>por comenzar!</h3>
    </div>
  </div>
</div>