import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() isOpen: boolean;
  @Input() large: boolean = false;
  @Input() small: boolean = false;
  @Input() textButton: string = 'Guardar';
  @Input() isButtonShow: boolean = true;
  @Input() closeHeader: boolean = false;
  @Input() noPadding: boolean = false;
  @Input() lockedAction: boolean;
  @Output() onAction = new EventEmitter<string>();
  @Output() onClosed = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {

  }

  onAccept() {
    this.onAction.emit('ok');
  }

  onClose() {
    this.onClosed.emit();
  }

}
