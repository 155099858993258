import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_RESOURCES } from 'src/environments/environment';
import { IProductRegister, Couple } from '../_models/data';
import { ICoupleInformation, instagramInfo } from '../_interface/coupleInformation';
import { IAuthorization } from '../_interface/authorization';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient) {

  }

  async get(id: string): Promise<ICoupleInformation | undefined> {
    return this.http.get<ICoupleInformation>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${id}`,
      {
        params: {
          filter: JSON.stringify({ includes: ['cashOutInformationList'] })
        }
      }
    ).toPromise();
  }

  async getLite(id: string, filter: any = null): Promise<ICoupleInformation | undefined> {
    return this.http.get<ICoupleInformation>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${id}`,
      {
        params: {
          filter
        }
      }
    ).toPromise();
  }

  async referals(): Promise<any> {
    return this.http.get<any>(
      `${URL_RESOURCES.API_UNIKO}/recommendationsAccounts/listCouple`
    ).toPromise();
  }

  async referalsCashout(name: string, bank: string, account: string, amount: number) {
    return this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/recommendationsAccounts/cashouts/couple/add`,
      {
        name,
        bank,
        account,
        amount
      }
    ).toPromise();
  }

  async addReferal(name: string, email: string, telephone: string) {
    return this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/recommendationsAccounts/recommendations/couple/add`,
      {
        name,
        email,
        telephone
      }
    ).toPromise();
  }

  async authInstagram(code: string): Promise<ICoupleInformation | undefined> {
    return this.http.post<ICoupleInformation>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/instagram`, {
      code
    }
    ).toPromise();
  }

  async payCredit(
    msi: number,
    token: string,
    first6: string,
    amount: number,
    country: string,
    type: string,
    fullname: string
  ): Promise<ICoupleInformation | undefined> {
    return this.http.post<ICoupleInformation>(
      `${URL_RESOURCES.API_UNIKO}/guests/credit`, {
      msi,
      token,
      first6,
      amount,
      country,
      type,
      fullname
    }
    ).toPromise();
  }

  async getPublic(id: string): Promise<ICoupleInformation | undefined> {
    const body = {
      filter: {
        where: {
          id: id
        }
      }
    }
    return this.http.post<ICoupleInformation>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/findOneRestrict`,
      body
    ).toPromise();
  }

  async getArticlesByFakeid(fakeid: string): Promise<any[] | undefined> {
    return await this.http.get<any[]>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${fakeid}/articles`
    ).toPromise();
  }

  async getArticlesByUrl(url: string): Promise<any[] | undefined> {
    return await this.http.get<any[]>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/articles/url/${url}`
    ).toPromise();
  }

  async getPlan(id: string): Promise<any> {
    return await this.http.get<any>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${id}/getPlan`
    ).toPromise();
  }

  async getReferal(name: string): Promise<any> {
    return await this.http.get<any>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/validCode/${name}`
    ).toPromise();
  }

  async getPlanPermisions(id: string): Promise<IAuthorization | undefined> {
    return await this.http.post<IAuthorization>(
      `${URL_RESOURCES.API_UNIKO}/plans/advantage`,
      {
        id: id
      }
    ).toPromise();
  }

  async getArticlesByCatalogs(priceInit: number, priceEnd: number, categories?: Array<string>, stores?: Array<string>, id?: string): Promise<any[] | undefined> {
    const query = {
      include: 'store',
      order: 'name ASC',
      where: {
        hidden: {
          neq: true
        },
        price: {
          between: [priceInit, priceEnd]
        }
      }
    }

    if (categories && categories.length) {
      query.where = Object.assign({
        ...query.where,
        "categoriesIds": {
          inq: categories
        }
      });
    }
    if (stores && stores.length) {
      query.where = Object.assign({
        ...query.where,
        "storeId": {
          inq: stores
        }
      });
    }
    if (id && id !== '') {
      query.where = Object.assign({
        ...query.where,
        "id": id
      });
    }

    return await this.http.get<any[]>(
      `${URL_RESOURCES.API_UNIKO}/producttemplates`,
      {
        params: {
          filter: JSON.stringify(query)
        }
      }
    ).toPromise();
  }

  async uploadImage(id: string, image: File): Promise<any> {
    const formData = new FormData();
    formData.append('image', image);
    return await this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/producttemplates/addImage`,
      formData
    ).toPromise();
  }

  async productRegistry(product: IProductRegister): Promise<any> {
    return await this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/producttemplates/addImage`,
      product
    ).toPromise();
  }

  async cashoutCreate(cashout: any): Promise<any> {
    return await this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/cashouts/add`,
      cashout
    ).toPromise();
  }

  async updateProductTable(product: IProductRegister, coupleAccoountId: string): Promise<any> {//Account Single O
    return await this.http.put<any>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${coupleAccoountId}/productsRegistry/${product.id}`,
      product
    ).toPromise();
  }

  async getStores(): Promise<any[] | undefined> {
    return await this.http.get<any[]>(
      `${URL_RESOURCES.API_UNIKO}/stores`
    ).toPromise();
  }

  async getCategories(): Promise<any[] | undefined> {
    return await this.http.get<any[]>(
      `${URL_RESOURCES.API_UNIKO}/categories`
    ).toPromise();
  }

  async getCategoriesForClient(id: any): Promise<any[] | undefined> {
    return await this.http.get<any[]>(
      `${URL_RESOURCES.API_UNIKO}/categories/active/${id}`
    ).toPromise();
  }

  async registerOnboarding(): Promise<any> {
    return await this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/onboardinglogin`,
      {
        source: "onBoarding"
      }
    ).toPromise();
  }

  async updateRegister(couple: ICoupleInformation): Promise<ICoupleInformation | undefined> {
    return this.http.put<ICoupleInformation>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/profileOn`,
      couple,
      {
        params: {
          id: couple.id
        }
      }
    ).toPromise();
  }

  async verifyMail(email: any) {
    try {
      const result = await this.http.get<any[] | undefined>(
        `${URL_RESOURCES.API_UNIKO}/coupleaccounts/email/exist/${email}`
      ).toPromise();
      if (result) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  async register(email: string, phone: string, prefijo: string, password: string, followup: string = ''): Promise<any> {
    return this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/register`,
      {
        email,
        phone,
        prefijo,
        password,
        followup
      }
    ).toPromise();
  }

  async suggestionsUrl(id: string): Promise<any[] | undefined> {
    return await this.http.get<any[]>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${id}/suggestionsReference`
    ).toPromise();
  }

  async getExperiences(type = 'WEDDING'): Promise<any[] | undefined> {
    return this.http.get<any[]>(
      `${URL_RESOURCES.API_UNIKO}/Experiences`,
      {
        params: {
          filter: JSON.stringify({ where: { status: true, type: type }, order: `order DESC` })
        }
      }
    ).toPromise();
  }

  async setExperiences(id: string, productsRegistryList: any[]): Promise<any[] | undefined> {
    return this.http.put<any[]>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${id}/registerGuest`,
      {
        experience: true,
        productsRegistryList: productsRegistryList
      }
    ).toPromise();
  }

  async setProductsList(id: string, experience: boolean, productsRegistryList: any[]): Promise<ICoupleInformation | undefined> {
    return this.http.put<ICoupleInformation>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${id}/registerGuest`,
      {
        experience: experience,
        productsRegistryList: productsRegistryList
      }
    ).toPromise();
  }

  async uploadCover(id: string, image: File): Promise<any> {
    const formData = new FormData();
    formData.append('image', image);
    return this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${id}/wedding/addCoverPhoto`,
      formData
    ).toPromise();
  }

  async uploadFilecashOutInformation(id: string, image: File): Promise<any> {
    const formData = new FormData();
    formData.append('image', image);
    return this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${id}/uploadFilecashOutInformation`,
      formData
    ).toPromise();
  }

  async getCashoutTotal(): Promise<any> {
    return await this.http.get<instagramInfo>(
      `${URL_RESOURCES.API_UNIKO}/cashouts/totals`
    ).toPromise();
  }

  async setCouplesInstagram(data: any): Promise<any> {
    return this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/couplesInstagram/add`,
      data
    ).toPromise();
  }

  async getCouplesInstagram(coupleAccoountId: string): Promise<any> {
    return await this.http.get<instagramInfo>(
      `${URL_RESOURCES.API_UNIKO}/couplesInstagram/info/${coupleAccoountId}`
    ).toPromise();
  }

  async addCoverPhotoGuest(id: string, image: File): Promise<any> {
    const formData = new FormData();
    formData.append('image', image);
    return this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${id}/wedding/addCoverPhotoGuest`,
      formData
    ).toPromise();
  }

  async addCoverPhotoSaveDate(id: string, image: File): Promise<any> {
    const formData = new FormData();
    formData.append('image', image);
    return this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${id}/wedding/addCoverPhotoSaveDate`,
      formData
    ).toPromise();
  }

  async getHotels(coupleAccoountId: string): Promise<any[] | undefined> {
    return this.http.get<any[]>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${coupleAccoountId}/hoteles`
    ).toPromise();
  }

  async setHotels(coupleAccoountId: string, hoteles: any): Promise<any[] | undefined> {
    return this.http.post<any[]>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${coupleAccoountId}/hoteles`,
      hoteles
    ).toPromise();
  }

  async delHotel(coupleAccoountId: string, id: string): Promise<any[] | undefined> {
    return this.http.delete<any[]>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${coupleAccoountId}/hoteles/${id}`,
    ).toPromise();
  }

  async getFilterTable(data: any): Promise<any> {
    return this.http.post<any>(`${URL_RESOURCES.API_UNIKO}/coupleaccounts/filerTable`,
      data).toPromise();
  }

  async updateTickets(coupleAccoountId: string, google: any): Promise<any> {
    return this.http.put<any[]>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${coupleAccoountId}/updateTickets`,
      google
    ).toPromise();
  }

  async getAddressZipcode(zipcode: string): Promise<any> {
    return this.http.get<any[]>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/getAddressZipcode?zipcode=` + zipcode
    ).toPromise();
  }

  async findOneRestrict(url: string): Promise<ICoupleInformation | undefined> {
    const body = {
      filter: {
        where: {
          or: [
            {
              url
            },
            {
              fakeid: url.toUpperCase()
            }
          ],
          isDisabled: false,
          isActive: true,
          tempAccount: {
            neq: true
          }
        }
      }
    }
    return this.http.post<ICoupleInformation>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/findOneRestrict`,
      body
    ).toPromise();
  }

  async setThanksMessage(coupleAccoountId: string, giftId: string, message: string) {
    return this.http.put<any[]>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${coupleAccoountId}/toThank`,
      { giftId: giftId, message: message }
    ).toPromise();
  }

  async sendValidationCode(coupleAccoountId: any, phone: any) {
    return this.http.post<any[]>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${coupleAccoountId}/sendAuthyToken`,
      { phone: phone }
    ).toPromise();
  }
  async verifyValidationCode(coupleAccoountId: any, code: any) {
    return this.http.post<ICoupleInformation[]>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${coupleAccoountId}/verifyAuthyToken`,
      { otp: code }
    ).toPromise();
  }
  async updateSiteView(coupleAccoountId: any, code: any) {
    return this.http.put<any>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${coupleAccoountId}/updateView`,
      code
    ).toPromise();
  }
  async generateDomains(url1: any, url2: any, url3: any) {
    return this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/createDomine`,
      { "url1": url1, "url2": url2, "url3": url3 }
    ).toPromise();
  }

  async getDomains(coupleAccoountId: string) {
    return this.http.get<any>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/findOne`,
      {
        params: {
          filter: JSON.stringify({ include: `domines`, where: { id: coupleAccoountId } })
        }
      }
    ).toPromise();
  }

  async getGuestListTemplate() {
    return this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/templateGuistListXls`,
      {},
      {
        responseType: 'blob' as 'json'
      }
    ).toPromise();
  }

  async getUpdatedGuestList(): Promise<any> {
    return this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/checklistDowload`,
      {},
      {
        responseType: 'blob' as 'json'
      }
    ).toPromise();
  }

  async getGuestList(id: string): Promise<any[] | undefined> {
    return this.http.get<any[]>(
      `${URL_RESOURCES.API_UNIKO}/guestsLists`,
      {
        params: {
          filter: JSON.stringify({ where: { coupleAccountId: id } })
        }
      }
    ).toPromise();
  }

  async getConfiguration(code): Promise<any> {
    return this.http.get<any>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/getConfiguration/${code}`,
      {}
    ).toPromise();
  }

  async utilsComissionCredit(): Promise<any> {
    return this.http.get<any>(
      `${URL_RESOURCES.API_UNIKO}/utils`,
      {
        params: {
          filter: JSON.stringify({ where: { type: 'comission_credit' } })
        }
      }
    ).toPromise().then(data => {
      return data[0];
    });
  }

  async uploadGuestList(coupleAccountId: any, file: File): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${coupleAccountId}/wedding/uploadGuestsList`,
      formData
    ).toPromise();
  }

  async getAssistantInfo(assistantId: any) {
    return this.http.get<any[]>(
      `${URL_RESOURCES.API_UNIKO}/guestsLists/message/token/${assistantId}`
    ).toPromise();
  }

  async confirmAssistant(assistantId: any, asistentesConfirm: any): Promise<any> {
    return this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/guestsLists/message/confirm/${assistantId}`,
      { "asistentesConfirm": asistentesConfirm }
    ).toPromise();
  }

  async onComissions(id: string): Promise<any> {
    return this.http.put<any>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${id}/onComissions`,
      {}
    ).toPromise();
  }

  async currencyrates(): Promise<any> {
    return this.http.get<any>(
      `https://free.currconv.com/api/v7/convert?q=MXN_USD,&compact=ultra&apiKey=[eb99210db54350e285c8]`,
      {}
    ).toPromise();
  }

  async formatedExchangeRate(): Promise<any[] | undefined> {
    let series = [
      { idSerie: 'SF57771', code: 'CAD', value: 0.9 },
      { idSerie: 'SF43718', code: 'USD', value: 20 },
      { idSerie: 'SF46410', code: 'EUR', value: 25 }
    ]

    let restResult = await this.exchangerate();
    restResult = restResult.bmx.series;
    restResult.forEach((serie: { idSerie: string; datos: { dato: string; }[]; }) => {
      const tempValue = series.find(value => { return value.idSerie == serie.idSerie });
      if (tempValue && tempValue.value) {
        tempValue.value = parseFloat(serie.datos[0].dato);
      }
    });
    return series;
  }

  async exchangerate(): Promise<any> {
    //SF43718,SF46410,SF57770/datos/oportuno?token=d27c5844267296a8f571a570d858521b8aa3d671439617a40dbecd23126d0335
    const series = 'SF43718,SF46410,SF57771';
    const token = 'd27c5844267296a8f571a570d858521b8aa3d671439617a40dbecd23126d0335';
    const base = `https://www.banxico.org.mx/SieAPIRest/service/v1/series/`;
    return await this.http.get<any>(
      `${base}${series}/datos/oportuno?token=${token}`, {
      params: {}
    }).toPromise();
  }

  async getAccountQuestions(): Promise<any>{
    return this.http.get<any>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/getAccountQuestions`,
      {}
    ).toPromise();
  }
}
