import { Component, OnInit, Input } from '@angular/core';
import { ICoupleInformation } from 'src/app/_interface/coupleInformation';
import { TEMPLATES } from 'src/app/_const/templates';
import { ISaveDate } from 'src/app/_interface/sateDate';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LayoutService } from 'src/app/services/layout.service';
import { SaveDateService } from 'src/app/services/save-date.service';
import { SafeHtml } from '@angular/platform-browser';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {
  @Input() templateId: number;
  desktopMode: boolean = true;
  colorDetail = 'red';
  mobileActive: boolean = false;
  couple: ICoupleInformation;
  saveDate: ISaveDate;
  colors: any[];
  svgTemplate: SafeHtml;
  svg: string;
  editDesing: boolean;
  openColor = false;
  openColorOrnate = false;
  openColorLetter = false;
  listBackround = TEMPLATES;
  template: any;
  templateObj: any;
  backgroundTemplate: any;
  color: any;
  model: string;
  sizes: any[];
  sizesModel: any;
  fontModel: any;
  sizesHeightModel: any;
  latterSpaceModel: any;
  sizesHeight: any[];
  latterSpace: any[];
  loadComplete: boolean = false;
  colorBackground: string = "#fff";
  colorOrnate: string = "#fff";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private saveDateService: SaveDateService,
    private layoutService: LayoutService,
    private spinner: NgxSpinnerService,
    private notification: NotificationService
  ) {
    this.templateId = this.route.snapshot.params['id'];
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
    this.sizes = range(5, 80, 1);
    this.sizesHeight = range(0, 10, 0.5);
    this.latterSpace = range(1, 40, 1);
    this.couple = this.layoutService.getCoupleInformation();
  }

  ngOnInit() {
    const template = Object.assign(this.listBackround[this.templateId]);
    this.templateObj = JSON.parse(JSON.stringify(template));
    this.desktopMode = this.templateObj.desktop;
    this.setImg();
    this.setColor();
    this.setModel();
    this.info();
  }

  ngOnDestroy() {
    this.templateObj = null;
  }

  setModel() {
    this.sizesModel = this.templateObj[this.templateObj.select].fontSize;
    this.sizesHeightModel = this.templateObj[this.templateObj.select].fontSize;
    this.fontModel = this.templateObj[this.templateObj.select].fontFamily;
    this.latterSpaceModel = this.templateObj[this.templateObj.select].latterSpace;
    this.model = this.templateObj[this.templateObj.select].text;
  }

  changeText($event) {
    console.log($event);
    this.templateObj[this.templateObj.select].text = this.model;
  }

  get mode() {
    return this.templateObj.desktop;
  }

  get img() {
    const img = this.templateObj.backgroundTemplate.find(data => data.select);
    return `${this.templateObj.name}${img.index}.png`;
  }

  get background() {
    const color = this.templateObj.colors.find(data => data.select);
    return color ? color.color : '#fff';
  }
  get ornateColor() {
    const color = this.templateObj.backgroundTemplate.find(data => data.select);
    return color ? color.color : '#fff';
  }

  get selectedColor() {
    const color = this.templateObj.colors.find(data => data.select);
    return color ? color.index : 0;
  }

  get selectedOrnate() {
    const color = this.templateObj.backgroundTemplate.find(data => data.select);
    return color ? color.index : 0;
  }

  setEditText(type, _style) {
    this.templateObj[this.templateObj.select][type] = _style;
  }

  changeInput(type) {
    this.editDesing = false;
    this.templateObj.select = type;
    this.setModel();
    this.mobileActive = true;
    document.getElementById('text').focus();
  }

  setImg() {
    const bg = this.templateObj.backgroundTemplate.find(data => data.select);
    this.colorOrnate = bg.color;
  }

  setColor() {
    const color = this.templateObj.colors.find(data => data.select);
    this.colorBackground = color.color;
  }

  setColorBackground(index, color?: string) {
    this.templateObj.colors = this.templateObj.colors.map(data => {
      if (data.index == index) {
        data.select = true;
        data.color = color ? color : data.color;
        this.colorBackground = color ? color : data.color;
      } else {
        data.select = false;
      }
      return data;
    });
  }

  setColorOrnate(index, color?: string) {
    this.templateObj.backgroundTemplate = this.templateObj.backgroundTemplate.map(data => {
      if (data.index == index) {
        data.select = true;
        data.color = color ? color : data.color;
        this.colorOrnate = color ? color : data.color
      } else {
        data.select = false;
      }
      return data;
    });
  }

  changeMode(event) {
    this.templateObj.desktop = event;
  }

  selectColor(index) {
    /*this.templateObj.backgroundTemplate.forEach((element, _index) => {
      element.select = element.index == index ? true : false;
    });*/
    //console.log(index);
    console.log(this.templateObj.colors, index);
    this.templateObj.colors = this.templateObj.colors.map(data => {
      if (data.index == index) {
        data.select = true;
        data.color = this.saveDate.colors.color;
      } else {
        data.select = false;
      }
      return data;
    });
  }

  selectTemplateColor(index) {
    this.templateObj.backgroundTemplate = this.templateObj.backgroundTemplate.map(data => {
      if (data.index == index) {
        data.select = true;
        data.color = this.saveDate.backgroundTemplate.color;
      } else {
        data.select = false;
      }
      return data;
    });
  }

  selectSavedColor(index) {
    /*this.templateObj.backgroundTemplate.forEach((element, _index) => {
      element.select = element.index == index ? true : false;
    });*/
    //console.log(index);
    this.templateObj.backgroundTemplate = this.templateObj.backgroundTemplate.map(data => {
      if (data.index == index) {
        data.select = true;
        data.color = this.saveDate.colors.color;
      } else {
        data.select = false;
      }
      return data;
    });

    console.log(this.templateObj.colors);

    this.templateObj.colors = this.templateObj.colors.map(data => {
      if (data.index == (index + 1)) {
        data.select = true;
        data.color = this.saveDate.colors.color;
      } else {
        data.select = false;
      }
      return data;
    });
  }

  handleChangeCompleteLatter(color) {
    this.setEditText('color', color.color.hex);
  }

  handleChangeComplete(color) {
    if (color.color.rgb.a && color.color.rgb.a < 1) {
      const colors = color.color.rgb;
      this.colorBackground = `rgba(${colors.r},${colors.g},${colors.b},${colors.a})`;
    } else {
      this.colorBackground = color.color.hex;
    }
    this.setColorBackground(5, this.colorBackground);
  }

  handleChangeCompleteOrnate(color) {
    if (color.color.rgb.a && color.color.rgb.a < 1) {
      const colors = color.color.rgb;
      this.colorOrnate = `rgba(${colors.r},${colors.g},${colors.b},${colors.a})`;
    } else {
      this.colorOrnate = color.color.hex;
    }
    this.setColorOrnate(5, this.colorOrnate);
  }

  prev() {
    this.router.navigate(['/', 'save-date', 'viewer', this.templateId]);
  }

  async info() {
    try {
      this.spinner.show();
      this.saveDate = await this.saveDateService.getSaveDate(this.couple.id);
      if (this.saveDate && String(this.templateId) === String(this.saveDate.templateId)) {
        console.log("Alpha", this.saveDate);
        //this.templateObj = this.listBackround[Number(this.saveDate.templateId)]
        this.templateObj.desktop = this.saveDate.desktop;
        this.templateObj.title = this.saveDate.title;
        this.templateObj.nametitle = this.saveDate.nametitle;
        this.templateObj.titleDate = this.saveDate.titleDate;
        this.templateObj.date = this.saveDate.date;
        this.templateObj.titleDescription = this.saveDate.titleDescription;
        this.templateObj.description = this.saveDate.description;
        this.selectColor(this.saveDate.colors.index);
        this.selectTemplateColor(this.saveDate.backgroundTemplate.index);
      } else if (this.saveDate) {
        console.log("Beta");
        this.templateObj.title.text = this.saveDate.title.text;
        this.templateObj.nametitle.text = this.saveDate.nametitle.text;
        this.templateObj.titleDate.text = this.saveDate.titleDate.text;
        this.templateObj.titleDescription.text = this.saveDate.titleDescription.text;
        this.templateObj.description.text = this.saveDate.description.text;
        this.templateObj.date.text = this.saveDate.date.text;
        this.templateObj.description.text = this.saveDate.description.text;
        this.templateObj.desktop = this.saveDate.desktop;
      } else {
        console.log("Gamma");
        this.templateObj.title.text = this.saveDate.title.text;
        this.templateObj.nametitle.text = `${this.couple.weddingData.nameP1} ${this.couple.weddingData.nameP2}`;
        this.templateObj.date.text = this.saveDate.date.text;
        this.templateObj.titleDescription.text = this.couple.weddingData.date;
        this.templateObj.description.text = this.saveDate.description.text;
        this.templateObj.date.text = this.saveDate.date.text;
        this.templateObj.description.text = this.saveDate.description.text;
        this.templateObj.desktop = this.saveDate.desktop;
      }
      //console.log(this.templateObj);
    } catch (e) {
      console.log(e);
    } finally {
      this.loadComplete = true;
      this.changeInput('title');
      this.spinner.hide();
    }
  }

  async save() {
    try {
      this.spinner.show();
      this.saveDate = {
        desktop: this.templateObj.desktop,
        template: this.templateObj.name,
        templateId: this.templateId,
        opacity: this.templateObj.opacity,
        title: this.templateObj.title,
        nametitle: this.templateObj.nametitle,
        titleDate: this.templateObj.titleDate,
        date: this.templateObj.date,
        titleDescription: this.templateObj.titleDescription,
        description: this.templateObj.description,
        backgroundTemplate: this.templateObj.backgroundTemplate.find(data => data.select === true),
        colors: this.templateObj.colors.find(data => data.select === true),
        coupleAccountId: this.couple.id,
      }
      await this.saveDateService.setSaveDate(this.saveDate);
      this.notification.success("Plantilla actualizada");
    } catch (e) {
      this.notification.error(e)
    } finally {
      this.spinner.hide();
      this.router.navigate(['/', 'save-date', 'viewer', this.templateId]);
    }
  }

}
