import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_RESOURCES } from 'src/environments/environment';
import { IFrequencyQuestions } from '../_interface/frequencyQuestion';
const URI_MODEL = 'frequentsQuestionsCouple';


@Injectable({
  providedIn: 'root'
})
export class QuestionsService {

  constructor(
    private http: HttpClient
  ) { }

  async getBycouple(coupleId): Promise<IFrequencyQuestions[]> {
    return await this.http.get<IFrequencyQuestions[]>(
      `${URL_RESOURCES.API_UNIKO}/${URI_MODEL}/getBycouple`,
      {
        params: {
          coupleId
        }
      }
    ).toPromise();
  }

  async generateQuestion(coupleId: string, question: string, image?: string): Promise<IFrequencyQuestions> {
    return await this.http.post<IFrequencyQuestions>(
      `${URL_RESOURCES.API_UNIKO}/${URI_MODEL}/generateQuestion`,
      {
        coupleId,
        question,
        image
      }
    ).toPromise();
  }

  async generateFrequentQuestion(question: string, answer: string, image?: string): Promise<IFrequencyQuestions> {
    return await this.http.post<IFrequencyQuestions>(
      `${URL_RESOURCES.API_UNIKO}/${URI_MODEL}/generateFrequentQuestion`,
      {
        question,
        answer,
        image
      }
    ).toPromise();
  }

  async uploadImageFrequentQuestion(id: string, image: File): Promise<any> {
    const formData = new FormData();
    formData.append('image', image);
    return await this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/frequentsQuestionsCouple/addImage`,
      formData
    ).toPromise();
  }

  async frequentQuestion(question: string, answer: string, questionId: string, image: string): Promise<IFrequencyQuestions> {
    return await this.http.put<IFrequencyQuestions>(
      `${URL_RESOURCES.API_UNIKO}/${URI_MODEL}/${questionId}/frequentQuestion`,
      {
        question,
        answer,
        image
      }
    ).toPromise();
  }

  async delelte(questionId: string): Promise<IFrequencyQuestions> {
    return await this.http.delete<IFrequencyQuestions>(
      `${URL_RESOURCES.API_UNIKO}/${URI_MODEL}/trash`, {
      params: {
        questionId
      }
    }
    ).toPromise();
  }
}
