import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_RESOURCES } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnswerService {

  constructor(
    private http: HttpClient
  ) { }

  async getGuestbook(
    fakeid: string
  ): Promise<any> {
    return await this.http.get<any>(
      `${URL_RESOURCES.API_UNIKO}/guestBook/filter/${fakeid}`,
    ).toPromise();
  }

  async addAnswer(
    guestBookId: string,
    comment: string,
    isPublic: string
  ): Promise<any> {
    const body = {
      guestBookId: guestBookId,
      comment: comment,
      isPublic: isPublic
    }
    return await this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/guestBook/${guestBookId}/addAnswer`,
      body
    ).toPromise();
  }

  async isShow(
    isPublic: boolean,
    guestBookId: string
  ): Promise<any> {
    const body = {
      isPublic: isPublic
    }
    return await this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/guestBook/${guestBookId}/isShow`,
      body
    ).toPromise();
  }

  async add(
    body: any
  ): Promise<any> {
    return await this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/guestBook/add`,
      body
    ).toPromise();
  }

  async remove(
    guestBookId: string
  ): Promise<any> {
    return await this.http.delete<any>(
      `${URL_RESOURCES.API_UNIKO}/guestBook/${guestBookId}/trash`
    ).toPromise();
  }
}
