<div class="container p-50 m-t">
  <div class="row">
    <div class="col-md-12 mb-12">
      <label for="" class="text-subtitle">
        Mensajes de amor
      </label>
    </div>
    <div class="col-md-12">
      <label for="" class="text-title" *ngIf="!isGuest">
        {{eventType == 'WEDDING'?'Vean las felicitaciones de sus familiares y amigos':'Ve las felicitaciones de sus familiares y amigos'}}
      </label>
      <label for="" class="text-title" *ngIf="isGuest">
        {{eventType == 'WEDDING'?'Dedica un mensaje o felicitación a la feliz pareja':'Dedica un mensaje o felicitación para el baby shower'}}
      </label>
    </div>
  </div>
  <div class="row m-t-50" *ngIf="isGuest">
    <div class="col-12 col-md-6" *ngFor="let _guest of guestBooks" [class.hidden]="_guest.isActive==false"
      [class.disabled-comment]="!_guest.isPublic">
      <div class="card shadow-lg mb-5 bg-white rounded anchoCompleto">
        <div class="card-header-buttons" style="padding-bottom:0px !important; width:100% !important;">
          <div class="icon-text">
            <img src="{{publicAssets}}/comillas.png" alt="">
          </div>
          <div class="btn-group" role="group">

          </div>
        </div>
        <div class="card-body p-3">
          <p class="card-text" style="margin-left:10px;">{{ _guest.comment }}</p>
        </div>
        <div class="img image-width" *ngIf="_guest.img.length" [innerHTML]="getImageToVideo(_guest)">

        </div>
        <div class="card-body p-3">
          <p>
            <span class="names-text" style="font-weight:600;">
              {{ _guest.names }}
            </span>
            <br />
            <span class="date-text">
              {{ _guest.date | date : "dd MMM yyyy" }}
            </span>
          </p>
          <hr class="bg-uniko" style="height:2px;">
          <button *ngIf="!isGuest && !_guest.guestBookAnswer" (click)="onEdit($event, _guest)"
            class="btn card-link comment-link colorUniko" style="font-weight:600; text-decoration:underline;">Contestar
            comentario</button>
          <p class="colorUniko" *ngIf="isGuest || _guest.guestBookAnswer && _guest.guestBookAnswer.length">
            {{ _guest.guestBookAnswer?.comment }}
            <br>
            <span class="date-text">
              {{ _guest.guestBookAnswer?.date | date : "dd MMM yyyy" }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row m-t-50" *ngIf="!isGuest && guestBooks.length">
    <div class="col-12 col-md-6" *ngFor="let _guest of guestBooks" [class.hidden]="_guest.isActive==false"
      [class.disabled-comment]="!_guest.isPublic">
      <div class="card shadow-lg mb-5 bg-white rounded anchoCompleto">
        <div class="card-header-buttons" style="padding-bottom:0px !important; width:100% !important;">
          <div class="icon-text">
            <img src="{{publicAssets}}/comillas.png" alt="">
          </div>
          <!-- <div class="icon-text" *ngIf="_guest.img.lenght">
                      <img [src]="_guest.img" alt="">
                  </div> -->
          <div class="btn-group" role="group">
            <button (click)="changePublic($event, _guest)" type="button"
              class="btn btn-nt-radius btn-secondary btn-uniko small">
              <i class="fas fa-eye"></i>
            </button>
            <button (click)="openDeleteModal(_guest)" type="button"
              class="btn btn-nt-radius btn-secondary btn-uniko-strong small">
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
        <div class="card-body p-3">
          <p class="card-text" style="margin-left:10px;">{{ _guest.comment }}</p>
        </div>
        <div class="img image-width" *ngIf="_guest.img.length" [innerHTML]="getImageToVideo(_guest)">

        </div>
        <div class="card-body p-3">
          <p>
            <span class="names-text" style="font-weight:600;">
              {{ _guest.names }}
            </span>
            <br />
            <span class="date-text">
              {{ _guest.date | date : "dd MMM yyyy" }}
            </span>
          </p>
          <hr class="bg-uniko" style="height:2px;">
          <button *ngIf="!isGuest && !_guest.guestBookAnswer" (click)="onEdit($event, _guest)"
            class="btn card-link comment-link colorUniko" style="font-weight:600; text-decoration:underline;">Contestar
            comentario</button>
          <p class="colorUniko" *ngIf="!isGuest || _guest.guestBookAnswer && _guest.guestBookAnswer.length">
            {{ _guest.guestBookAnswer?.comment }}
            <br>
            <span class="date-text">
              {{ _guest.guestBookAnswer?.date | date : "dd MMM yyyy" }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal [isOpen]="isOpenDelete" (onClosed)="closeModalDelete()" [isButtonShow]="false">
  <div class="row justify-content-center">
    <div class="col-12 col-md-8 text-center">
      <h3 class="txt-uniko">¡Atención!</h3>
      <p>¿Borrar permanentemente esta dedicatoria?</p>
    </div>
  </div>
  <div class="row justify-content-around">
    <div class="col-auto">
      <button class="btn" (click)="isOpenDelete=false">Cancelar</button>
    </div>
    <div class="col-auto">
      <button class="btn btn-uniko" (click)="remove(targetGuest)">Borrar</button>
    </div>
  </div>
</app-modal>

<app-modal [isOpen]="isOpenEdit" [large]="true" (onClosed)="closeModal()" (onAction)="saveAnswer()" textButton="Enviar">
  <div class="row">
    <div class="col-12">
      <form [formGroup]="sendAnswerForm">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="form-group text-left">
              <label class="label-control">El comentario de su invitado</label>
              <textarea class="form-control h-mobile" rows="5" formControlName="question"></textarea>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="form-group text-left">
              <label class="label-control">Escribe tu respuesta</label>
              <textarea class="form-control h-mobile" rows="5" formControlName="answer"></textarea>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</app-modal>