import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_RESOURCES } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  @Output() catalogEmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() giftEmitter: EventEmitter<boolean> = new EventEmitter();
  constructor(private http: HttpClient) { }

  async states(): Promise<any[]> {
    return await this.http.get<any[]>(
      `${URL_RESOURCES.API_UNIKO}/statecouples`
    ).toPromise();
  }

  async plans(): Promise<any[]> {
    return this.http.get<any[]>(
      `${URL_RESOURCES.API_UNIKO}/plans`,
      {
        params: {
          filter: JSON.stringify({
            include: {
              relation: 'planAggre',
              where: { 
                  isAvailable:true
              },
	      scope: {
                include: ['listAggre']
              }
            }
          })
        }
      }
    ).toPromise();
  }

  async plansType(): Promise<any[]> {
    return this.http.get<any[]>(
      `${URL_RESOURCES.API_UNIKO}/plans/list`
    ).toPromise();
  }

  catalogChanges() {
    this.giftEmitter.emit(true);
  }
}
