import { ICredit } from '../_interface/cashout';

export class Credit {
  protected _credit: ICredit;
  constructor() {
    if (localStorage.getItem('cashout')) {
      this.getLocal();
    }
  }

  get info(): ICredit {
    return this._credit;
  }

  set info(info: ICredit) {
    this._credit = info;
    this.setLocal();
  }

  clear() {
    localStorage.removeItem('cashout');
  }

  decrypt(data: string): any {
    try {
      return JSON.parse(unescape(atob(atob(atob(data)))));
    } catch (e) {
      try {
        return atob(atob(atob(unescape(data))));
      } catch (e) {

      }
    }
  }

  encrypt(data: any): string {
    try {
      return btoa(btoa(btoa(escape(JSON.stringify(data)))));
    } catch (e) {
      try {
        return btoa(btoa(btoa(escape(data))));
      } catch (err) {
        return '';
      }
    }
  }

  getLocal() {
    this._credit = this.decrypt(localStorage.getItem('credit') || '');
  }

  setLocal() {
    localStorage.setItem('credit', this.encrypt(this._credit));
  }
}