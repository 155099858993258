import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/_guards/auth.guard';
import { SearchComponent } from './search/search.component';
import { PlansComponent } from './plans/plans.component';
import { ReferralsComponent } from './referrals/referrals.component';
import { TermsComponent } from './shared/terms/terms.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { PrivacyComponent } from './shared/privacy/privacy.component';

export const AppRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'onboarding'
  },
  {
    path: 'confirmation/:id',
    component: ConfirmationComponent
  },
  {
    path: 'search',
    component: SearchComponent
  },
  {
    path: 'plans/:nameId',
    component: PlansComponent,
  },
  {
    path: 'referido/:name',
    component: ReferralsComponent
  },
  {
    path: 'terminos-y-condiciones',
    component: TermsComponent
  },
  {
    path: 'politica-de-privacidad',
    component: PrivacyComponent
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('src/app/event/event.module').then(m => m.EventModule)
  },
  {
    path: 'onboarding',
    loadChildren: () => import('src/app/onboarding/onboarding.module').then(m => m.OnboardingModule)
  },
  {
    path: ':eventUrl',
    loadChildren: () => import('src/app/guest/guest.module').then(m => m.GuestModule)
  },
];

