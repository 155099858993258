import { NgModule } from '@angular/core';
import {
  MatIconModule,
  MatToolbarModule,
  MatSnackBar,
  MatSnackBarModule,
  MatTooltipModule,
  MatButtonModule,
  MatGridListModule,
  MatInputModule,
  MatCheckboxModule,
  MatDialogModule,
  MatMenuModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSliderModule,
  MatSelectModule,
  MatOptionModule,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS
} from '@angular/material';

@NgModule({
  declarations: [],
  imports: [
    MatIconModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatButtonModule,
    MatGridListModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogModule,
    MatMenuModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSliderModule,
    MatSelectModule,
    MatOptionModule
  ],
  exports: [
    MatMenuModule,
    MatIconModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatButtonModule,
    MatGridListModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSliderModule,
    MatSelectModule,
    MatOptionModule
  ],
  providers:[
    { provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
  ]
})
export class MaterialModule { }
