<div class="container p-50 m-t">
  <div class="row">
    <div class="col-md-12 mb-12">
      <label for="" class="text-subtitle">
        ¿Tienes dudas?
      </label>
    </div>
    <div class="col-md-12">
      <label for="" class="text-title">
        Resolvemos tus dudas {{getTypeEventString()}}
      </label>
    </div>
    <div *ngIf="!isGuest" class="col-md-12 mb-12">
      <label for="" [class.btn-active]="isPublic" class="mitadEspacio" (click)="isPublic = true">
        PREGUNTAS PÚBLICAS
      </label>
      <label for="" [class.btn-active]="!isPublic" class="mitadEspacio" style="padding-left:30px;"
        (click)="isPublic = false">
        SOLICITUDES DE PREGUNTAS
      </label>
    </div>
  </div>
  <div class="row m-t-20">
    <app-public class="col-12" [isGuest]="isGuest" [faqs]="faqsPublics" *ngIf="isPublic"></app-public>
    <app-pending class="col-12" [faqs]="faqsSolicited" *ngIf="!isPublic && !isGuest"></app-pending>
  </div>
</div>