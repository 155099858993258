<div class="mb-3 rounded shadow-sm contenedor-payment">
  <div class="seal" *ngIf="plan.bestValue">
    <img src="https://s3-us-west-2.amazonaws.com/uniko-prod/assets/seal of uniko.png">
  </div>
  <div [ngClass]="{
    'lineaGris': plan.isPaid,
    'lineaUniko': !plan.isPaid
  }">&nbsp;</div>
  <div class="col-12 py-3 px-4 margins-fill">
    <div class="row m-0">
      <div class="col-12 p-0 m-0">
        <p class="p-0 m-0 title-pay">{{ plan.name }}</p>
        <p class="p-0 m-0 price notranslate" *ngIf="!plan.isBasic && !plan.isBack" [class.txt-uniko]="!plan.isPaid">
          {{ plan.amount | currency:'MXN' }} <span class="currency-text">MXN<span
              *ngIf="plan.isSuscription">/MES</span></span>

        </p>
        <p class="p-0 m-0 price" *ngIf="plan.isBack" [class.txt-uniko]="!plan.isPaid">PAGO</p>
        <p class="p-0 m-0 price" *ngIf="plan.isBasic" [class.txt-uniko]="!plan.isPaid">Gratis</p>
        <ul class="listaUl" style="margin-bottom:2rem;">
          <li [class.checkNo]="!_listAggre.isActive" [class.checkSi]="_listAggre.isActive"
            *ngFor="let _listAggre of plan.planAggre">
            {{ _listAggre.listAggre.name }}
          </li>
        </ul>
        <p *ngIf="plan.isPaid" style="font-size:16px; text-align:center; padding-top:7px;">
          Plan Actual
        </p>
        <button *ngIf="!plan.isPaid" mat-button class="btn btn-uniko col-centered btn-block capitalize"
          routerLinkActive="active" routerLink="/pago/plan/{{(plan.isSuscription ? plan.conektaPlanId : plan.id)}}"
          style="font-size: 16px;">
          {{ (plan?.isSuscription ? 'Elegir Plan' : 'Pago en una exhibición') }}
        </button>
      </div>
    </div>
  </div>
</div>