<div class="container-fluid p-0 m-0 h-100">
  <div class="d-flex align-items-start flex-column h-100 m-0 p-0">
    <div class="flex-grow w-100 h-100">
      <div class="w-100 h-100 flex-grow-1 d-flex flex-column">
        <app-header menuKey="" [public]="true"></app-header>
        <div class="w-100 py-5 flex-grow-1">
          <iframe class="w-100 h-100"
            src="https://uniko-prod.s3.us-west-2.amazonaws.com/documents/uniko/politica_de_privacidad.pdf"></iframe>
        </div>
      </div>
    </div>
    <div class="w-100">
      <app-footer></app-footer>
    </div>
  </div>
</div>