import { EventEmitter, Output } from '@angular/core';
import { ICoupleInformation } from '../_interface/coupleInformation';


export class User {
    createdAt: Date;
    isActive: boolean;
    role: string;
    updatedAt: Date;
    username: string;
    email?: string;
    constructor(data?: any) {
        if (data) {
            this.createdAt = data.createdAt;
            this.isActive = data.isActive;
            this.role = data.role;
            this.updatedAt = data.updatedAt;
            this.username = data.username;
            this.email = this.email;
        }
    }
}

export class CurrentUser {
    created: Date;
    id: string;
    role: string;
    ttl: string;
    user?: ICoupleInformation;
    constructor(data?: any) {
        if (data) {
            this.created = data.created;
            this.id = data.id;
            this.role = data.role;
            this.ttl = this.ttl;
            this.user = data.user;
        }
    }
}

export class Session {
    created: Date;
    token: string;
    ttl: string;
    user: ICoupleInformation;
}

export interface Couple {
    url: string;
    email: string;
    email2?: string;
    phoneNumber1: string;
    phoneNumber2?: string;
    date?: Date;
    datecreatedAt: Date;
    fakeid: string;
    id: string;
    nameP1: string;
    nameP2?: string;
    isPaid: boolean;
    isActive: boolean;
    isPremium: boolean;
    profile?: string;
    amount?: number;
    theme?: string;
}

// export class WeddingData {
//     nameP1: string;
//     sanitizedNameP1: string;
//     lastnameP1: string;
//     sanitizedLastnameP1: string;
//     nameP2: string;
//     sanitizedNameP2: string;
//     lastnameP2: string;
//     sanitizedLastnameP2: string;
//     date: Date;
//     dateText: string;
//     requireConfirmation: boolean;
//     welcomeMessage: string;
//     coverPhoto: string;
//     profilePhoto?: string;
//     placeName?: string;
//     address?: string;
//     gpsaddress?: any;
//     city?: string;
//     isSetted: boolean;
//     id?: string;
//     titleMessage?: string;
//     titleHistory?: string;
//     messageHistory?: string;
//     detailHistory?: string;
// }

// export class CoupleInformation {
//   url: string;
//   currentUrl?: string;
//   experience: boolean;
//   email2?: string;
//   phoneNumber1: string;
//   phoneNumber2?: string;
//   hasFirstProduct: boolean;
//   createEmailReceived: boolean;
//   paymentEmailReceived: boolean;
//   requireOnboarding: boolean;
//   tempAccount: boolean;
//   isDisabled: boolean;
//   isSendAccountBalance: boolean;
//   rsvpDisabled: boolean;
//   trackQuantity: boolean;
//   limitQuantity: boolean;
//   is2FA: boolean;
//   prefijo: string;
//   isAnswerQuestions: boolean;
//   onboarding?: number;
//   isActive: boolean;
//   email: string;
//   id: string;
//   createdAt: Date;
//   updatedAt: Date;
//   fakeid: string;
//   token2FA: string;
//   totalGift?: number;
//   totalGiftReceived?: number;
//   domine: string;
//   weddingData: WeddingData;
//   payInformationData?: any;
//   cashOutInformationList: any[];
//   registryData: any;
//   giftList: any[];
//   productsRegistryList: any[];
//   totalCashout?: number;
//   totalReceived?: number;
//   onboardingStep?: number;
//   stateId?: string;
//   ticketsStatus: string;
//   ticketsCant: number;
//   shippingAddress: any;
//   viewGifts?: boolean
//   viewHelp?: boolean
//   viewHistory?: boolean
//   viewHotel?: boolean
//   viewInstagram?: boolean
//   viewTime?: boolean
//   constructor() {
//       this.weddingData = new WeddingData()
//   }

// }

export class Payer {
    country?: string;
    fullname?: string;
    email?: string;
    phone?: string;
    city?: string;
}

export class Products {
    name: string;
    description: string;
    sku: string;
    unit_price: number;
    price?: number;
    quantity: number;
    id?: string;
    image?: string
}

export class Button {
    label: string;
    matIcon: boolean;
    _class: string;
    onClick: any;
    url: any;
    parent?: any
    constructor(label: string, method: any, _class: string, url?: any, parent?: any, matIcon?: boolean) {
        this.label = label;
        this.onClick = method;
        this.matIcon = matIcon || false;
        this._class = _class;
        this.url = url;
        this.parent = parent;
    }
}

export interface IProductRegister {
    categoriesIds: Array<string>;
    createByAccount: boolean;
    description: string;
    image: string;
    imagesList: Array<string>,
    name: string;
    price: number;
    qty: number;
    storeId: string;
    isActive: boolean;
    id?: string;
    altImg?: string;
    check?: boolean;
    createdAt?: Date;
    hidden?: boolean;
    isMercadolibre?: boolean;
    updatedAt?: Date;
    previewOrder?: number;
}

export class ImageSnippet {
    constructor(public src: string, public file: File) { }
}

export class address {

}
