<div class="content-buttons" *ngIf="!isGuest">
  <button (click)="updateView()" class="btn btn-uniko-sm" [class.uniko-primary]="couple.viewHistory"
    [class.uniko-off]="!couple.viewHistory" [disabled]="quickLock">
    <i class="material-icons white">
      visibility
    </i>
  </button>
  <button (click)="edit()" class="btn btn-uniko-sm uniko-secondary">
    <i class="material-icons">
      edit
    </i>
  </button>
</div>
<div class="row py-4 justify-content-center w-100" [class.d-none]="isGuest && !couple.viewHistory"
  [class.section-uniko-off]="!couple.viewHistory">
  <div class="col-12 col-md-6 my-4">
    <p class="m-0 p-0 mb-2 t-primary title-section">{{couple.weddingData.detailHistory }}</p>
    <h1 class="mb-5 text-title">{{ couple.weddingData.titleHistory }}</h1>
    <p class="description-history">{{ couple.weddingData.messageHistory }}</p>
  </div>
</div>


<app-modal [isOpen]="isOpen" (onAction)="update()" (onClosed)="isOpen=false;">
  <form [formGroup]="dataForm">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="label-control">Detalle</label>
              <input [class.submit]="submitted" formControlName="detail" type="text" maxlength="200"
                class="form-control" placeholder="Detalle">
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label class="label-control">Título</label>
              <input [class.submit]="submitted" formControlName="title" type="text" maxlength="60" class="form-control"
                placeholder="Título">
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="label-control">Descripción</label>
          <textarea class="form-control" formControlName="messageHistory"></textarea>
        </div>
      </div>
    </div>
  </form>
</app-modal>