<app-header menuKey="SEARCH" [public]="true"></app-header>
<div class="container-fluid">
  <div class="row">
    <div class="col-12 search-header bg-uniko-light pt-4">
      <div class="container">
        <div class="row">
          <div class="hidden-mobile col-sm-2 p-0 m-0"></div>
          <div class="col-12 col-sm-8">
            <h2>Encuentra una mesa de regalos</h2>
            <div><img class="img-responsive search-gift"
                src="https://s3-us-west-2.amazonaws.com/uniko-prod/assets/regalo.png"></div>
            <h5>¡Es momento de buscar el regalo perfecto!</h5>
          </div>
          <div class="hidden-mobile col-sm-2 p-0 m-0"></div>
        </div>
        <div class="row">
          <div class="hidden-mobile col-sm-2 p-0 m-0"></div>
          <div class="col-12 p-0">
            <form [formGroup]="searchForm" class='flex search-form flex-wrap w-100'>
              <div class="row w-100 mx-0">
                <div class="col-12 col-md-10 px-0">
                  <div class="row mx-0">
                    <div class="form-group col-12 col-sm-6 text-left pl-md-0">
                      <label class="label-control">Nombre</label>
                      <input name='name' formControlName="name" type="name" class="form-control" placeholder="">
                    </div>
                    <div class="form-group col-12 col-sm-3 text-left pl-md-0">
                      <label class="label-control">Fecha</label>
                      <input class="form-control" formControlName="date" [owlDateTime]="dt3" [owlDateTimeTrigger]="dt3"
                        placeholder="Date Time">
                      <owl-date-time [pickerType]="'calendar'" #dt3></owl-date-time>
                    </div>
                    <div class="form-group col-12 col-sm-3 text-left pl-md-0">
                      <label class="label-control">Tipo</label>
                      <select formControlName="type" class="form-control" value="" name="type" id="">
                        <option value="" selected>Todos</option>
                        <option [value]="'WEDDING'">Boda</option>
                        <option [value]="'BABY'">Babyshower</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md align-items-center d-flex justify-content-end">
                  <button class='btn btn-uniko mt-2 w-100' (click)="newQuery()">Buscar</button>
                </div>
              </div>
            </form>
          </div>
          <div class="hidden-mobile col-sm-2 p-0 m-0"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-3 pb-3">
    <div class="col-12">
      <div class="container">
        <div class='row'>
          <div class="col-12 table-wrapper" *ngIf="results.length">
            <div class="row event" *ngFor="let event of results">
              <div class="col-12 col-sm-6 col-md-5 event-image p-0">
                <img class="img-responsive" [src]="getImage(event)">
              </div>
              <div class="col-12 col-sm-6 col-md-7 event-info p-0">
                <div class='row m-0'>
                  <div class="col event-data text-center mt-1">
                    <span>
                      <h4>{{ getName(event) }}</h4>
                    </span>
                    <span>{{eventFormatDate(event.weddingData.date)}}</span>
                    <span
                      style="font-size: 10px; display: block;">{{ event.weddingData.type === 'BABY' ? 'Baby Shower' : 'Boda' }}</span>
                  </div>
                  <div class="col-12 col-md-5 event-controls text-center">
                    <button class='btn btn-uniko' (click)="goToEvent(event.url)">Visita su mesa</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 table-wrapper" *ngIf="!results.length">
            <div class="row text-center">
              <div class="col-12 mt-4">
                <h1>Oops!</h1>
                <h5>No hay resultados para tu consulta!</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>