<div class="modal-site-fade" [class.open]="isOpen">

</div>

<div class="modal-site" [class.open]="isOpen">
  <div class="content">
    <div class="buttons">
      <!-- <button (click)="onClose()" class="btn float-left mobile-size">
                Cancelar
            </button> -->
      <button (click)="onAccept()" class="btn float-right mobile-size">
        x
      </button>
    </div>
    <div class="form-data">
      <ng-content></ng-content>
    </div>
  </div>
</div>