import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { LayoutService } from 'src/app/services/layout.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from 'src/app/services/account.service';
import { CatalogService } from 'src/app/services/catalog.service';
import { NotificationService } from 'src/app/services/notification.service';


@Component({
  selector: 'app-site-gift',
  templateUrl: './site-gift.component.html',
  styleUrls: ['./site-gift.component.scss']
})
export class SiteGiftComponent implements OnInit {
  @Input() index: number;
  @Input() art: any = {};
  @Input() store: string;
  @Input() guest: boolean = false;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private layoutService: LayoutService,
    private spinner: NgxSpinnerService,
    private coupleService: AccountService,
    private catalogService: CatalogService,
    private notification: NotificationService
  ) {
    
  }

  ngOnInit() {
  }
  acomodoArt(idArt: string) {
    let idArticulo = document.getElementById(idArt);
    idArticulo.className = "cardArticulo card m-2 mb-4 ArtSelec";
  }
  acomodoArt2(idArt: string) {
    let idArticulo = document.getElementById(idArt);
    idArticulo.className = "cardArticulo card m-2 mb-4";
  }
  editarArt() {
    this.router.navigate(['/gift-table/edit', this.art.id]);
  }

  add(product) {
    product.quantity = 1;
    console.log(product)
    this.layoutService.cart.add(product);

  }


  async commitRemoval(idArt: string): Promise<void> {
    try {
      this.spinner.show();
      const couple = this.layoutService.getCoupleInformation();
      let _couple = await this.coupleService.get(couple.id);
      _couple.productsRegistryList = couple.productsRegistryList.filter(function (obj) {
        return obj.id != idArt;
      });
      const update = await this.coupleService.setProductsList(_couple.id, _couple.experience, _couple.productsRegistryList);
      this.layoutService.setCoupleInformation(_couple);
      this.catalogService.catalogChanges();
    } catch (e) {
      this.notification.error(e);
    } finally {
      this.notification.success("Articulo eliminado");
      this.spinner.hide();
      this.router.navigate(['/gift-table']);
    }
  }

  borrarArt(idArt: string) {
    this.layoutService.emitItemDeletion(idArt);
    /*//alert("Eliminar artículo: " + idArt);
    const dialogRef = this.dialog.open(DialogAlertaBorrar, { data: idArt });
 
   dialogRef.afterClosed().subscribe(result => {
     if(result==true){             
        this.commitRemoval(idArt);
        console.log("Guardar");
     }
     else{
       console.log("Cancelar");
     }
   });*/
  }

}
