import { Component, OnInit, Input, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from 'src/app/services/layout.service';
import { AccountService } from 'src/app/services/account.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CatalogService } from 'src/app/services/catalog.service';
import { ICoupleInformation } from 'src/app/_interface/coupleInformation';
import { URL_RESOURCES } from 'src/environments/environment';

@Component({
  selector: 'app-gift',
  templateUrl: './gift.component.html',
  styleUrls: ['./gift.component.scss']
})
export class GiftComponent implements OnInit {
  @Input() lock: boolean;
  @Input() index: number;
  @Input() art: any = {};
  @Input() store: string = '';
  @Input() category: string = '';
  @Input() isVisible: boolean;
  @Input() couple: ICoupleInformation;
  @Input() isGuest: boolean;
  @Input() isCatalog: boolean;
  @Input() isPaidPlan: boolean = false;
  publicAssets = URL_RESOURCES.ASSETS;
  constructor(
    private router: Router,
    private layoutService: LayoutService,
    private coupleService: AccountService,
    private catalogService: CatalogService,
    private spinner: NgxSpinnerService,
    private notification: ToastrService
  ) { }

  ngOnInit() {
  }

  get currency() {
    return this.layoutService.settings.info.currency;
  }

  get formatPrice() {
    const price = this.art ? this.art.price : 0;
    return price / this.layoutService.settings.info.currencyChange;
  }

  getImage(){
    const image = this.art.image?this.art.image:`${this.publicAssets}/regalo.png`;
    return image;
  }

  acomodoArt(idArt: string) {
    let idArticulo = document.getElementById(idArt);
    idArticulo.className = "cardArticulo card m-2 mb-4 ArtSelec";
  }
  acomodoArt2(idArt: string) {
    let idArticulo = document.getElementById(idArt);
    idArticulo.className = "cardArticulo card m-2 mb-4";
  }

  borrarArt(idArt: string) {
    this.layoutService.emitItemDeletion(idArt);
  }

  async commitRemoval(idArt: string): Promise<void> {
    try {
      this.spinner.show();
      const couple = this.layoutService.getCoupleInformation();
      let _couple = await this.coupleService.get(couple.id);
      _couple.productsRegistryList = couple.productsRegistryList.filter(function (obj) {
        return obj.id != idArt;
      });
      const update = await this.coupleService.setProductsList(_couple.id, _couple.experience, _couple.productsRegistryList);
      this.layoutService.setCoupleInformation(_couple);
      this.catalogService.catalogChanges();
    } catch (e) {
      this.notification.error(<string>e);
    } finally {
      this.spinner.hide();
    }
  }

  editarArt(id: any) {
    this.router.navigate(['/gift-table/edit', id]);
  }

  add(product: { id: any; quantity: any; }) {
    if(this.isPaidPlan){
      product.quantity = 1;
      this.layoutService.cart.add(product);
    }
  }

  goTo(id: string) {
    if (this.isGuest) {
      this.router.navigate([this.couple.url, "gift-table", id]);
    } else if (this.isCatalog) {
      this.router.navigate(["catalog", id]);
    } else {
      this.router.navigate(["gift-table", id]);
    }

  }

  async addItem(itemId: string) {
    try {
      this.spinner.show();
      const quantity = 1;
      let gift = this.art;
      const couple = this.layoutService.getCoupleInformation();
      let _couple = await this.coupleService.get(couple.id);
      let prevItem = _couple.productsRegistryList.find(item => item.id == gift.id);
      let max = 0;
      if (_couple.productsRegistryList && _couple.productsRegistryList.length > 0) {
        const lastitem = _couple.productsRegistryList.reduce(function (prev, current) {
          return (prev.previewOrder > current.previewOrder) ? prev : current
        });
        max = lastitem.previewOrder;
      }

      gift.previewOrder = max + 1;

      if (prevItem) {
        prevItem.qty += quantity
      } else {
        gift.qty = quantity;
        _couple.productsRegistryList.push(gift);
      }

      await this.coupleService.setProductsList(_couple.id, _couple.experience, _couple.productsRegistryList);
      this.layoutService.setCoupleInformation(_couple);
      let user = this.layoutService.getCurrentUser();
      user.user = _couple;
      this.layoutService.setCurrentUser(user);
      this.notification.success('Articulo agregado');
      this.catalogService.catalogChanges();
    } catch (e) {
      this.notification.error(<string>e);
      console.log(e);
    } finally {
      this.spinner.hide();
    }
  }

}
