<div class="sombraCuadros mb-5 bg-white rounded col-12" style="padding:0px;" *ngFor="let faq of faqs">
  <div class="card-header-buttons" style="width:100% !important; margin:0px;">
    <div class="btn-group" role="group">
      <i class="fas fa-trash-alt borrarArt" (click)="openRemove(faq)"></i>
      <i class="fas fa-pen editarArt" (click)="openEdit(faq)"></i>
    </div>
  </div>
  <div class="card-body p-3">
    <h5 class="colorUniko">
      ¿{{ faq.question }}?
    </h5>
  </div>
</div>
<div class="col-12 text-center" *ngIf="!faqs || !faqs.length">
  <h4 class="color-grey mt-4">Aun no te han preguntado nada</h4>
</div>

<app-modal [isOpen]="isOpen" (onClosed)="closeModal()" (onAction)="save()" textButton="Publicar">
  <input type="file" id="image-upload-q-pending" (change)="uploadPending($event)" style="display: none;">
  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="dataForm">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-6">
            <div class="form-group text-left">
              <label class="label-control">Escribe tu pregunta</label>
              <textarea class="form-control h-mobile" rows="10" formControlName="question">

                                  </textarea>
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-12">
            <div class="form-group text-left">
              <label class="label-control">Escribe tu respuesta</label>
              <textarea class="form-control h-mobile" rows="10" formControlName="answer">

                                  </textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12">
            <label class="label-control">Agrega una foto</label>
            <div class="gift-image-container mr-4 mt-4">
              <i class="fas fa-plus iconoAgregar" (click)="onUpload($event, 'image-upload-q-pending')" *ngIf="!file"></i>
              <i class="fas fa-times iconoBorrar" (click)="removePhoto()" *ngIf="file"></i>
              <div class="input-image-container">
                <div class="w-100 h-100 image-wrap" *ngIf="selectedFile || currentFaq.image" [innerHTML]="getInputImage(selectedFile, file, currentFaq.image)"></div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</app-modal>
<app-modal [isOpen]="isOpenDelete" (onClosed)="closeModal()" (onAction)="removeQuestion()" [isButtonShow]="false">
  <div class="row" *ngIf="currentFaq">
    <div class="col-12 pr-0 mb-4">
      <h4 class="mb-3">¿Borrar definitivamente esta pregunta?</h4>
      <h5 class="align-middle colorUniko mb-2">
        {{currentFaq.question}}
      </h5>
      <p class="card-text" *ngIf="currentFaq.answer"> - {{currentFaq.answer}}</p>
    </div>
    <div class="col-12 p-20 text-center" style="padding: 10px;">
      <button class="btn float-left" (click)="closeModal()">
        No, Cancelar
      </button>
      <button class="btn text-danger float-right" (click)="removeQuestion()">
        Sí, eliminarla
      </button>
    </div>
</div>
</app-modal>