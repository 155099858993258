<div class="containerPrincipal container mt-3 mb-4" *ngIf="loaded">
  <div class="row mb-5">
    <div class="col-12">
      <div class="btn-group btn-group-toggle float-right" data-toggle="buttons" *ngIf="!isGuest">
        <button mat-button (click)="openDialog()" class="btn editarArt">
          <i class="fas fa-pen mr-2"></i>
          Editar
        </button>
        <button class="btn borrarArt" (click)="isOpenDelete = true;">
          <i class="fas fa-trash-alt mr-2"></i>
          Eliminar
        </button>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="row align-items-center">
        <img [src]="gift.image" class="cardgift-img-top card-img-top rounded-0" alt="..." *ngIf="gift.image">
        <img [src]="publicAssets+'/regalo.png'" class="cardgift-img-top card-img-top rounded-0" alt="..."
          *ngIf="!gift.image">
      </div>
      <div class="row align-items-center">
        <img *ngFor="let imagenArt of gift.imagesList" [src]="imagenArt" (click)="obtenURL(imagenArt)"
          class="card-img-top mt-3 mr-3 rounded-0" style="width:75px; cursor:pointer;" alt="...">
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="card border-0">
        <div class="card-body p-0 notranslate">
          <p class="titulogift align-bottom">
            {{ gift.name }}
          </p>
          <p class="align-middle" style="margin-bottom:10px !important;">
            <small class="font-weight-bold colorUniko">{{ getStoreName(gift.storeId) }}</small>
          </p>
          <p class="cardgift-Precio">
            <small class="preciogift font-weight-bold">{{ formatPrice(gift.price) | currency:currency }}</small>
          </p>
          <p class="align-middle" style="margin-bottom: 0px !important;">
            <small class="font-weight-bold">Descripción</small>
          </p>
          <p class="mb-3">
            {{ gift.description }}
          </p>
          <p class="align-middle" style="margin-bottom: 0px !important;">
            <small class="font-weight-bold">Categoría</small>
          </p>
          <div class="mb-3">
            <p class="mb-0" *ngFor="let categoria of gift.categoriesIds">
              {{ getCatName(categoria) }}
            </p>
          </div>
          <div class="text-right w-100">
            <button mat-button (click)="addCart(gift)" class="btn btn-uniko addArt" *ngIf="isGuest">
              <div class="row mx-0 justify-content-center">
                <div class=" col-12 col-md-auto p-0 text-center">
                  <div class="iconCointainer position-relative d-inline">
                    <i class="fas fa-shopping-bag editarArt tb-secondary"></i>
                    <i class="fas fa-plus editarArtMas tb-secondary"></i>
                  </div>
                </div>
                <div class="ml-2 col-12 col-md-auto d-flex align-items-center justify-content-center">Agregar al carrito</div>
              </div>
            </button>
          </div>
        </div>
        <!--<div>
                  <p class="align-middle" style="margin-bottom: 0px !important;">
                      <small class="font-weight-bold">Cantidad</small>
                  </p>
                  <div class="mb-3">
                      <p>
                          {{gift.qty}}
                      </p>                        
                  </div>
              </div>-->
      </div>
    </div>
  </div>
</div>
<app-modal (onClosed)="isOpen=false;" (onAction)="guardarRegalo()" textButton="Guardar" [isOpen]="isOpen" *ngIf="item">
  <div class="edit-container">
    <div class="col-12">
      <div class="row">
        <div class="col-12 pr-0">
          <div class="row overflow-auto m-0">
            <div class="col-12 col-lg-5 p-0 flex images-container flex-wrap">
              <div class="row w-100 m-0">
                <div *ngFor="let image of item.imagesList; let i = index;" id="{{ i }}"
                  class="gift-image-container col-6">
                  <i class="fas fa-times iconoBorrar" (click)="quitarFoto(i,'old')"></i>
                  <div class='gift-image'>
                    <img [src]="image" class="cardgift-img-top card-img-top rounded-0 img-responsive" alt="...">
                  </div>
                </div>
                <div *ngFor="let image of images; let i = index;" id="{{ i }}" class="gift-image-container col-6">
                  <i class="fas fa-times iconoBorrar" (click)="quitarFoto(i,'new')"></i>
                  <div class='gift-image'>
                    <img [src]="image" class="cardgift-img-top card-img-top rounded-0 img-responsive" alt="...">
                  </div>
                </div>
                <div class="gift-image-container col-6" *ngIf='countImages()'>
                  <i class="fas fa-plus iconoAgregar" (click)="agregarFoto($event)"></i>
                  <input #newGiftImage id='new-gift-image' type="file" hidden (change)='fileProgress($event)'>
                  <div class='gift-image'>
                    <img src="{{publicAssets}}/uniko-empty-image.jpg" style="border:1px dashed #333333;">
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-7 pr-0 mt-2">
              <div class="card border-0" style="height:475px; background-color:transparent !important;">
                <div class="card-body p-0">
                  <form [formGroup]="itemForm">
                    <mat-form-field class="w-100">
                      <input matInput placeholder="Nombre de Producto" name='name' formControlName='name'
                        [required]="true">
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <input matInput type="number" placeholder="Precio" name='price' formControlName='price'
                        [required]="true">
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <textarea matInput placeholder="Descripción" name='description' formControlName='description'
                        style="height: 110px;" [required]="true"></textarea>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label>Tienda</mat-label>
                      <select matNativeControl formControlName='storeId' name='storeId' [required]="true">
                        <option></option>
                        <option *ngFor="let store of stores" [ngValue]='store.id'>{{store.name}}
                        </option>
                      </select>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label>Categoría</mat-label>
                      <mat-select formControlName='category' [multiple]="true" name="category" [required]="true">
                        <mat-option *ngFor="let category of categories" [value]="category.id">
                          {{category.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <!--<mat-form-field class="w-100">
                                          <mat-label>Cantidad</mat-label>
                                          <select matNativeControl name='qty' formControlName='qty'>
                                              <option [ngValue]="1">1</option>
                                              <option [ngValue]="2">2</option>
                                              <option [ngValue]="3">3</option>
                                              <option [ngValue]="4">4</option>
                                              <option [ngValue]="5">5</option>
                                              <option [ngValue]="6">6</option>
                                              <option [ngValue]="7">7</option>
                                              <option [ngValue]="8">8</option>
                                              <option [ngValue]="9">9</option>
                                              </select>
                                      </mat-form-field>-->
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-modal>
<app-modal (onClosed)="isOpenDelete=false;" [isButtonShow]="false" [isOpen]="isOpenDelete">
  <div class="containerPrincipal dialog-delete">
    <div class="row">
      <div class="col-12 pr-0">
        <h1 class="title2 mb-5 colorUniko" style="font-size:1.4rem;">¿Están seguros que desean eliminar este
          regalo?</h1>
        <p class="align-middle" style="margin-bottom: 0px !important;">
          <small class="font-weight-bold">Antes de continuar</small>
        </p>
        <p class="mb-3">
          Este regalo no aparecerá más en tu mesa, si cambias de parecer podrás accesar a “Agregar Regalos”
          volver a agregarlo
        </p>
      </div>
      <div class="col-12 p-20 text-center" style="padding: 10px;">
        <button mat-button (click)="isOpenDelete=false;" class="btn float-left">
          No, Cancelar
        </button>
        <button mat-button (click)="commitRemoval(gift.id)" class="btn text-danger float-right">
          Sí, eliminarlo
        </button>
      </div>
    </div>
  </div>
</app-modal>