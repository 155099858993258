import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss']
})
export class NoticeComponent implements OnInit {
  @Input() menuKey: any;
  @Input() plan: any;
  @Input() menus: any;
  section: any;
  constructor() { }

  ngOnInit() {

  }

  havePlanActive() {
    if (this.plan) {
      const section = this.plan.planAggre.find((item: { listAggre: { keyMenu: any; }; }) => item.listAggre.keyMenu == this.menuKey);
      this.section = section;
      return section ? section.isActive : false;
    }
    return false;
  }

  haveMenu() {
    if (this.menus) {
      const menu = this.menus.find((item: { _key: any; }) => item._key == this.menuKey);
      return menu;
    }
    return false;
  }

  showNotice() {
    return (this.haveMenu() && !this.havePlanActive());
  }

  getPlanSectionName() {
    if (this.plan) {
      return this.plan.planAggre.find((item: { listAggre: { keyMenu: any; }; }) => item.listAggre.keyMenu == this.menuKey);
    }
  }

}
