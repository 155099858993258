import { APP_INITIALIZER, ErrorHandler, NgModule, LOCALE_ID } from "@angular/core";
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppRoutes } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from 'src/app/_guards/auth.guard';
import { ErrorInterceptor } from 'src/app/_helpers/error.interceptor';
import { JwtInterceptor } from 'src/app/_helpers/jwt.interceptor';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { DragulaModule } from 'ng2-dragula';
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from './material/material.module';
import { SearchComponent } from './search/search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReferralsComponent } from './referrals/referrals.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { PlansComponent } from './plans/plans.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import localeMx from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeMx);
import * as LogRocket from 'logrocket';

LogRocket.init('vtifet/uniko');

@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    ReferralsComponent,
    PlansComponent,
    ConfirmationComponent
  ],
  imports: [
    NgxSliderModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    DragulaModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    BrowserModule,
    RouterModule.forRoot(AppRoutes, { useHash: false, scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', }),
  ],
  providers: [
    AuthGuard,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es-MX' },
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'es-MX'},
  ],
  exports: [
    OwlDateTimeModule,
    OwlNativeDateTimeModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
