<div class="content-buttons" *ngIf="!isGuest">
  <button (click)="updateView()" class="btn btn-uniko-sm" [class.uniko-primary]="couple.viewGifts"
    [class.uniko-off]="!couple.viewGifts" [disabled]="quickLock">
    <i class="material-icons white">
      visibility
    </i>
  </button>
</div>
<div class="row py-4" [class.d-none]="isGuest && !couple.viewGifts" [class.section-uniko-off]="!couple.viewGifts">
  <div class="col-12 my-4">
    <div class="row justify-content-center m-0">
      <div class="col-12 col-md-6 col-lg-6 p-0">
        <p class="m-0 p-0 mb-2 t-primary title-section">ÉCHALE UN OJO A
          {{getEventCouple(couple.weddingData)?'NUESTRA':'LA'}} MESA DE REGALOS</p>
        <h1 class="mb-5 text-title t-base">Estos son {{getEventCouple(couple.weddingData)?'nuestros':'mis'}}
          regalos preferidos</h1>
        <div class="row m-0 p-0 shadow-sm tb-light t-rounded">
          <div class="col-12 col-md-6 mx-0 p-3">
            <p class="t-secondary m-0 p-0 number">{{diffDate.days}}</p>
            <p class="m-0 p-0 text">{{'Días para cerrar nuestra mesa' | uppercase}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mx-0 mt-3" *ngIf="gifts.length">
      <div class="col-12 col-xl-8 m-0 p-0">
        <div class="row justify-content-between" style="overflow-x:auto; flex-wrap: nowrap;">
          <app-site-gift *ngFor="let art of gifts | slice:0:10; let i = index;" [art]="art" [index]="i"
            [store]="getNameStore(art.storeId)" [guest]="isGuest">
          </app-site-gift>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mx-0 mt-3">
      <div class="col-6 p-0">
        <p class="m-0 p-0 mb-4" style="font-size:16px; font-weight:600; text-align:center;">
          <a *ngIf="!isGuest" routerLink="/gift-table" class="m-0 p-0 text-danger" style="text-decoration: underline;"
            href="">Ver toda la mesa de regalos</a>
          <a *ngIf="isGuest" routerLink="/{{couple.url}}/gift-table" class="m-0 p-0 text-danger"
            style="text-decoration: underline;" href="">Ver toda la mesa de regalos</a>
        </p>
      </div>
    </div>
  </div>
</div>

<app-modal [isOpen]="isOpen" (onClosed)="onClose()" (onAction)="saveEmail()">
  <div class="col-md-12">
    <form [formGroup]="dataForm">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
          <div class="form-group text-left">
            <label class="label-control">Email</label>
            <input formControlName="email" type="text" class="form-control" placeholder="Email">
          </div>
        </div>
      </div>
    </form>
  </div>
</app-modal>

<app-modal (onClosed)="isOpenDelete=false;" [isButtonShow]="false" [isOpen]="isOpenDelete">
  <div class="containerPrincipal dialog-delete">
    <div class="row">
      <div class="col-12 pr-0">
        <h1 class="title2 mb-5 colorUniko" style="font-size:1.4rem;">¿Están seguros que desean eliminar este
          regalo?</h1>
        <p class="align-middle" style="margin-bottom: 0px !important;">
          <small class="font-weight-bold">Antes de continuar</small>
        </p>
        <p class="mb-3">
          Este regalo no aparecerá más en tu mesa, si cambias de parecer podrás accesar a “Agregar Regalos”
          volver a agregarlo
        </p>
      </div>
      <div class="col-12 p-20 text-center" style="padding: 10px;">
        <button mat-button (click)="isOpenDelete=false;" class="btn float-left">
          No, Cancelar
        </button>
        <button mat-button (click)="commitRemoval()" class="btn text-danger float-right">
          Sí, eliminarlo
        </button>
      </div>
    </div>
  </div>
</app-modal>