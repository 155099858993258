import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-cover',
  templateUrl: './cover.component.html',
  styleUrls: ['./cover.component.scss']
})
export class CoverComponent implements OnInit {
  @Input() content: any;
  @Input() templateId: any;
  @Input() cover: any;
  @Input() proxy: boolean = false;
  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {

  }

  getProxy() {
    if (this.proxy) {
      const bypassed = this.sanitizer.bypassSecurityTrustUrl(this.cover);

      let fragment = this.cover.split('couple');
      return 'https://assets.uniko.co/couple' + fragment[1];
    } else {
      return this.cover;
    }
  }

}
