<div class="content-buttons" *ngIf="!isGuest">
  <button (click)="updateView({'viewHotel':1})" class="btn btn-uniko-sm" [class.uniko-primary]="couple.viewHotel"
    [class.uniko-off]="!couple.viewHotel" [disabled]="quickLock">
    <i class="material-icons white">
      visibility
    </i>
  </button>
  <button (click)="add()" class="btn btn-uniko-sm uniko-secondary">
    <i class="material-icons">
      edit
    </i>
  </button>
</div>
<div class="row py-4 justify-content-center hoteles w-100" [class.d-none]="isGuest && !couple.viewHotel"
  [class.section-uniko-off]="!couple.viewHotel">
  <div class="col-12 col-md-8 my-4">
    <p class="m-0 p-0 mb-2 t-primary title-section">HOTELES</p>
    <h1 class="mb-5 text-title">Opciones de Hospedaje</h1>
    <div class="row rounded m-0 p-0 diffused-content">
      <div [class.hidden-mobile]="showMap" class="col-12 col-md-4 col-lg-4 m-0 p-0"
        style="height:460px; overflow:auto;padding-top: 1em !important;">
        <div class="sn-content" *ngIf="!hotelsList.length">
          <img src="{{publicAssets}}/hotel.svg" alt="">
          <label for="" class="title-100">
            Aun no han registrado hoteles cercanos
          </label>
          <label for="" class="title-500">
            Compartan con sus invitados los cupones de los hoteles con los que tiene convenio
          </label>
          <button (click)="add()" *ngIf="!isGuest" class="btn tb-secondary t-light w-100" type="button">
            Agregar hoteles
          </button>
        </div>
        <div *ngFor="let _hotel of hotelsList">
          <div class="list-group hotel-section" *ngIf="!_hotel.plan.length">
            <div class="buttons-actions" *ngIf="!isGuest">
              <button (click)="confirmDelHotel(_hotel)" class="btn tb-secondary t-light">
                <i class="material-icons white" style="vertical-align: middle;">
                  delete_sweep
                </i>
              </button>
            </div>
            <div class="list-group-item2 pb-3">
              <p class="t-primary m-0 p-0" style="font-size:20px;">{{ _hotel.name }}</p>
              <p class="m-0 my-2 p-0" style="font-size:16px;">{{ _hotel.phone }}</p>
              <p class="m-0 mt-2 p-0 pr-3" style="font-size:14px;">
                {{
                (_hotel.gpsaddress ? _hotel.gpsaddress.formatted_address : _hotel.address)
                }}
              </p>
              <a href="{{ _hotel.gpsaddress.url }}" target="_blank" class="float-right"
                style="text-align:right; width:100%; font-size:14px;" *ngIf="_hotel.gpsaddress">Ver en Google Maps</a>
            </div>
          </div>
          <div class="list-group hotel-section" *ngFor="let _plan of _hotel.plan">
            <div class="buttons-actions" *ngIf="!isGuest">
              <button (click)="confirmDelHotel(_hotel, _plan.id)" class="btn tb-secondary t-light">
                <i class="material-icons white" style="vertical-align: middle;">
                  delete_sweep
                </i>
              </button>
            </div>
            <div class="list-group-item2 pb-3">
              <p class="txtGris m-0 p-0" style="font-size:12px; font-weight:600;">1 HABITACIÓN - {{ _plan.bed }} Cama
              </p>
              <p class="t-primary m-0 p-0" style="font-size:20px;">{{ _hotel.name }}</p>
              <p class="m-0 p-0" style="font-size:16px; font-weight:600;" *ngIf="_plan.price && _plan.price > 0">
                {{ _plan.price | currency:'MXN' }} por noche</p>
              <p class="m-0 my-2 p-0" style="font-size:16px;">{{ _hotel.phone }}</p>
              <p class="m-0 p-0" style="font-size:12px; font-weight:600;" *ngIf="_plan.cupon">Cupón</p>
              <p class="m-0 p-0" style="font-size:16px;" *ngIf="_plan.cupon">{{ _plan.cupon }}</p>
              <p class="m-0 mt-2 p-0 pr-3" style="font-size:14px;">
                {{
                (_hotel.gpsaddress ? _hotel.gpsaddress.formatted_address : _hotel.address)
                }}
              </p>
              <a href="{{ _hotel.gpsaddress.url }}" target="_blank" class="float-right"
                style="text-align:right; width:100%; font-size:14px;" *ngIf="_hotel.gpsaddress">Ver en Google Maps</a>
            </div>
          </div>

        </div>
        <div class="button-event" *ngIf="hotelsList.length && !isGuest">
          <button (click)=" add()" class="btn tb-secondary w-100 t-light" type="button">
            Agregar hoteles
          </button>
        </div>
      </div>
      <div [class.hidden-mobile]="!showMap" class="col-12 col-md-8 col-lg-8 m-0 p-0"
        style="height:460px; overflow:auto;">
        <google-map height="460px" width="100%" [options]="mapOptions" [zoom]="zoom">
          <map-marker *ngIf="eventHaveAddress" [position]="eventMarkerPosition" [options]="eventMarkerOptions"
            [icon]="markerIcon"></map-marker>
          <map-marker *ngFor="let _hoteleGps of hoteleGps" [position]="getHotelLocation(_hoteleGps?.gpsaddress)"
            [options]="eventMarkerOptions" [label]="{text: _hoteleGps?.name}">
          </map-marker>
        </google-map>
      </div>
    </div>
    <div class="col-12 view-mobile">
      <button class="btn" (click)="showMap = !showMap">
        <i class="material-icons">keyboard_arrow_up</i>
        <span *ngIf="!showMap">
          Mostrar mapa
        </span>
        <span *ngIf="showMap">
          Ocultar mapa
        </span>
      </button>
    </div>
  </div>
</div>

<app-modal [isOpen]="isOpenInfo" (onClosed)="onClosedInfo()" [isButtonShow]="false" [small]="true">
  <div class="info">
    <div class="info-title t-primary">
      ¿Desea eliminar el hotel?
    </div>
    <div class="subtitle">
      {{ hotel?.name }}
    </div>
  </div>
  <div class="buttonst text-center">
    <button class="btn tb-secondary t-light" (click)="removeHotel()">
      Sí, eliminar
    </button>
  </div>
</app-modal>

<app-modal [isOpen]="isOpen" (onClosed)="onClosed()" (onAction)="update()">
  <form [formGroup]="dataForm">
    <div class="row" style="position: relative">
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="label-control">
                Lugar o dirección
              </label>
              <input ngx-google-places-autocomplete [class.submit]="submitted" formControlName="address" type="text"
                (keydown.enter)="$event.preventDefault()" autocorrect="off" autocapitalize="off" spellcheck="off"
                class="form-control" placeholder="Dirección" (onAddressChange)="handleAddressChange($event)" #search />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="label-control">
                Hotel
              </label>
              <input [class.submit]="submitted" formControlName="name" type="text" maxlength="200" class="form-control"
                placeholder="">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="label-control">
                Teléfono
              </label>
              <input [class.submit]="submitted" formControlName="phone" type="text" maxlength="30" class="form-control"
                placeholder="">
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6" *ngIf="!plan.length">
        <div class="row text-right">
          <div class="col-12 col-md-12">
            <div (click)="addPlan()" class="add-button font">
              + Agregar Plan
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6" formArrayName="plan">
        <div *ngFor="let item of plan.controls; let planIndex=index" [formGroupName]="planIndex">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="label-control">Plan</label>
                <input [class.submit]="submitted" formControlName="name" type="text" maxlength="60" class="form-control"
                  placeholder="">
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="label-control"></label>
                <select name="" formControlName="bed" id="" class="form-control">
                  <option value="1">1 cama</option>
                  <option value="2">2 camas</option>
                  <option value="3">3 camas</option>
                  <option value="4">4 camas</option>
                  <option value="5">5 camas</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12">
              <div class="form-group">
                <label class="label-control">Precio por noche</label>
                <input [class.submit]="submitted" formControlName="price" type="text" maxlength="60"
                  class="form-control" placeholder="">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12">
              <div class="form-group">
                <label class="label-control">Cupón</label>
                <input [class.submit]="submitted" formControlName="cupon" type="text" maxlength="60"
                  class="form-control" placeholder="">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 text-right">
              <a href="" (click)="delPlan($event, planIndex)">Eliminar</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12" *ngIf="plan.length">
        <div class="row text-right">
          <div class="col-12 col-md-6">
            <div (click)="addPlan()" class="add-button font">
              + Agregar Plan
            </div>
          </div>
        </div>
      </div>
    </div> <!-- end row -->
  </form>
</app-modal>
