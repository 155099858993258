import { ICoupleInformation } from './../_interface/coupleInformation';
export class CoupleInformation {
  protected _coupleInformation: ICoupleInformation | undefined;
  constructor() {
    if (localStorage.getItem('coupleInformation')) {
      this.getLocal();
    }
  }


  get info() {
    return this._coupleInformation;
  }

  set info(info: ICoupleInformation | undefined) {
    this._coupleInformation = info;
    this.setLocal();
  }

  get totals() {
    const giftTotal = this.info?.giftList.reduce((total, data) => {
      return total + data.gift.price;
    }, 0);
    const cashoutTotal = this.info?.cashOutInformationList.reduce((total, data) => {
      return total + (data.isPaid ? parseFloat(data.approvedAmount) : 0);
    }, 0);
    const total = giftTotal - cashoutTotal;
    return {
      giftTotal,
      cashoutTotal,
      total
    }
  }

  decrypt(data: string): any {
    try {
      return JSON.parse(unescape(atob(atob(atob(data)))));
    } catch (e) {
      try {
        return atob(atob(atob(unescape(data))));
      } catch (e) {

      }
    }
  }

  encrypt(data: any): string {
    try {
      return btoa(btoa(btoa(escape(JSON.stringify(data)))));
    } catch (e) {
      try {
        return btoa(btoa(btoa(escape(data))));
      } catch (err) {
        return '';
      }
    }
  }

  getLocal() {
    this._coupleInformation = this.decrypt(localStorage.getItem('coupleInformation') || '');
  }

  setLocal() {
    localStorage.setItem('coupleInformation', this.encrypt(this._coupleInformation));
  }

}