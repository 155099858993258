import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_RESOURCES } from 'src/environments/environment';
import { IPaymentInfo, IOrder } from '../_interface/order';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    private http: HttpClient
  ) { }

  async createOrder(
    paymentInfo: IPaymentInfo
  ): Promise<IOrder> {
    return this.http.post<IOrder>(
      `${URL_RESOURCES.API_UNIKO}/guests/createOrder`, {
      paymentInfo: paymentInfo
    }
    ).toPromise()
  }

  async updateOrder(
    orderId: string,
    paymentInfo: IPaymentInfo
  ): Promise<IOrder> {
    return this.http.post<IOrder>(
      `${URL_RESOURCES.API_UNIKO}/guests/updateOrder`, {
      orderId,
      paymentInfo: paymentInfo
    }
    ).toPromise()
  }


  async calculateComission(
    calculate: any
  ): Promise<IOrder> {
    return this.http.post<IOrder>(
      `${URL_RESOURCES.API_UNIKO}/cards/calculateComission`,
      calculate
    ).toPromise()
  }

  async makeCardPayment(
    orderId: string,
    paymentInfo: IPaymentInfo
  ): Promise<IOrder> {
    return this.http.post<IOrder>(
      `${URL_RESOURCES.API_UNIKO}/guests/makeCardPayment`, {
      orderId,
      paymentInfo: paymentInfo
    }
    ).toPromise()
  }

  async makeOxxoPayment(
    orderId: string,
    paymentInfo: IPaymentInfo
  ): Promise<IOrder> {
    return this.http.post<IOrder>(
      `${URL_RESOURCES.API_UNIKO}/guests/makeOxxoPayment`, {
      orderId,
      paymentInfo: paymentInfo
    }
    ).toPromise()
  }

  async makeSpeiPayment(
    orderId: string,
    paymentInfo: IPaymentInfo
  ): Promise<IOrder> {
    return this.http.post<IOrder>(
      `${URL_RESOURCES.API_UNIKO}/guests/makeSpeiPayment`, {
      orderId,
      paymentInfo: paymentInfo
    }
    ).toPromise()
  }

  async makePaypalPayment(
    orderId: string,
    paymentInfo: IPaymentInfo
  ): Promise<IOrder> {
    return this.http.post<IOrder>(
      `${URL_RESOURCES.API_UNIKO}/guests/makePaypalPayment`, {
      orderId,
      paymentInfo: paymentInfo
    }
    ).toPromise()
  }

  async makePaypalPlusPayment(
    orderId: string,
    paymentInfo: IPaymentInfo
  ): Promise<IOrder> {
    return this.http.post<IOrder>(
      `${URL_RESOURCES.API_UNIKO}/guests/makePaypalPlusPayment`, {
      orderId,
      paymentInfo: paymentInfo
    }
    ).toPromise()
  }


  async makeRappiPayment(
    orderId: string,
    paymentInfo: IPaymentInfo
  ): Promise<IOrder> {
    return this.http.post<IOrder>(
      `${URL_RESOURCES.API_UNIKO}/guests/makeRappi`, {
      orderId,
      paymentInfo: paymentInfo
    }
    ).toPromise()
  }

  async confirmRappiPayment(
    orderId: string,
    rappiId: string
  ): Promise<IOrder> {
    return this.http.post<IOrder>(
      `${URL_RESOURCES.API_UNIKO}/guests/makeRappiPay`, {
      orderId,
      rappiId,
    }
    ).toPromise()
  }

  async confirmPaypalPayment(
    orderId: string,
    paymentId: string,
    payerID: string,
    token: string,
  ): Promise<IOrder> {
    return this.http.post<IOrder>(
      `${URL_RESOURCES.API_UNIKO}/guests/confirmPaypalPayment`, {
      orderId,
      paymentId,
      payerID,
      token
    }
    ).toPromise()
  }

  async makeCardPaymentPlan(
    coupleId: string,
    paymentInfo: any
  ): Promise<IOrder> {
    return this.http.post<IOrder>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${coupleId}/makeCardPaymentPlan`, {
      paymentInfo: paymentInfo
    }
    ).toPromise()
  }

  async makeCardPaymentAccount(
    coupleId: string,
    paymentInfo: any
  ): Promise<any> {
    return this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${coupleId}/_makeCardPayment`, {
      paymentInfo: paymentInfo
    }
    ).toPromise();
  }

  async makePaypalPaymentAccount(
    coupleId: string,
    paymentInfo: any
  ): Promise<any> {
    return this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${coupleId}/makePaypalPayment`, {
      paymentInfo: paymentInfo
    }
    ).toPromise();
  }

  async confirmPaypalPaymentAccount(
    coupleId: string,
    paymentId: string,
    token: string,
    payerID: string,
  ): Promise<any> {
    return this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/coupleaccounts/${coupleId}/confirmPaypalPayment`, {
      paymentId,
      payerID,
      token
    }
    ).toPromise();
  }

  async applyCode(
    coupleId: string,
    code: any
  ): Promise<any> {
    return this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/discountCodes/applyCode`, {
      coupleId,
      code
    }
    ).toPromise();
  }

  async recoveryCart(
    id: string
  ): Promise<any> {
    return this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/orders/cart`,
      {},
      {
        params: {
          id: id
        }
      }
    ).toPromise()
  }

  async getCode(
    coupleId: string,
  ): Promise<any> {
    return this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/discountCodes/_redemtion`, {
      coupleId
    }
    ).toPromise();
  }

  async reminder(email, coupleId) {
    return this.http.post<any>(
      `${URL_RESOURCES.API_UNIKO}/guests/remindMe`, {
      email,
      coupleId
    }
    ).toPromise();
  }
}
