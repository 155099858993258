import { Component, OnInit, Inject, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { LayoutService } from './../../services/layout.service';
import { AccountService } from 'src/app/services/account.service';
import { DragulaService } from 'ng2-dragula';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification.service';
import { CatalogService } from 'src/app/services/catalog.service';
import { ICoupleInformation } from 'src/app/_interface/coupleInformation';
import { Router } from '@angular/router';
import { IProductRegister } from 'src/app/_models/data';
import { Options } from '@angular-slider/ngx-slider';
import { URL_RESOURCES } from 'src/environments/environment';

@Component({
  selector: 'app-gift-list',
  templateUrl: './gift-list.component.html',
  styleUrls: ['./gift-list.component.scss']
})
export class GiftListComponent implements OnInit {

  @Input() isGuest: boolean;
  @Input() isCatalog: boolean;
  @Input() couple: ICoupleInformation;
  @Input() isPaidPlan: boolean = false;
  currency: string;
  usefullInformation: any;
  minValue: number = 0;
  maxValue: number = 30000;
  categories: any[] = [];
  stores: any[] = [];
  storesSelected: any[] = [];
  categoriesSelected: any[] = [];
  filterPriceOpen = false;
  filterCategoriesOpen = false;
  filterStoresOpen = false;
  publicAssets = URL_RESOURCES.ASSETS;
  isOpenFilterContainer: boolean = false;
  isOpenFilter: boolean = false;
  isOpenDelete: boolean = false;
  currentOrder: number = 3;
  lockDrag = false;
  dragula: any;
  giftList: any[] = [];
  ready: boolean = false;
  currencies = {
    "USD": 0,
    "CAD": 0,
    "EUR": 0,
    "MXN": 1
  };
  currentItemForDeletion: string;
  loadedCurrency: boolean = false;
  sliderOptions: Options = {
    step: 100,
    floor: 0,
    ceil: 30000,
    showTicks: false,
  };

  constructor(
    private layoutService: LayoutService,
    private notification: NotificationService,
    private coupleService: AccountService,
    private dragulaService: DragulaService,
    private catalogService: CatalogService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  ngOnInit() {
    this.currency = this.layoutService.settings.info.currency;
    if (!this.isGuest && !this.isCatalog) {
      this.dragula = this.dragulaService.dropModel("ITEMSCTLG").subscribe(async args => {
        this.sortFixer(args.targetModel);
      });
      this.dragulaService.createGroup("ITEMSCTLG", {
        moves: function (el, container, handle) {
          const element = <HTMLInputElement>document.getElementById('domCurrentOrder');
          let order = element.value || null;
          return (handle.classList.contains('handle') && order == '3');
        },
      });
      this.layoutService.coupleEmitter.subscribe(data => {
        this.couple = this.layoutService.getCoupleInformation();
        this.setPreviewOrder(this.currentOrder);
      });
      this.catalogService.giftEmitter.subscribe((_data: any) => {
        this.couple = this.layoutService.getCoupleInformation();
        this.catalogs();
      });
      this.layoutService.itemDeletionEmitter.subscribe(itemId => {
        this.currentItemForDeletion = itemId;
        this.isOpenDelete = true;
      });
    }
    this.getInfo();
  }

  async getInfo(): Promise<void> {
    try {
      this.spinner.show();
      await this.catalogs();
      this.maxValue = Math.ceil(this.getMaxPrice(this.giftList) / 100) * 100;
      this.minValue = Math.ceil(this.getMinPrice(this.giftList));
      this.sliderSetLimits(this.maxValue, this.minValue);
    } catch (e) {
      console.log(e);
    } finally {
      this.spinner.hide();
    }

  }

  async sortFixer(model: any[]) {
    try {
      this.lockDrag = true;
      for (let i = 0; i < model.length; i++) {
        model[i].previewOrder = i + 1;
      }
      this.giftList = model;
      await this.commitChanges();
    } catch (e) {
      this.notification.error(e);
    } finally {
      this.lockDrag = false;
    }
  }

  async arrayComposter(model: any[], index?: any) {
    this.giftList = model;
  }

  ngOnDestroy() {
    try {
      if (!this.isGuest && !this.isCatalog) {
        this.dragula.unsubscribe();
        this.dragulaService.destroy('ITEMSCTLG');
      }
    } catch (e) {
      console.log(e);
    }
  }

  sliderSetLimits(newCeil: number, newFloor: number): void {
    const newOptions: Options = Object.assign({}, this.sliderOptions);
    newOptions.ceil = newCeil;
    newOptions.floor = newFloor;
    this.sliderOptions = newOptions;
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any; }; }) {
    const width = event.target.innerWidth;
    if (width < 768) {
      this.forceColapse();
    }
  }

  setPreviewOrder(type: number) {
    let itemsSorted = <IProductRegister[]>JSON.parse(JSON.stringify(this.giftList));
    try {
      switch (type) {
        case 1:
          itemsSorted.sort(function (obj1, obj2) {
            // Ascending: price
            return obj1.price - obj2.price;
          });
          break;
        case 2:
          itemsSorted.sort(function (obj1, obj2) {
            // Descending: price
            return obj2.price - obj1.price;
          });
          break;
        case 3:
          itemsSorted.sort(function (obj1, obj2) {
            // Original: index ( previewOrder )
            return obj1.previewOrder - obj2.previewOrder;
          });
          break;
        //array.sort((a,b) => a.title.rendered.localeCompare(b.title.rendered));
      }
    }
    catch (e) {
      console.log(e)
    }
    finally {
      this.currentOrder = type;
      this.giftList = itemsSorted;
    }
  }

  async commitChanges() {
    const update = await this.coupleService.setProductsList(this.couple.id, this.couple.experience, this.giftList);
    this.layoutService.setCoupleInformation(update);
    let user = this.layoutService.getCurrentUser();
    user.user = this.couple;
    this.layoutService.setCurrentUser(user);
    this.catalogService.catalogChanges();
  }

  async saveItemOrder(item: IProductRegister) {
    let info = await this.coupleService.updateProductTable(item, this.layoutService.getUser().id);
  }

  async getGeneralCatalog() {
    const categories = this.categories.map((info) => (info.id));
    this.giftList = await this.coupleService.getArticlesByCatalogs(0, 30000, categories);
  }

  async catalogs(): Promise<void> {
    try {
      if (this.isGuest) {
        this.categories = await this.coupleService.getCategoriesForClient(this.couple.id);
      } else {
        this.categories = await this.coupleService.getCategories();
      }
      this.stores = await this.coupleService.getStores();
      if (this.isCatalog) {
        this.getGeneralCatalog();
      } else {
        this.giftList = this.couple.productsRegistryList;
      }
      this.ready = true;
    } catch (e) {
      console.log(e);
    } finally {

    }
  }

  setStore(store: any) {
    const exist = this.storesSelected.find(_store => {
      return _store.id === store.id
    });
    if (!exist) {
      this.storesSelected.push(store);
    }
  }

  setCategory(category: any) {
    const exist = this.categoriesSelected.find(_category => {
      return !!(_category.id === category.id)
    });
    if (!exist) {
      this.categoriesSelected.push(category);
    }
  }

  dynamicFilter(itemId: any) {
    const price = this.dynamicPrice(itemId);
    const store = this.dynamicStores(itemId);
    const category = this.dynamicCategories(itemId);
    return price && store && category;
  }

  dynamicPrice(itemId: any) {
    const item = this.giftList.find(product => product.id == itemId);
    const price = item?.price >= this.minValue && item.price <= this.maxValue;
    return price;
  }

  dynamicStores(itemId: any) {
    const item = this.giftList.find(product => product.id == itemId);
    let allStores = true;
    if (this.storesSelected.length) {
      allStores = this.storesSelected.find(store => store.id === item.storeId || store.name === 'Otras');
    }
    return allStores;
  }

  dynamicCategories(itemId: any) {
    const item = this.giftList.find(product => product.id == itemId);
    const selectedCategoriesId = this.categoriesSelected.map(category => { return category.id });
    let allCoincidences = false;

    if (selectedCategoriesId.length > 0) {
      selectedCategoriesId.forEach(cat => {
        let coincidence = item.categoriesIds.includes(cat);
        if (coincidence) {
          allCoincidences = true;
        }
      });
    } else {
      allCoincidences = true;
    }

    return allCoincidences;
  }


  getMaxPrice(articles: any[]) {
    if (articles && articles.length) {
      const maxValue = Math.max.apply(Math, articles.map(function (o: { price: any; }) { return o.price; }));
      const expensiver = articles.filter(function (o: { price: number; }) { return o.price === maxValue; })[0];
      return expensiver ? expensiver.price : this.maxValue;
    } else {
      return this.maxValue;
    }
  }

  getMinPrice(articles: any[]) {
    if (articles && articles.length) {
      const minValue = Math.min.apply(Math, articles.map(function (o: { price: any; }) { return o.price; }));
      const cheaper = articles.filter(function (o: { price: number; }) { return o.price === minValue; })[0];
      return cheaper ? cheaper.price : this.minValue;
    } else {
      return this.minValue;
    }
  }

  queryArticles() {
    this.giftList.forEach(element => {
      element.visible = true;
    });
    this.setPreviewOrder(this.currentOrder);
  }

  removeStore(store: any) {
    let index = 0;
    this.storesSelected.forEach((_store, _index) => {
      if (store.id === _store.id) {
        index = _index;
      }
    })
    this.storesSelected.splice(index, 1);
  }

  removeCategory(category: any) {
    let index = 0;
    this.categoriesSelected.forEach((_category, _index) => {
      if (category.id === _category.id) {
        index = _index;
      }
    })
    this.categoriesSelected.splice(index, 1);
  }

  getNameStore(id: string): string {
    const store = this.stores.find(_store => _store.id == id);
    let name = store ? store.name : '';
    return name;
  }

  getStore(stores: any): string {

    return '';
  }

  getNameCategory(categories: any[]): string {
    let categoriesName: any[] = [];
    this.categories.forEach(category => {
      const _category = categories.find(cat => {
        return cat === category.id;
      });
      if (_category) {
        categoriesName.push(category.name);
      }
    });
    return categoriesName.join(', ');
  }

  colapsa(id: string) {
    let elemento = document.getElementById(id);
    let toggleDat = elemento.getAttribute('data-toggle');
    if (toggleDat == "up") {
      elemento.setAttribute("data-toggle", "down");
      elemento.style.display = "none";
    }
    else if (toggleDat == "down") {
      elemento.setAttribute("data-toggle", "up");
      elemento.style.display = "";
    }

  }

  forceColapse() {
    this.filterPriceOpen = false;
    this.filterCategoriesOpen = false;
    this.filterStoresOpen = false;
  }

  colapsa2(id: string, hiddenId: string) {
    let elemento = document.getElementById(id);
    let toggleDat = elemento.getAttribute('data-toggle');

    let elementoHidden = document.getElementById(hiddenId);
    let toggleDatHidden = elemento.getAttribute('data-toggle');
    if (toggleDat == "up") {
      elemento.setAttribute("data-toggle", "down");
      elemento.style.display = "none";
    }
    else if (toggleDat == "down") {
      elemento.setAttribute("data-toggle", "up");
      elemento.style.display = "";
      elementoHidden.setAttribute("data-toggle", "down");
      elementoHidden.style.display = "none";
    }

  }
  acomodoArt(idArt: string) {
    let idArticulo = document.getElementById(idArt);
    idArticulo.className = "cardArticulo card m-2 mb-4 ArtSelec";
  }
  acomodoArt2(idArt: string) {
    let idArticulo = document.getElementById(idArt);
    idArticulo.className = "cardArticulo card m-2 mb-4";
  }
  borrarArt(idArt: number): void {
    this.giftList.slice(idArt, 1);
    this.commitChanges();
  }
  /*editarArt(idArticulo:number){
    this.router.navigate(['/gift-table/edit/' + idArticulo]);
  }*/
  ordenPor(tipo: number) {
    alert(tipo);
  }

  async setCurrency(currency: string) {
    try {
      if (!this.loadedCurrency) {
        const response = await this.coupleService.formatedExchangeRate();
        this.currencies.CAD = response.find(data => { return data.code == 'CAD' }).value;
        this.currencies.USD = response.find(data => { return data.code == 'USD' }).value;
        this.currencies.EUR = response.find(data => { return data.code == 'EUR' }).value;
        this.loadedCurrency = true;
      }
      this.layoutService.currency = currency;
      this.layoutService.currencyChange = this.currencies[currency];
      this.currency = currency;
      this.layoutService.settings.currency = this.currency;
    } catch (e) {

    }
  }

  iniMesa() {
    this.router.navigate(['/gift-table/add/']);
  }

  async commitRemoval(): Promise<void> {
    try {
      if(!this.currentItemForDeletion){
        this.isOpenDelete = false;
      }
      const idArt = this.currentItemForDeletion;
      this.spinner.show();
      const couple = this.layoutService.getCoupleInformation();
      let _couple = await this.coupleService.get(couple.id);
      _couple.productsRegistryList = couple.productsRegistryList.filter(function (obj) {
        return obj.id != idArt;
      });
      await this.coupleService.setProductsList(_couple.id, _couple.experience, _couple.productsRegistryList);
      this.layoutService.setCoupleInformation(_couple);
      this.giftList = _couple.productsRegistryList;
      this.catalogService.catalogChanges();
      this.notification.success("Regalo eliminado");
      this.currentItemForDeletion = null;
      this.isOpenDelete = false;
    } catch (e) {
      this.notification.error(e);
    } finally {
      this.spinner.hide();
      this.router.navigate(['/gift-table']);
    }
  }
}
