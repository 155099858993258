import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  @Input() content: any;
  @Input() templateId: any;
  @Input() loadComplete: boolean = false;
  constructor() { }

  ngOnInit() {

  }


  resetPositions() {

  }

  getStyle(type) {
    const content = this.content[type];
    if (content) {
      let newob = {
        'text-align': content.textAlign,
        'color': content.color,
        'font-family': content.fontFamily,
        'font-weight': content.fontWeight,
        'font-size': content.fontSize,
        'text-transform': content.textTransform,
        'font-style': content.fontStyle,
        'letter-spacing': content.latterSpace
      }
      return newob;
    }
    return {}
  }

  get setTextShadow() {
    const selection = this.content.colors.find(data => data.select);
    let color = selection ? selection.color : 'transparent'
    let shadow = `-1px -1px 0 ${color},  1px -1px 0 ${color}, -1px 1px 0 ${color}, 1px 1px 0 ${color};`;
    return shadow;
  }
}
