import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { SiteComponent } from './site/site.component';
import { GiftListComponent } from './gift-list/gift-list.component';
import { NavigationMenuComponent } from './navigation-menu/navigation-menu.component';
import { ActionMenuComponent } from './action-menu/action-menu.component';
import { NoticeComponent } from './notice/notice.component';
import { ModalComponent } from './modal/modal.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GiftComponent } from './gift/gift.component';
import { DragulaModule } from 'ng2-dragula';
import { MaterialModule } from 'src/app/material/material.module';
import { InfoComponent } from './site/info/info.component';
import { GalleryComponent } from './site/gallery/gallery.component';
import { GiftTableComponent } from './site/gift-table/gift-table.component';
import { QuestionsComponent } from './site/questions/questions.component';
import { HistoryComponent } from './site/history/history.component';
import { HotelsComponent } from './site/hotels/hotels.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { ImageCropperModule } from 'ngx-image-cropper';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { SiteGiftComponent } from './site/site-gift/site-gift.component';
import { SharedQuestionsComponent } from './shared-questions/shared-questions.component';
import { SharedGuestbookComponent } from './shared-guestbook/shared-guestbook.component';
import { PublicComponent } from './shared-questions/public/public.component';
import { PendingComponent } from './shared-questions/pending/pending.component';
import { GiftDetailComponent } from './gift-detail/gift-detail.component';
import { FooterComponent } from './footer/footer.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ArrangmentItemComponent } from './arrangment-item/arrangment-item.component';
import { DynamicStepsComponent } from './dynamic-steps/dynamic-steps.component';
import { FillPipe } from 'src/app/_helpers/fill.pipe';
import { ModalCartComponent } from './modal-cart/modal-cart.component';
import { ContentComponent } from './save-date/content/content.component';
import { CoverComponent } from './save-date/cover/cover.component';
import { EditorComponent } from './save-date/editor/editor.component';
import { ListComponent } from './save-date/list/list.component';
import { ToolsComponent } from './save-date/tools/tools.component';
import { ViewerComponent } from './save-date/viewer/viewer.component';
import { ColorSketchModule } from 'ngx-color/sketch';
import { TemplatesComponent } from './save-date/templates/templates.component';
import { TermsComponent } from './terms/terms.component';
import { SharingComponent } from './sharing/sharing.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { DatePickerComponent } from './date-picker/date-picker.component'

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  imports: [
    ColorSketchModule,
    NgxSliderModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ImageCropperModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    DragulaModule.forRoot(),
    MaterialModule,
    GoogleMapsModule,
    GooglePlaceModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  declarations: [
    FillPipe,
    HeaderComponent,
    SiteComponent,
    GiftListComponent,
    NavigationMenuComponent,
    ActionMenuComponent,
    NoticeComponent,
    ModalComponent,
    GiftComponent,
    InfoComponent,
    GalleryComponent,
    GiftTableComponent,
    QuestionsComponent,
    HistoryComponent,
    HotelsComponent,
    SiteGiftComponent,
    SharedQuestionsComponent,
    SharedGuestbookComponent,
    PublicComponent,
    PendingComponent,
    GiftDetailComponent,
    FooterComponent,
    EditorComponent,
    ViewerComponent,
    ArrangmentItemComponent,
    DynamicStepsComponent,
    ModalCartComponent,
    ContentComponent,
    CoverComponent,
    EditorComponent,
    ListComponent,
    ToolsComponent,
    ViewerComponent,
    TemplatesComponent,
    TermsComponent,
    SharingComponent,
    PrivacyComponent,
    TimePickerComponent,
    DatePickerComponent
  ],
  exports: [
    NgxSliderModule,
    ReactiveFormsModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ImageCropperModule,
    GoogleMapsModule,
    GooglePlaceModule,
    MaterialModule,
    HeaderComponent,
    SiteComponent,
    GiftListComponent,
    ListComponent,
    NavigationMenuComponent,
    ActionMenuComponent,
    NoticeComponent,
    ModalComponent,
    GiftComponent,
    GiftDetailComponent,
    SharedGuestbookComponent,
    SharedQuestionsComponent,
    FooterComponent,
    ArrangmentItemComponent,
    DynamicStepsComponent,
    EditorComponent,
    ViewerComponent,
    FillPipe,
    ModalCartComponent,
    SharingComponent,
    TimePickerComponent,
    NgxMaskModule
  ]
})
export class SharedModule { }

