import { IAuthorization } from '../_interface/authorization';

export class Authorization {
  protected _authorization: IAuthorization;

  constructor() {
    if (localStorage.getItem('auth')) {
      this._authorization = this.decrypt(localStorage.getItem('auth') || '');
    }
  }

  get info() {
    return this._authorization;
  }

  set info(auth: IAuthorization) {
    this._authorization = auth;
    this.setLocal();
  }

  verify(key: string) {
    const accept = this._authorization.planAggre.find(data => data.isActive && data.listAggre.keyMenu === key);
    return accept ? true : false;
  }

  setLocal() {
    localStorage.setItem('auth', this.encrypt(this._authorization));
  }

  decrypt(data: string): any {
    try {
      return JSON.parse(unescape(atob(atob(atob(data)))));
    } catch (e) {
      try {
        return atob(atob(atob(unescape(data))));
      } catch (e) {

      }
    }
  }

  encrypt(data: any): string {
    try {
      return btoa(btoa(btoa(escape(JSON.stringify(data)))));
    } catch (e) {
      try {
        return btoa(btoa(btoa(escape(data))));
      } catch (err) {
        return '';
      }
    }
  }

  clear() {
    localStorage.removeItem('auth');
  }
}