import { IPlan } from '../_interface/plan';

export class Plan {
  protected _plan: IPlan;
  constructor() {
    if (localStorage.getItem('uniko.plan')) {
      this.getLocal();
    }
  }

  get info() {
    return this._plan;
  }

  set info(plan: IPlan) {
    this._plan = plan;
    this.setLocal();
  }

  decrypt(data: string): any {
    try {
      return JSON.parse(unescape(atob(atob(atob(data)))));
    } catch (e) {
      try {
        return atob(atob(atob(unescape(data))));
      } catch (e) {
        return false;
      }
    }
  }

  encrypt(data: any): string {
    try {
      return btoa(btoa(btoa(escape(JSON.stringify(data)))));
    } catch (e) {
      try {
        return btoa(btoa(btoa(escape(data))));
      } catch (err) {
        return '';
      }
    }
  }

  getLocal() {
    this._plan = this.decrypt(localStorage.getItem('uniko.plan') || '');
  }

  setLocal() {
    localStorage.setItem('uniko.plan', this.encrypt(this._plan));
  }
}