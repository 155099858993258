export const TEMPLATES = {
    1: {
      name: 'uniko-',
      opacity: '1',
      select: 'title',
      desktop: true,
      classicMode: true,
      title: {
        color: '#000',
        fontFamily: `'Montserrat', sans-serif`,
        fontWeight: '200',
        textAlign: 'center',
        fontSize: '16pt',
        textTransform: 'uppercase',
        fontStyle: 'normal',
        text: 'título del evento',
        latterSpace: '10px',
      },
      nametitle: {
        color: '#fc7667',
        fontFamily: `'Montserrat', sans-serif`,
        fontWeight: '700',
        textAlign: 'center',
        fontSize: '38pt',
        textTransform: 'Capitalize',
        fontStyle: 'normal',
        text: 'Nombre & Nombre',
        latterSpace: '1px',
      },
      titleDate: {
        color: '#000',
        fontFamily: `'Montserrat', sans-serif`,
        fontWeight: '200',
        textAlign: 'center',
        fontSize: '12pt',
        textTransform: 'uppercase',
        fontStyle: 'normal',
        text: 'Aparta la fecha',
        latterSpace: '5px',
      },
      date: {
        color: '#000',
        fontFamily: `'Montserrat', sans-serif`,
        fontWeight: '700',
        textAlign: 'center',
        fontSize: '28pt',
        textTransform: 'Capitalize',
        fontStyle: 'normal',
        text: '18. Octubre . 2019',
        latterSpace: '1px',
      },
      titleDescription: {
        color: '#fc7667',
        fontFamily: `'Montserrat', sans-serif`,
        fontWeight: '700',
        textAlign: 'center',
        fontSize: '12pt',
        textTransform: 'uppercase',
        fontStyle: 'normal',
        text: 'Lugar',
        latterSpace: '1px',
      },
      description: {
        color: '#000',
        fontFamily: `'Montserrat', sans-serif`,
        fontWeight: '200',
        textAlign: 'center',
        fontSize: '11pt',
        textTransform: 'uppercase',
        fontStyle: 'normal',
        text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
        Ut enim ad minim veniam, quis nostrud exercitation ullamco`,
        latterSpace: '1px',
      },
      backgroundTemplate: [
        {"index":1,"color":"#fc7667", select: true},
        {"index":2,"color":"#000000", select: false},
      ],
      colors: [
        {"index":1,"color":"#fc7667", select: true},
        {"index":2,"color":"#000000", select: false},
      ]
    },
    2: {
      select: 'title',
      opacity: '1',
      desktop: true,
      classicMode: true,
      title: {
        color: '#000',
        fontFamily: `'Damion', cursive`,
        fontWeight: '200',
        textAlign: 'center',
        fontSize: '50pt',
        textTransform: 'Capitalize',
        fontStyle: 'normal',
        text: 'título del evento',
        latterSpace: '1px',
      },
      nametitle: {
        color: '#c5a939',
        fontFamily: `'Cardo', serif`,
        fontWeight: '700',
        textAlign: 'center',
        fontSize: '36pt',
        textTransform: 'uppercase',
        fontStyle: 'normal',
        text: 'Nombre & Nombre',
        latterSpace: '1px',
      },
      titleDate: {
        color: '#000',
        fontFamily: `'Cardo', serif`,
        fontWeight: '100',
        textAlign: 'center',
        fontSize: '13pt',
        textTransform: 'uppercase',
        fontStyle: 'normal',
        text: 'Aparta la fecha',
        latterSpace: '1px',
      },
      date: {
        color: '#000',
        fontFamily: `'Damion', cursive`,
        fontWeight: '200',
        textAlign: 'center',
        fontSize: '28pt',
        textTransform: 'Capitalize',
        fontStyle: 'normal',
        text: '18. Octubre . 2019',
        latterSpace: '1px',
      },
      titleDescription: {
        color: '#c5a939',
        fontFamily: `'Cardo', serif`,
        fontWeight: '400',
        textAlign: 'center',
        fontSize: '13pt',
        textTransform: 'uppercase',
        fontStyle: 'normal',
        text: 'Lugar',
        latterSpace: '1px',
      },
      description: {
        color: '#000',
        fontFamily: `'Cardo', serif`,
        fontWeight: '400',
        textAlign: 'center',
        fontSize: '12pt',
        textTransform: 'Capitalize',
        fontStyle: 'normal',
        text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
        Ut enim ad minim veniam, quis nostrud exercitation ullamco`,
        latterSpace: '1px',
      },
      name: 'template-dots-',
      backgroundTemplate: [
        {"index":1,"color":"#fff", select: false},
        {"index":2,"color":"#dab4c9", select: false},
        {"index":3,"color":"#ffebce", select: true},
        {"index":4,"color":"#4b4b4b", select: false},
        {"index":5,"color":"#fff", select: false},
      ],
      colors: [
        {"index":1,"color":"#fff", select: true},
        {"index":2,"color":"#FFF7EE", select: false},
        {"index":3,"color":"#FEF4FA", select: false},
        {"index":4,"color":"#F6F6F6", select: false},
        {"index":5,"color":"#fff", select: false},        
      ]
    }, 
    3: {
      name: 'template-flowers-',
      select: 'title',
      desktop: true,
      classicMode: true,
      opacity: '0.4',
      title: {
        color: '#f9e9cd',
        fontFamily: `'Cardo', serif`,
        fontWeight: '200',
        textAlign: 'center',
        fontSize: '26pt',
        textTransform: 'uppercase',
        fontStyle: 'normal',
        text: 'título del evento',
        latterSpace: '1px',
      },
      nametitle: {
        color: '#7c5033',
        fontFamily: `'Sofia', cursive`,
        fontWeight: '700',
        textAlign: 'center',
        fontSize: '38pt',
        textTransform: 'Capitalize',
        fontStyle: 'normal',
        text: 'Nombre & Nombre',
        latterSpace: '1px',
      },
      titleDate: {
        color: '#f9e9cd',
        fontFamily: `'Cardo', serif`,
        fontWeight: '200',
        textAlign: 'center',
        fontSize: '20pt',
        textTransform: 'Capitalize',
        fontStyle: 'normal',
        text: 'Aparta la fecha',
        latterSpace: '1px',
      },
      date: {
        color: '#7c5033',
        fontFamily: `'Sofia', cursive`,
        fontWeight: '200',
        textAlign: 'center',
        fontSize: '28pt',
        textTransform: 'Capitalize',
        fontStyle: 'normal',
        text: '18. Octubre . 2019',
        latterSpace: '1px',
      },
      titleDescription: {
          color: '#f9e9cd',
          fontFamily: `'Cardo', serif`,
          fontWeight: '200',
          textAlign: 'center',
          fontSize: '20pt',
          textTransform: 'Capitalize',
          fontStyle: 'normal',
          text: 'Lugar',
          latterSpace: '1px',
      },
      description: {
        color: '#7c5033',
        fontFamily: `'Cardo', serif`,
        fontWeight: '600',
        textAlign: 'center',
        fontSize: '12pt',
        textTransform: 'uppercase',
        fontStyle: 'normal',
        text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
        Ut enim ad minim veniam, quis nostrud exercitation ullamco`,
        latterSpace: '1px',
      },
      backgroundTemplate: [
        {"index":1,"color":"#FFF2E580", select: true},
        {"index":2,"color":"#D6B495", select: false},
        {"index":3,"color":"#CDA693", select: false},
        {"index":4,"color":"#dbb5c9", select: false},
        {"index":5,"color":"#ffffff", select: false},
      ],
      colors: [
        {"index":1,"color":"#FFF3E2", select: false},
        {"index":2,"color":"#DBB48D", select: false},
        {"index":3,"color":"#C7A998", select: false},
        {"index":4,"color":"#E4B8CF", select: true},
        {"index":5,"color":"#ffffff", select: false},        
      ]
    }, 
    4: {
      name: 'template-border-',
      opacity: '0.4',
      desktop: true,
      classicMode: true,
      select: 'title',
      title: {
        color: '#b1b0d5',
        fontFamily: `'Cardo', serif`,
        fontWeight: '600',
        textAlign: 'center',
        fontSize: '26pt',
        textTransform: 'Capitalize',
        fontStyle: 'normal',
        text: 'título del evento',
        latterSpace: '1px',
      },
      nametitle: {
        color: '#fff',
        fontFamily: `'Cardo', serif`,
        fontWeight: '700',
        textAlign: 'center',
        fontSize: '38pt',
        textTransform: 'Capitalize',
        fontStyle: 'normal',
        text: 'Nombre & Nombre',
        latterSpace: '1px',
      },
      titleDate: {
        color: '#b1b0d5',
        fontFamily: `'Cardo', serif`,
        fontWeight: '700',
        textAlign: 'center',
        fontSize: '19pt',
        textTransform: 'Capitalize',
        fontStyle: 'italic',
        text: 'Aparta la fecha',
        latterSpace: '1px',
      },
      date: {
        color: '#fff',
        fontFamily: `'Cardo', serif`,
        fontWeight: '600',
        textAlign: 'center',
        fontSize: '28pt',
        textTransform: 'Capitalize',
        fontStyle: 'normal',
        text: '18. Octubre . 2019',
        latterSpace: '1px',
      },
      titleDescription: {
        color: '#b1b0d5',
        fontFamily: `'Cardo', serif`,
        fontWeight: '700',
        textAlign: 'center',
        fontSize: '19pt',
        textTransform: 'Capitalize',
        fontStyle: 'italic',
        text: 'Lugar',
        latterSpace: '1px',
      },
      description: {
        color: '#fff',
        fontFamily: `'Cardo', serif`,
        fontWeight: '600',
        textAlign: 'center',
        fontSize: '12pt',
        textTransform: 'Capitalize',
        fontStyle: 'normal',
        text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
        Ut enim ad minim veniam, quis nostrud exercitation ullamco`,
        latterSpace: '1px',
      },
      backgroundTemplate: [
        {"index":1,"color":"#fff", select: false},   
        {"index":2,"color":"#b2b3d7", select: true},     
        {"index":3,"color":"#141e41", select: false},
        {"index":4,"color":"#000000", select: false},
        {"index":5,"color":"#fff", select: false},
      ],
      colors: [
        {"index":1,"color":"#fff", select: false},        
        {"index":2,"color":"#b2b3d7", select: false},
        {"index":3,"color":"#141e41", select: true},
        {"index":4,"color":"#000000", select: false},
        {"index":5,"color":"#fff", select: false},
      ]
    },
    5: {
      name: 'template-lines-',
      select: 'title',
      opacity: 1,
      desktop: true,  
      classicMode: true,
      title: {
        color: '#BA2C69',
        fontFamily: `'Norican', cursive`,
        fontWeight: '200',
        textAlign: 'center',
        fontSize: '70pt',
        textTransform: 'lowercase',
        fontStyle: 'normal',
        text: 'título del evento',
        latterSpace: '1px',
      },
      nametitle: {
        color: '#ba2c68',
        fontFamily: `'Cardo', serif`,
        fontWeight: '200',
        textAlign: 'center',
        fontSize: '38pt',
        textTransform: 'lowercase',
        fontStyle: 'normal',
        text: 'Nombre & Nombre',
        latterSpace: '1px',
      },
      titleDate: {
        color: '#342f77',
        fontFamily: `'Cardo', serif`,
        fontWeight: '200',
        textAlign: 'center',
        fontSize: '13pt',
        textTransform: 'Capitalize',
        fontStyle: 'normal',
        text: 'Aparta la fecha',
        latterSpace: '5px',
      },
      date: {
        color: '#ba2c68',
        fontFamily: `'Cardo', serif`,
        fontWeight: '200',
        textAlign: 'center',
        fontSize: '28pt',
        textTransform: 'uppercase',
        fontStyle: 'normal',
        text: '18. Octubre . 2019',
        latterSpace: '1px',
      },
      titleDescription: {
        color: '#342f77',
        fontFamily: `'Cardo', serif`,
        fontWeight: '700',
        textAlign: 'center',
        fontSize: '19pt',
        textTransform: 'Capitalize',
        fontStyle: 'normal',
        text: 'Lugar',
        latterSpace: '1px',
      },
      description: {
        color: '#342f77',
        fontFamily: `'Cardo', serif`,
        fontWeight: '600',
        textAlign: 'center',
        fontSize: '12pt',
        textTransform: 'Capitalize',
        fontStyle: 'normal',
        text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
        Ut enim ad minim veniam, quis nostrud exercitation ullamco`,
        latterSpace: '1px',
      },
      backgroundTemplate: [
        {"index":1,"color":"#ffffff", select: false},
        {"index":2,"color":"#f1f0ff", select: true},
        {"index":3,"color":"#D9276B", select: false},
        {"index":4,"color":"#332c7a", select: false},        
        {"index":5,"color":"#ffffff", select: false},
      ],
      colors: [
        {"index":1,"color":"#fff", select: true},
        {"index":2,"color":"#f1f0ff", select: false},
        {"index":3,"color":"#d9276b80", select: false},
        {"index":4,"color":"#332c7a80", select: false},
        {"index":5,"color":"#fff", select: false},
      ]
    },
    6:{
      name: 'custom-template-',
      select: 'title',
      opacity: 1,
      classicMode: false,      
      desktop: true,
    }
  }