import { Component, OnInit } from '@angular/core';
import { URL_RESOURCES } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  publicAssets = URL_RESOURCES.ASSETS
  constructor() { }

  ngOnInit(): void {
  }

}
