import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { LayoutService } from 'src/app/services/layout.service';
import * as dayjs from 'dayjs';
import { DateTime } from 'luxon';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ICoupleInformation } from 'src/app/_interface/coupleInformation';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  @Output() edit = new EventEmitter();
  @Input() couple: ICoupleInformation;
  date: any;
  hour: any;
  @Output() isOpen = false;
  @Input() isGuest: boolean = false;
  diffDate: any = {
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00'
  }
  time: any;
  quickLock: boolean = false;
  constructor(
    private coupleService: AccountService,
    private layoutService: LayoutService,
    private notification: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    dayjs.locale('es');
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.extend(LocalizedFormat)
    dayjs.extend(relativeTime)
    dayjs.tz.setDefault("America/Mexico_City");
    this.isGuest = this.layoutService.isGuest();
    this.couple = this.layoutService.getCoupleInformation();
    this.layoutService.coupleEmitter.subscribe(data => {
      this.diffDate = {
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00'
      }
      this.couple = this.layoutService.getCoupleInformation();
      this.getDate();
    });
    this.getDate();
    this.diffDateNow();
  }

  ngOnInit() {

  }

  async updateView() {
    try {
      this.spinner.show();
      this.quickLock = true;
      let consult = await this.coupleService.updateSiteView(this.couple.id, { viewTime: 1 });
      if (consult == "ok") {
        this.couple.viewTime = !this.couple.viewTime;
        this.layoutService.coupleInformation.info = this.couple;
      }
      this.quickLock = false;
    } catch (e) {
      console.log("Info:", e);
      this.notification.error(<string>e);
    } finally {
      this.spinner.hide();
    }
  }

  onEdit() {
    this.edit.emit('ok');
  }

  getDate() {
    if (this.couple.weddingData.date) {
      const alphadate = this.layoutService.formatDateForRead(this.couple.weddingData.date, this.couple.dateIssueFlag).setLocale('es-MX');
      //this.date = dayjs(this.couple.weddingData.date).format('dddd, DD MMMM YYYY');
      //this.hour = dayjs(this.couple.weddingData.date).format('h:mm A');
      this.date = alphadate.toFormat('cccc, dd MMMM yyyy');
      this.hour = alphadate.toFormat('t');
    }
  }

  diffDateNow() {
    setInterval(() => {
      if (this.couple.weddingData.date) {
        const baseDate = DateTime.fromISO(this.couple.weddingData.date).setLocale('es-MX');
        const diffArray = baseDate.diff(DateTime.now(), ["days", "hours", "minutes", "seconds"]).toObject();

        this.diffDate = {
          days: diffArray.days,
          hours: diffArray.hours,
          minutes: diffArray.minutes,
          seconds: diffArray.seconds.toFixed(0)
        }
      }
    }, 1000)
  }

  async updateLite(): Promise<void> {
    try {
      this.couple = await this.coupleService.updateRegister(this.couple);
      this.layoutService.setCoupleInformation(this.couple);
    } catch (e) {
      console.log(e);
    } finally {
    }
  }
}
