<div class="cardArticulo card m-2 mb-4" id="{{ art.id }}">
  <img [src]="art.image" class="cardArticulo-img-top card-img-top" alt="alt{{ art.id }}">
  <i class="fas fa-shopping-bag editarArt tb-secondary" (click)="add(art)" *ngIf="guest"></i>
  <i class="fas fa-plus editarArtMas tb-secondary" *ngIf="guest"></i>
  <i class="fas fa-trash borrarArt tb-secondary" (click)="borrarArt(art.id)" *ngIf="!guest"></i>
  <i class="fas fa-edit editarArt tb-secondary" (click)="editarArt()" *ngIf="!guest"></i>
  <div *ngIf="art.isMercadolibre === true" class="merLibArt"></div>
  <div class="cardArticulo-body card-body">
    <h5 class="cardArticulo-title card-title text-truncate">{{ art.name }}</h5>
    <p class="cardArticulo-text card-text">
      <small class="float-left precioArticulo font-weight-bold">{{ art.price | currency:'MXN' }}</small>
      <!-- <small class="float-left precioArticulo font-weight-bold">{{ art.description | currency:'MXN' }}</small> -->
      <small class="float-right align-middle font-weight-bold t-primary">{{ store }}</small>
    </p>
  </div>
</div>