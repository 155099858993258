<app-header menuKey="SAVE_DATE" [buttons]="buttons"></app-header>
<div class="container-fluid extra-margin">
  <app-tools [desktopMode]="templateObj.desktop" (modeEmiter)="changeMode($event)" *ngIf="!isGuest && templateObj">
  </app-tools>
  <div class="row mt-2">
    <div class="col-12 overflow-atuo">
      <div class="corner-container" *ngIf="templateObj">
        <div class="corners">
          <div class="row editor-container" [class.mobile]="!mode">
            <div class="d-flex flex-wrap w-100" id="screen">
              <div class="cover p-0 m-0" [class.col]="mode" [class.col-12]="!mode">
                <app-cover [cover]="cover" [proxy]="proxy" [content]="templateObj" [templateId]="templateId">
                </app-cover>
                <div class="controls" ignoreElements="true" data-html2canvas-ignore="true" *ngIf="!isGuest">
                  <button class="btn edit p-1" (click)="onUpload($event, 'profile')" *ngIf="templateObj.classicMode"><i
                      class="far fa-edit"></i></button>
                  <button class="btn edit p-1" (click)="onUpload($event, 'landscape')"
                    *ngIf="!templateObj.classicMode"><i class="far fa-edit"></i></button>
                </div>
              </div>
              <div class="content p-0 m-0" [class.col-7]="mode" [class.col-12]="!mode" *ngIf="templateObj.classicMode">
                <app-content [loadComplete]="loadComplete" [content]="templateObj" [templateId]="templateId">
                </app-content>
                <div class="controls" ignoreElements="true" data-html2canvas-ignore="true" *ngIf="!isGuest">
                  <button class="btn edit p-1" (click)="goToEdit()"><i class="far fa-edit"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<input type="file" id="profile" (change)="upload($event, 'profile')" style="display: none;">
<input type="file" id="landscape" (change)="uploadLandscape($event, 'landscape')" style="display: none;">

<app-modal [isOpen]="isOpenImg" [textButton]="'Guardar'" (onClosed)="isOpenImg=false;" (onAction)="uploadCoverPhoto()">
  <image-cropper *ngIf="classicMode" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
    [aspectRatio]="1 / 1" [resizeToWidth]="450" [resizeToHeight]='550' [cropperMinWidth]="450" [cropperMinHeight]="550"
    format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)"
    (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
</app-modal>
<app-modal [isOpen]="isOpenImgLand" [textButton]="'Guardar'" (onClosed)="isOpenImgLand=false;"
  (onAction)="uploadCoverPhoto()">
  <image-cropper *ngIf="mode" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
    [aspectRatio]="16 / 9" [resizeToWidth]="1024" format="png" (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
  </image-cropper>
  <image-cropper *ngIf="!mode" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
    [aspectRatio]="9 / 16" [resizeToWidth]="425" format="png" (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
  </image-cropper>
</app-modal>

<app-modal [isOpen]="isOpenShare" [small]="true" [isButtonShow]="isOpenShare" (onClosed)="onCloseShare()">
  <app-sharing></app-sharing>
</app-modal>