<div class="row m-0 justify-content-center">
  <div class="col-12 col-md-6 p-0 mt-2">
    <div class="designer row m-0 justify-content-center">
      <button class="btn txt-info" (click)="changeMode()">
        <i class="fas fa-mobile-alt mr-2" *ngIf="desktopMode"></i>
        <i class="fas fa-desktop mr-2" *ngIf="!desktopMode"></i>
        <span class="hidden-mobile">Versión {{desktopMode?'Mobile':'Escritorio'}}</span>
      </button>
    </div>
  </div>
</div>