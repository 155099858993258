<div class="content-buttons" *ngIf="!isGuest">
  <button (click)="updateView()" class="btn btn-uniko-sm" [class.uniko-primary]="couple.viewHelp"
    [class.uniko-off]="!couple.viewHelp" [disabled]="quickLock">
    <i class="material-icons white">
      visibility
    </i>
  </button>
  <button class="btn btn-uniko-sm uniko-secondary" routerLink="/questions">
    <i class="material-icons">
      edit
    </i>
  </button>
</div>
<div class="row py-4" [class.d-none]="isGuest && !couple.viewHelp" [class.section-uniko-off]="!couple.viewHelp">
  <div class="col-12 my-4">
    <div class="row justify-content-center">
      <div class="col-6 m-0 p-0">
        <p class="m-0 p-0 mb-2 t-primary" style="font-size:12px; font-weight:600; text-align: center;">ANTES DE
          QUE EMPIECE LA FIESTA</p>
        <h1 class="mb-5 text-title t-base">Algunas cosas que necesitas saber antes del
          {{isCouple(couple.weddingData)?'evento':'baby shower'}}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row justify-content-center" *ngFor="let faq of faqs">
          <div class="col-12 col-md-8 col-lg-8 mt-4">
            <div class="m-0 p-0 mb-3 bg-white t-shadow">
              <div class="card-body p-3">
                <h5 class="m-0 p-0 t-primary" (click)="faq.isOpen = (faq.isOpen ? false : true)">
                  <i *ngIf="faq.isOpen" class="fas fa-chevron-up float-right t-primary" style="padding-top:3px;"></i>
                  <i *ngIf="!faq.isOpen" class="fas fa-chevron-down float-right t-primary" style="padding-top:3px;"></i>
                  ¿{{ faq.question }}?
                </h5>
                <div class="row" *ngIf="faq.isOpen">
                  <div class="col-12" [class.col-md-12]="!existImg(faq)" [class.col-md-6]="existImg(faq)">
                    <div class="answer  my-2" [innerHTML]="faq.answer">

                    </div>
                  </div>
                  <div class="col-12 col-md-6" *ngIf="existImg(faq)">
                    <div class="img image-width" [innerHTML]="getImageToVideo(faq)">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 m-0 p-0">
          <p class="m-0 p-0 mb-4 mt-5" style="font-size:16px; font-weight:600; text-align:center;">
            <a *ngIf="!isGuest" class="m-0 p-0 text-danger" style="text-decoration: underline;" routerLink="/questions"
              href="">Ver todo
              acerca del evento</a>
            <a *ngIf="isGuest" class="m-0 p-0 text-danger" style="text-decoration: underline;"
              routerLink="/{{couple.url}}/questions" href="">Ver todo
              acerca del evento</a>
          </p>
        </div>
      </div>
    </div>
  </div>