<div class="modal-site-fade" [class.open]="isOpen">

</div>

<div class="modal-site" [class.open]="isOpen">
  <div class="content" [class.large]="large" [class.small]="small">
    <div class="buttons" [class.m-0]="closeHeader">
      <button *ngIf="isButtonShow" (click)="onClose()" class="btn float-left mobile-size">
        Cancelar
      </button>
      <button *ngIf="isButtonShow" [attr.disabled]="lockedAction" (click)="onAccept()" class="btn btn-uniko float-right mobile-size">
        {{ textButton }}
      </button>
      <button class="btn-close float-right" *ngIf="!isButtonShow" (click)="onClose()">X</button>
    </div>
    <div class="form-data" [class.p-0]="noPadding">
      <ng-content></ng-content>
    </div>
  </div>
</div>