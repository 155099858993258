import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutService } from './../../services/layout.service';
import { AccountService } from 'src/app/services/account.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification.service';
import { CatalogService } from 'src/app/services/catalog.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { URL_RESOURCES } from 'src/environments/environment';

@Component({
  selector: 'app-shared-gift-detail',
  templateUrl: './gift-detail.component.html',
  styleUrls: ['./gift-detail.component.scss']
})
export class GiftDetailComponent implements OnInit {
  @Input() gift: any;
  categories: any[];
  stores: any[];
  loaded = false;
  itemId: string;
  images: any[] = [];
  imagesFiles: any[] = [];
  item: any;
  category: any;
  isOpen: boolean = false;
  isOpenDelete: boolean = false;
  itemForm: FormGroup;
  isGuest: boolean;
  publicAssets = URL_RESOURCES.ASSETS;
  @ViewChild('newGiftImage') inputImageField: ElementRef;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _layoutService: LayoutService,
    private coupleService: AccountService,
    private spinner: NgxSpinnerService,
    private catalogService: CatalogService,
    private layoutService: LayoutService,
    private notification: NotificationService,
    private formBuilder: FormBuilder,
  ) {
    this.categories = [];
    this.stores = [];
    this.isGuest = this.layoutService.isGuest();
  }

  addCart(product: { id: any; quantity: any; }) {
    product.quantity = 1;
    this.layoutService.cart.add(product);
  }

  async catalogs(): Promise<void> {
    try {
      this.spinner.show();
      this.categories = await this.coupleService.getCategories();
      this.stores = await this.coupleService.getStores();
    } catch (e) {
      console.log(e);
    } finally {
      this.spinner.hide();
      this.activatedRoute.params.subscribe(params => {
        this.loaded = true;
        this.itemId = params['id'];
        this.item = <any>JSON.parse(JSON.stringify(this.gift));
        this.makeForm();

      });
    }
  }

  get currency() {
    return this.layoutService.settings.info.currency;
  }

  formatPrice(price) {
    return price / this.layoutService.settings.info.currencyChange;
  }

  makeForm() {
    console.log(this.item);
    this.itemForm = this.formBuilder.group({
      name: [this.item.name, Validators.required],
      price: [this.item.price, [Validators.required, Validators.min(1)]],
      description: [this.item.description, Validators.required],
      storeId: [this.item.storeId, Validators.required],
      category: [this.item.categoriesIds, Validators.required]
    })
  }

  getCatName(id) {
    let name = this.categories.filter(cat => cat.id == id).map(function (obj) {
      return obj.name;
    });
    return name;
  }

  getStoreName(id) {
    let name = this.stores.filter(store => store.id == id).map(function (obj) {
      return obj.name;
    });
    return name;
  }

  openDialog(): void {
    this.isOpen = true;
  }

  ngOnInit() {
    this.catalogs();
    if (!this.gift.imagesList) {
      this.gift.imagesList = [];
    }
    //this.gift.imagesList[0] = this.gift.image;
  }

  obtenURL(objDat: string) {
    this.gift.image = objDat;
  }

  borrarArt(idArt: string) {
    //alert("Eliminar artículo: " + idArt);

    /*
      dialogRef.afterClosed().subscribe(result => {
        if(result==true){             
           this.commitRemoval(idArt);
           console.log("Guardar");
        }
        else{
          console.log("Cancelar");
        }
      });*/
  }

  async commitRemoval(idArt: string): Promise<void> {
    try {
      this.spinner.show();
      const couple = this.layoutService.getCoupleInformation();
      let _couple = await this.coupleService.get(couple.id);
      _couple.productsRegistryList = couple.productsRegistryList.filter(function (obj) {
        return obj.id != idArt;
      });
      const update = await this.coupleService.setProductsList(_couple.id, _couple.experience, _couple.productsRegistryList);
      this.layoutService.setCoupleInformation(_couple);
      this.catalogService.catalogChanges();
    } catch (e) {
      this.notification.error(e);
    } finally {
      this.notification.success("gift eliminado");
      this.spinner.hide();
      this.router.navigate(['/gift-table']);
    }
  }

  //--STUFF

  quitarFoto(index: number, type) {
    switch (type) {
      case 'new':
        this.imagesFiles.splice(index, 1);
        this.images.splice(index, 1);
        break;
      case 'old':
        this.item.imagesList.splice(index, 1);
        break;
    }

  }

  agregarFoto(e) {
    e.preventDefault();
    e.stopPropagation();
    document.getElementById('new-gift-image').click();
  }

  fileProgress(fileInput: any) {
    const fileData = <File>fileInput.target.files[0];
    if (this.checkFileSize(fileInput)) {
      this.notification.warning("El tamaño del archivo no debe exceder los 5 MB");
      this.inputImageField.nativeElement.value = null;
      return
    }
    this.readThis(fileData);
  }

  changeListener($event): void {
    this.readThis($event.target);
  }

  readThis(file: any): void {
    // var file:File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.images.push(myReader.result);
      this.imagesFiles.push(file);
    }
    myReader.readAsDataURL(file);
    this.inputImageField.nativeElement.value = null;
  }

  async uploadPicture(id: string, file: any): Promise<any> {
    return await this.coupleService.uploadImage(id, file);
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  checkFileSize(fileEvent: any) {
    const file = fileEvent.target.files[0];
    return (file.size / 1024 / 1024) > 5;
  }

  countImages() {
    if (this.images && this.item.imagesList) {
      return (4 > this.images.length + this.item.imagesList.length) ? true : false;
    } else {
      return true;
    }
  }

  async guardarRegalo() {
    try {
      this.spinner.show();
      this.markFormGroupTouched(this.itemForm);
      if (this.itemForm.invalid) {
        this.notification.warning('Los campos marcados con * son requeridos');
        return;
      }
      let gift = this.item;
      const couple = this.layoutService.getCoupleInformation();
      let _couple = await this.coupleService.get(couple.id);

      gift.name = this.itemForm.value.name;
      gift.description = this.itemForm.value.description;
      gift.price = this.itemForm.value.price
      gift.storeId = this.itemForm.value.storeId;
      gift.qty = 1;
      gift.categoriesIds = []
      if (this.itemForm.value.category instanceof Array) {
        gift.categoriesIds = this.itemForm.value.category;
      } else {
        gift.categoriesIds = [this.itemForm.value.category];
      }

      if (!gift.imagesList) {
        gift.imagesList = [];
      }

      for (const file of this.imagesFiles) {
        const url = await this.uploadPicture(gift.id, file);
        gift.imagesList.push(url.url);
      }
      gift.image = gift.imagesList[0];
      let info = await this.coupleService.updateProductTable(gift, this.layoutService.getUser().id);
      let Index = _couple.productsRegistryList.map(function (e) { return e.id; }).indexOf(info.id);
      _couple.productsRegistryList[Index] = gift;
      await this.coupleService.setProductsList(_couple.id, _couple.experience, _couple.productsRegistryList);
      this.layoutService.setCoupleInformation(_couple);
      let user = this.layoutService.getCurrentUser();
      user.user = _couple;
      this.layoutService.setCurrentUser(user);
      this.catalogService.catalogChanges();
      this.notification.success("gift actualizado");
      this.router.navigate(['gift-table']);
      this.isOpen = false;
    } catch (e) {
      this.notification.error(e);
      console.log(e);
    } finally {
      this.spinner.hide();
    }
  }

}
