<div class="content-buttons" *ngIf="!isGuest">
  <button (click)="updateView($event)" class="btn btn-uniko-sm" [class.uniko-primary]="couple.viewInstagram"
    [class.uniko-off]="!couple.viewInstagram" [disabled]="quickLock">
    <i class="material-icons white">
      visibility
    </i>
  </button>
  <button class="btn btn-uniko-sm uniko-secondary" (click)="isOpen=true;">
    <i class="material-icons">
      edit
    </i>
  </button>
</div>
<div class="row py-4" [class.d-none]="isGuest && !couple.viewInstagram"
  [class.section-uniko-off]="!couple.viewInstagram">
  <div class="col-12 my-4">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6 col-lg-6 m-0 p-0">
        <p class="m-0 p-0 mb-2 t-primary" style="font-size:12px; font-weight:600; text-align: center;">ALGUNOS
          MOMENTOS</p>
        <h1 class="mb-5 text-title t-base" *ngIf="haveHashtag">#{{instagramInfo.hashtag}}</h1>
        <h1 class="mb-5 text-title t-base" *ngIf="!haveHashtag && !haveGallery">¡Empieza tu propia galería de
          fotos!</h1>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 m-0 p-0" style="overflow-x:auto;">
        <div class="images" *ngIf="haveGallery">
          <img *ngFor="let image of instagramInfo.images" [src]="image" class="fotoGaleria">
        </div>
        <div class="col-12 text-center m-0 p-0" *ngIf="!haveGallery">
          <button class="btn tb-secondary t-light" (click)="isOpen=true;">Galería del evento</button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-modal [isOpen]="isOpen" (onClosed)="isOpen=false;" (onAction)="saveGallery()" textButton="Guardar">
  <div class="photo-gallery">
    <form [formGroup]="galleryForm" class="gallery-form">
      <div class="row">
        <div class="col-12">
          <span>Añade tus fotos favoritas para el evento</span>
        </div>
        <div class="col-12">
          <label class="label-control">Galería</label>
        </div>
      </div>
      <div class="row align-items-center mb-4 pl-2 pr-2">
        <div class="col-6 col-lg-3 p-2" *ngFor="let picture of gallery; let index = index;">
          <div class="image-container">
            <button class="action remove" (click)="removePicture($event, index)">
              <i class="fas fa-times"></i>
            </button>
            <img [src]="picture.saved?picture.imageUrl:picture.image">
          </div>
        </div>
        <div class="col-6 col-lg-3 p-2">
          <div class="image-container empty">
            <button class="action add" (click)="addPicture($event)">
              <i class="fas fa-plus"></i>
            </button>
            <img src="{{publicAssets}}/uniko-empty-image.jpg">
          </div>
        </div>
      </div>
      <!-- <div class="row">
              <div class="col-12">                
                  <div class="row">
                      <div class="col-12"></div>
                  </div>
                  <div class="row">
                      <div class="col-12 col-md-6">
                          <label for="" class="label-control">
                              Cuenta Instagram
                          </label>
                          <input maxlength="50" formControlName="account" type="emial" class="form-control" disabled>
                      </div>
                      <div class="col-12 col-md-6">
                          <label for="" class="label-control">
                              Escriban su hashtag
                          </label>
                          <input maxlength="50" formControlName="hashtag" type="emial" class="form-control">
                      </div>
                  </div>
                  <div class="row mt-2">
                      <div class="col">
                          <button class="btn color-anchor p-0" (click)="authInstagram()"> <i class="fas fa-plus"></i> Añadir cuenta de instagram</button>
                      </div>
                  </div>
              </div>
          </div> -->
    </form>
    <input #galleryInput id='galleryInput' type="file" hidden (change)='fileProgress($event)'>
  </div>
</app-modal>