import { ISettings } from './../_interface/settings';

export class Settings {
  protected _settings: ISettings;

  constructor() {
    if (localStorage.getItem('uniko.settings')) {
      this.getLocal();
    } else {
      this._settings = {
        currency: 'MXN',
        currencyChange: 1,
        language: 'ES',
        country: 'MX'
      }
    }
  }

  get info() {
    return this._settings;
  }

  set currency(currency: string) {
    this._settings.currency = currency;
    this.setLocal();
  }

  set currencyChange(change: number) {
    this._settings.currencyChange = change;
    this.setLocal();
  }


  decrypt(data: string): any {
    try {
      return JSON.parse(unescape(atob(atob(atob(data)))));
    } catch (e) {
      try {
        return atob(atob(atob(unescape(data))));
      } catch (e) {

      }
    }
  }

  encrypt(data: any): string {
    try {
      return btoa(btoa(btoa(escape(JSON.stringify(data)))));
    } catch (e) {
      try {
        return btoa(btoa(btoa(escape(data))));
      } catch (err) {
        return '';
      }
    }
  }

  getLocal() {
    this._settings = this.decrypt(localStorage.getItem('uniko.settings') || '');
  }

  setLocal() {
    localStorage.setItem('uniko.settings', this.encrypt(this._settings));
  }

}