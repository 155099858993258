import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss']
})
export class ToolsComponent implements OnInit {
  something: any;
  @Input()
  desktopMode: boolean;
  @Output()
  modeEmiter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {

  }

  vectorChangeMode(eventus) {
    try {
      switch (eventus) {
        case 'save':
          break;
        case 'edit':
          break;
        case 'mode':
          //let string = this.desktopMode=="desktop"?"mobile":"desktop";
          this.modeEmiter.emit(!this.desktopMode);
          break;
      }
    } catch (e) {

    } finally {

    }
  }

  changeMode() {
    this.vectorChangeMode("mode");
  }

}
