<div class="row mx-0">
  <form [formGroup]="timeForm" class="d-flex time-picker">
    <!--
    <div class="col-auto px-0">
      <mat-form-field style="width: 100%;">
        <input type="number" matInput class="form-control" min="0" max="100" formControlName="hour" (change)="changeMade()">
      </mat-form-field>
    </div>
    <div class="col-auto px-1">
      <mat-form-field>
        <input type="number" matInput class="form-control" min="0" max="100" formControlName="minute" (change)="changeMade()">
      </mat-form-field>
    </div>-->
    <div class="col-auto px-0">
      <mat-form-field style="width: 100%;">
        <input matInput 
          mask="hh:m0"
          type="text" 
          formControlName="time" (change)="changeMade()">
          <mat-hint class="small text-special">HH:MM</mat-hint>
      </mat-form-field>
    </div>
    <div class="col px-0">
      <button class="btn time-picker-button" (click)="changeMeridian()">
        {{meridian?'AM':'PM'}}
      </button>
    </div>
  </form>
</div>
