import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_RESOURCES } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(
    private http: HttpClient
  ) {

  }

  async setActive(menuId: string, active: boolean): Promise<any> {
    return await this.http.put<any>(
      `${URL_RESOURCES.API_UNIKO}/coupleMenu/${menuId}/setActive`,
      {
        active
      }
    ).toPromise();
  }
}
