<div class="w-100">
  <app-header menuKey="CONFIRMATION"></app-header>
</div>
<div class="w-100 pt-5 flex-grow-1">
  <div class="row mb-2">
    <div class="col-12 bg-uniko-light pt-5 pb-5" *ngIf="!complete && assistantInfo">
      <div class="row justify-content-center mb-3">
        <div class="col-12 col-md-8 text-center">
          <div class="image mb-2">
            <img src="{{publicAssets}}/uniko-guests.png">
          </div>
          <div class="bold">
            <p>Confirma tu asistencia a la boda de {{assistantInfo.couple.weddingData.nameP1}} y
              {{assistantInfo.couple.weddingData.nameP2}} </p>
          </div>
          <div class="small"><span>Selecciona la casilla indicando el número de invitados totales que confirmas</span>
          </div>
        </div>
      </div>
      <div class="row justify-content-center mb-3">
        <div class="col-12 col-md-8 text-center">
          <div class="form-group">
            <select class="form-control" name='assistants' [(ngModel)]='assistantInfo.asistentesConfirm' required>
              <option selected value="0">No asistiré</option>
              <option *ngFor="let option of options" value="{{option}}">{{option}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row justify-content-center mb-3">
        <div class="col-12 col-md-8 text-center">
          <button class="btn btn-uniko" (click)="confirmAssistants()">Confirmar</button>
        </div>
      </div>
    </div>
    <div class="col-12 bg-uniko-light pt-5 pb-5" *ngIf="complete">
      <div class="row justify-content-center mb-3">
        <div class="col-12 col-md-8 text-center">
          <div class="image">
            <img src="{{publicAssets}}/uniko-guests.png">
          </div>
          <div class="bold big">
            <p>Gracias por confirmar tu asistencia!</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center mb-3">
        <div class="col-12 col-md-8 text-center">
          <button class="btn btn-uniko" routerLinkActive="active"
            [routerLink]="'/'+assistantInfo.couple.url+'/gift-table'">Visita su mesa de regalos</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!complete && assistantInfo">
    <div class="col text-center">
      <a class="txt-uniko" routerLinkActive="active" [routerLink]="'/'+assistantInfo.couple.url+'/gift-table'">Ir a su
        mesa de regalos</a>
    </div>
  </div>
</div>