<div class="row m-0 h-100">
  <div class="col-12 h-100 p-0">
    <div class="row content-background">
      <!--SGV SHOULD BE HERE-->
      <app-templates [loadComplete]="loadComplete" [content]="content" [templateId]="templateId"></app-templates>
    </div>
    <div class="content-container h-100 p-3">
      <p class="title" [ngStyle]="getStyle('title')">
        {{ content?.title.text }}
      </p>
      <h1 class="nameTitle" [ngStyle]="getStyle('nametitle')">
        {{ content?.nametitle.text }}
      </h1>
      <p class="titleDate mt-5 mb-3" [ngStyle]="getStyle('titleDate')">
        {{ content?.titleDate.text }}
      </p>
      <h1 class="date" [ngStyle]="getStyle('date')">
        {{ content?.date.text }}
      </h1>
      <p class="titleDescription m-0 p-0 mt-4 mb-5" [ngStyle]="getStyle('titleDescription')">
        {{ content?.titleDescription.text }}
      </p>
      <p class="description" [ngStyle]="getStyle('description')">
        {{ content?.description.text }}
      </p>
    </div>
  </div>
</div>