import { User, CurrentUser, Couple } from './data';
import { SaveDate } from './saveDate';
import { Cart } from './cart';
import { Order } from './order';
import { Plan } from './plan';
import { CoupleInformation } from './coupleInformation';
import { Cashout } from './cashout';
import { Credit } from './credit';
import { Authorization } from './authorization';
import { Settings } from './../_models/settings';
import { Sura } from './sura';

export class Layout {
    user: User;
    coupleInformation: CoupleInformation;
    currentUser: CurrentUser;
    saveDate: SaveDate;
    cart: Cart;
    order: Order;
    plan: Plan;
    cashout: Cashout;
    credit: Credit;
    authorization: Authorization;
    settings: Settings;
    sura: Sura;
    constructor() {
        this.user = new User();
        this.currentUser = new CurrentUser();
        this.coupleInformation = new CoupleInformation();
        this.saveDate = new SaveDate();
        this.cart = new Cart();
        this.order = new Order();
        this.plan = new Plan();
        this.cashout = new Cashout();
        this.credit = new Credit();
        this.authorization = new Authorization();
        this.settings = new Settings();
        this.sura = new Sura();
    }

    clear() {
        this.currentUser = new CurrentUser();
        this.user = new User();
        this.coupleInformation = new CoupleInformation();
        this.saveDate = new SaveDate();
        this.cart = new Cart();
        this.order = new Order();
        this.plan = new Plan();
        this.cashout = new Cashout();
        this.credit = new Credit();
    }
    
}