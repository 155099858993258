<div class="site-section col-auto">
  <div class=" content-buttons" *ngIf="!isGuest">
    <button (click)="updateView()" class="btn btn-uniko-sm" [class.uniko-primary]="couple.viewTime"
      [class.uniko-off]="!couple.viewTime" [disabled]="quickLock">
      <i class="material-icons white" *ngIf="couple.viewTime">
        visibility
      </i>
      <i class="material-icons white" *ngIf="!couple.viewTime">
        visibility_off
      </i>
    </button>
  </div>
  <div class="row py-4 justify-content-center" [class.d-none]="isGuest && !couple.viewTime"
    [class.section-uniko-off]="!couple.viewTime">
    <div class="col-12 col-md-4 my-4">
      <div class="card">
        <div class="card-header m-0 p-1 text-center t-base tb-tertiary" style="font-weight: 600; font-size:12px;">
          ¡PREPÁRENSE! YA FALTA POCO…
        </div>
        <div class="card-body p-2 m-0">
          <div class="row p-0 m-0">
            <div class="col-3 p-0 m-0 text-center">
              <h3 class="t-primary p-0 m-0" style="font-weight: 600;">{{diffDate.days}}</h3>
              <p class="p-0 m-0">Días</p>
            </div>
            <div class="col-3 p-0 m-0 text-center">
              <h3 class="t-primary p-0 m-0" style="font-weight: 600;">{{diffDate.hours}}</h3>
              <p class="p-0 m-0">Horas</p>
            </div>
            <div class="col-3 p-0 m-0 text-center">
              <h3 class="t-primary p-0 m-0" style="font-weight: 600;">{{ diffDate.minutes }}</h3>
              <p class="p-0 m-0">Minutos</p>
            </div>
            <div class="col-3 p-0 m-0 text-center">
              <h3 class="t-primary p-0 m-0" style="font-weight: 600;">{{ diffDate.seconds }}</h3>
              <p class="p-0 m-0">Segundos</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="site-section section-shadow col-auto">
  <div class="content-buttons" *ngIf="!isGuest">
    <button (click)="onEdit()" class="btn btn-uniko-sm uniko-secondary">
      <i class="material-icons">
        edit
      </i>
    </button>
  </div>
  <div class="container">
    <div class="row py-4 justify-content-center">
      <div class="col-12 col-lg-8 history my-4">
        <div class="primary-history">
          <h1 class="title-description t-base">{{ couple.weddingData.titleMessage }}</h1>
          <p class="t-base">{{ couple.weddingData.welcomeMessage }}</p>
        </div>
        <div class="col-12 col-md-2 col-lg-2 m-0 p-0 primary-divider">
          <div class="tb-secondary mx-auto bar-separe"></div>
        </div>
        <div class="secondary-history">
          <p class="m-0 p-0 mt-5 mb-2 t-primary" style="font-size:12px; font-weight:600;">LOS ESPERAMOS</p>
          <div class="row justify-content-center m-0 p-0">
            <div class="when t-base text-capitalize">
              <p class="m-0 p-0">{{ date }}</p>
              <p class="m-0 p-0">{{ hour }}</p>
            </div>
            <div class="col-auto secondary-divider">
              <div class="tb-secondary mx-auto bar-separe"></div>
            </div>
            <div class="where t-base">
              <p class="m-0 p-0 text-event-align-left t-primary" style="font-size:14px; font-weight:700;">
                {{ this.couple.weddingData.placeName | uppercase }}</p>
              <p class="m-0 p-0 text-event-align-left">
                {{ couple.weddingData.address }}
              </p>
              <a *ngIf="couple.weddingData.gpsaddress" target="_blank" href="{{ couple.weddingData.gpsaddress.url}}"
                class="display text-event-align-left">Ver en Google Maps</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>