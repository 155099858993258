export const environment = {
  production: false
};

export const URL_RESOURCES = {
  API_UNIKO: 'https://staging-api.uniko.co/api/v2',
  //API_UNIKO: 'http://localhost:3000/api/v2',
  UNIKO: 'http://evento-v2.uniko.co',
  GLOBAL: 'https://uniko.co',
  ASSETS: 'https://s3-us-west-2.amazonaws.com/uniko-prod/assets',
  DOCS: 'https://s3-us-west-2.amazonaws.com/uniko-prod/documents'
};

export const KEYS = {
  CONEKTA_GUEST: 'key_DbUrckRgW1RjgS3SBVfgWFp',
  CONEKTA_ACCOUNT: 'key_qQtGo8hXi0o1AqWrAz8IsUb',
  paypalLive: 'sandbox',
  INSTAGRAM_CLIENT_ID: '1cac2e75f3c44d7f9c75f7b760728f3b'
}
