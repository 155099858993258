import { Component, OnInit, Input } from '@angular/core';
import { ICoupleInformation } from 'src/app/_interface/coupleInformation';
import { LayoutService } from 'src/app/services/layout.service';
import { AccountService } from 'src/app/services/account.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ICart } from 'src/app/_interface/cart';
import { UserService } from 'src/app/services/user.service';
import { URL_RESOURCES } from 'src/environments/environment';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss']
})
export class NavigationMenuComponent implements OnInit {

  @Input() guest: boolean;
  @Input() couple: ICoupleInformation;
  @Input() menuKey: string;
  @Input() menus: any;
  @Input() plan: any;
  @Input() public: boolean;
  @Input() off: boolean = false;
  isOpenMenu: boolean = false;
  isOpenMWT: boolean = false;
  isOpenProfile: boolean = false;
  isOpenCart = false;
  isOpen = false;
  cart: ICart;
  title: string;
  guestUrl: string;
  activeMenus: any;
  totalItems: any;
  bolsaCompra: boolean = true;
  global: any = URL_RESOURCES.GLOBAL;
  totalAmmount: number;
  publicAssets = URL_RESOURCES.ASSETS;
  constructor(
    private layoutService: LayoutService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.couple = this.layoutService.getCoupleInformation();
    if (!this.public && !this.off) {
      this.layoutService.cart.cartEmitter.subscribe(data => {
        this.isOpenCart = true;
        this.cart = this.layoutService.cart.cart;
        this.totalItems = this.layoutService.cart.totalItems;
      });
      this.cart = this.layoutService.cart.cart;
      this.totalItems = this.layoutService.cart.totalItems;
      this.totalAmmount = this.cart.products.reduce((total, gift) => {
        return total + gift.price;
      }, 0) / this.layoutService.settings.info.currencyChange;
      this.title = this.getTitle();
    }
  }

  getTitle() {
    if(this.couple && this.couple.weddingData){
      switch (this.couple.weddingData.type) {
        case 'BABY':
          const name = this.couple.weddingData.nameP1.split(' ');
          return `${name[0].charAt(0).toUpperCase() + name[0].slice(1)}`;
        case 'WEDDING':
        default:
          return `${this.couple.weddingData.nameP1.charAt(0).toUpperCase()} & ${this.couple.weddingData.nameP2.charAt(0).toUpperCase()}`
      }
    }
    return '';
  }

  emptyProduct() {
    return (this.cart.product && (Object.keys(this.cart.product).length === 0));
  }

  emptyProductList() {
    return (this.cart.products && this.cart.products.length === 0);
  }

  formatPrice(articulo: { price: number; }) {
    return articulo.price / this.layoutService.settings.info.currencyChange;
  }

  get currency() {
    return this.layoutService.settings.info.currency;
  }

  isActive(menu: string) {
    if (this.guest && this.menus) {
      const _menu = this.menus.find((data: { _key: string; }) => data._key === menu);
      if (_menu && _menu.active) {
        return true
      }
      return false;
    } else {
      return true;
    }
  }

  onClose() {
    this.isOpenCart = false;
  }

  mapMenuInactive(key: any) {
    if (this.plan) {
      const menu = this.plan.planAggre.find((item: { listAggre: { keyMenu: any; }; }) => item.listAggre.keyMenu == key);
      if (menu) {
        return !menu.isActive;
      }
    }
    return false;
  }

  mapMenuSelected(key: string) {
    if (this.menuKey) {
      return this.menuKey == key;
    }
    return false;
  }

  /*openDialog(): void {
    const dialogRef = this.dialog.open(DialogAlertaPro, { disableClose: false });
    dialogRef.afterClosed().subscribe(result => {
    });
  }*/

  goUrlTools(event: Event, type: string) {
    event.preventDefault();
    event.stopPropagation();
    let url: any[] = [];
    switch (type) {
      case "GUEST_CONFIRMATION": url = ["/tools", "guest"];
        break;
      case "DOMINE": url = ["/tools", "domain"];
        break;
      case "WEDDING_ACADEMY": url = ["weddingAccademy"];
        break;
      case "TICKET": url = ["/tools", "tickets"];
        break;
    }
    if (this.layoutService.authorization.verify(type)) {
      this.router.navigate(url);
    } else {
      //this.openDialog();
    }
  }

  goUrl(event: Event, type: string) {
    event.preventDefault();
    event.stopPropagation();
    if (this.layoutService.authorization.verify(type)) {
      this.router.navigate(['/tools', type === 'GUEST_CONFIRMATION' ? 'guest' : 'domain']);
    } else {
      //this.openDialog();
    }
  }

  logout(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.layoutService.clear();
    location.reload();
  }

}
