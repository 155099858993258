import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { Button } from '../_models/data';
import { formatDate } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as dayjs from 'dayjs';
import { URL_RESOURCES } from 'src/environments/environment';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  filter: any;
  results: any[];
  buttons: Array<Button> = [];
  format = 'dd · MM · yyyy';
  locale = 'en-US';
  searchForm: FormGroup;
  publicAssets = URL_RESOURCES.ASSETS;
  constructor(
    private coupleService: AccountService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private formBuilder: FormBuilder,
    private notification: ToastrService
  ) {
    localStorage.clear();
    this.filter = {
      "filter": {
        "where": {
          "names": "",
          "url": "",
          "isDisabled": false,
          "is_active": true,
          "fromNow": true,
          "payment_state": 2,
          "date": ""//DUMMY VALUE
        },
        "order": "weddingData.date ASC", "limit": 50
      }
    }



    this.getFilters();
  }

  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      name: [this.filter.filter.where.names, Validators.required],
      date: [this.filter.filter.where.date, Validators.required],
      type: [this.filter.filter.where.type],
      isDate: [this.filter.filter.where.date ? false : true],
    });
    this.onChanges();
  }

  onChanges() { //date Validator
    this.searchForm.valueChanges.subscribe(value => {
      if (value.date && value.isDate) {
        this.searchForm.patchValue({
          isDate: false
        });
      }
    });
  }

  goToEvent(event: any) {
    this.router.navigate([event]);
  }

  eventFormatDate(date: string | number | Date): string {
    return date ? formatDate(date, this.format, this.locale) : 'Sin fecha';
  }

  newQuery() {
    this.filter.filter.where.names = this.searchForm.value.name;
    delete this.filter.filter.where.type;
    if (this.searchForm.value.date) {
      this.filter.filter.where.startDate = dayjs(this.searchForm.value.date).format('YYYY-MM-DD');
      this.filter.filter.where.endDate = dayjs(this.searchForm.value.date).add(1, 'day').format('YYYY-MM-DD');
    } else {
      delete this.filter.filter.where.startDate;
      delete this.filter.filter.where.endDate;
    }
    if (this.searchForm.value.type) {
      this.filter.filter.where.type = this.searchForm.value.type;
    }
    this.filter.filter.where.fromNow = !this.filter.filter.where.names && !this.searchForm.value.date ? true : false;
    console.log(this.filter);
    this.getFilters();
  }

  getName(event: { weddingData: { type: string; nameP1: any; nameP2: any; }; }) {
    const name = event.weddingData.type === 'WEDDING' ? `${event.weddingData.nameP1} & ${event.weddingData.nameP2}` : event.weddingData.nameP1;
    return name;
  }

  getImage(event: { weddingData: { coverPhoto: any; type: any; }; theme: any; }) {
    let img = event.weddingData.coverPhoto;

    if (img == './../../assets/img/imgPrinNovios.jpg') {
      img = null;
    }

    let baseImg = `${this.publicAssets}/site/site_default`;
    let typeEvent = event.weddingData.type
    let theme = event.theme ? event.theme : ''
    baseImg += (theme && theme != '') ? '_' + theme : theme;
    switch (typeEvent) {
      case 'WEDDING':
        baseImg += '_wedding.jpg';
        break;
      case 'BABY':
        baseImg += '_baby.jpg';
        break;
      default:
        baseImg += '_wedding.jpg';
    }
    if (!img || !(img !== '')) {
      img = baseImg;
    }
    return img;
  }

  async getFilters() {
    try {
      this.results = [];
      this.spinner.show();
      this.results = await this.coupleService.getFilterTable(this.filter);
      console.log(this.results);

    } catch (e) {
      this.notification.error(<string>e);
    } finally {
      this.spinner.hide();
    }
  }
}
