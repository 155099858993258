import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SaveDateService } from 'src/app/services/save-date.service';
import { LayoutService } from 'src/app/services/layout.service';
import { URL_RESOURCES } from 'src/environments/environment';

@Component({
  selector: 'app-save-date-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  saveDate: any;
  isSelected: boolean = false;
  publicAssets = URL_RESOURCES.ASSETS;
  constructor(
    private spinner: NgxSpinnerService,
    private notification: ToastrService,
    private saveDateService: SaveDateService,
    private layoutService: LayoutService,
  ) {
    this.getInfo();
  }

  ngOnInit() {
  }

  getType() {
    switch (this.layoutService.getCoupleInformation().weddingData.type) {
      case 'WEDDING':
        return '_wedding';
      case 'BABY':
        return '_baby';
      case 'BIRTHDAY':
        return '_birthday';
      default:
        return '_wedding';
    }
  }

  async getInfo() {
    try {
      this.spinner.show();
      this.saveDate = await this.saveDateService.getSaveDate(this.layoutService.getCoupleInformation().id);
      if (this.saveDate) {
        this.isSelected = true;
      }
    } catch (e) {
      this.notification.error(<string>e);
    } finally {
      this.spinner.hide();
    }
  }

}
