import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';
import { AccountService } from 'src/app/services/account.service';
import { ICoupleInformation } from 'src/app/_interface/coupleInformation';
import { NgxSpinnerService } from 'ngx-spinner';
import { getImageUrl } from 'src/app/_helpers/exif.fixer'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { ActivatedRoute } from '@angular/router';
import { KEYS, URL_RESOURCES } from 'src/environments/environment';

export interface galleryObject {
  image?: any,
  imageFile?: File,
  imageUrl?: string,
  saved?: boolean,
  ready?: boolean
}


@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  @Input() couple: ICoupleInformation;
  @Input() isGuest: boolean = false;
  isOpen: boolean = false;
  coupleGallery: any[];
  gallery: galleryObject[] = [];
  galleryForm: FormGroup;
  targetIndex: number;
  loaded: boolean = false;
  haveGallery: boolean = false;
  haveHashtag: boolean = false;
  @ViewChild('galleryInput') galleryInput: ElementRef;
  instagramInfo: any;
  quickLock: boolean = false;
  publicAssets = URL_RESOURCES.ASSETS;

  constructor(
    private coupleService: AccountService,
    private layoutService: LayoutService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private notification: NotificationService,
    private route: ActivatedRoute,
  ) {
    this.couple = this.layoutService.getCoupleInformation();
    this.layoutService.coupleEmitter.subscribe(data => {
      this.couple = this.layoutService.getCoupleInformation();
      this.getInstagram();
    });


    if (this.route.snapshot.queryParams["code"]) {
      this.validCode(this.route.snapshot.queryParams["code"]);
    }
  }

  ngOnInit() {
    this.galleryForm = this.formBuilder.group({
      account: [''],
      hashtag: [''],
    });
    this.getInstagram();
  }

  authInstagram() {
    this.spinner.show();
    const url = 'https://api.instagram.com/oauth/authorize/';
    location.href =
      `${url}?client_id=${KEYS.INSTAGRAM_CLIENT_ID}&redirect_uri=${location.origin}/site&response_type=code`;
  }

  validCode(code: string) {
    try {
      this.spinner.show();
      this.coupleService.authInstagram(code);
      this.isOpen = true;
    } catch (e) {
      this.notification.error(e);
    } finally {
      this.spinner.hide();
    }
  }

  setGallery() {
    if (this.instagramInfo.hashtag) {
      this.galleryForm.get('hashtag').setValue(this.instagramInfo.hashtag);
      this.haveHashtag = true;
    } else {
      this.haveHashtag = false;
    }

    if (this.instagramInfo.images) {
      let images = this.instagramInfo.images;
      this.gallery = [];
      this.coupleGallery = this.instagramInfo.images;
      this.haveGallery = true;
      for (let i = 0; i < images.length; i++) {
        this.gallery.push({
          imageUrl: images[i],
          saved: true,
        });
      }
    } else {
      this.haveGallery = false;
    }
  }

  async getInstagram() {
    console.log("Get gallery", this.couple.id);
    this.instagramInfo = await this.coupleService.getCouplesInstagram(this.couple.id);
    if (this.instagramInfo) {
      this.loaded = true;
      if (this.instagramInfo) {
        this.setGallery();
      }
    }
  }

  async delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async updateView(event) {
    try {
      this.spinner.show();
      this.quickLock = true;
      let consult = await this.coupleService.updateSiteView(this.couple.id, { viewInstagram: 1 });
      if (consult == "ok") {
        this.couple.viewInstagram = !this.couple.viewInstagram;
        this.layoutService.coupleInformation.info = this.couple;
      }
      this.quickLock = false;
    } catch (e) {
      this.notification.error(e);
    } finally {
      this.spinner.hide();
    }
  }

  addPicture(event) {
    event.preventDefault();
    event.stopPropagation();
    document.getElementById('galleryInput').click();
  }

  removePicture(event, index) {
    event.preventDefault();
    event.stopPropagation();
    this.gallery.splice(index, 1);
  }

  async fileProgress(fileInput: any) {
    try {
      if (this.checkFileSize(fileInput)) {
        this.notification.warning("El tamaño del archivo no debe exceder los 5 MB");
        this.galleryInput.nativeElement.value = null;
        return
      }
      const fileData = <File>fileInput.target.files[0];
      let newFileData = await getImageUrl(fileData, 2048);
      //console.log("Me done",newFileData);
      let testFile = this.b64toBlob(newFileData);
      //this.gallery.push({image: newFileData, imageFile: fileData, ready:true});
      this.readThis(testFile);
      this.galleryInput.nativeElement.value = null;
    } catch (e) {
      console.log(e);
    } finally {

    }
  }

  b64toBlob(dataURI) {

    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }

  checkFileSize(fileEvent: any) {
    const file = fileEvent.target.files[0];
    return (file.size / 1024 / 1024) > 5;
  }

  readThis(file: any): void {
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.gallery.push({ image: myReader.result, imageFile: file, ready: true });
      this.spinner.hide();
    }
    myReader.readAsDataURL(file);
    this.spinner.show();
  }

  async uploadPicture(id: string, file: any): Promise<any> {
    return await this.coupleService.uploadImage(id, file);
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  async saveGallery() {
    try {
      this.spinner.show();
      this.markFormGroupTouched(this.galleryForm);
      if (this.galleryForm.invalid) {
        return;
      }
      let uploadedImages: any[] = [];
      const oldImages = this.gallery.filter(item => item.saved == true);
      const newImages = this.gallery.filter(item => item.ready == true);

      for (const picture of oldImages) {
        uploadedImages.push(picture.imageUrl);
      }
      for (const picture of newImages) {
        const url = await this.uploadPicture(this.couple.id, picture.imageFile);
        uploadedImages.push(url.url);
      }
      const data = { images: uploadedImages, hashtag: this.galleryForm.value.hashtag };
      const result = await this.coupleService.setCouplesInstagram(data);
      this.instagramInfo = result;
      this.setGallery();
      this.isOpen = false;
    } catch (e) {
      this.notification.error(e);
    } finally {
      this.spinner.hide();
    }
  }
}
