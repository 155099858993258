import { ICashout } from '../_interface/cashout';

export class Cashout {
  protected _cashout: ICashout;
  constructor() {
    if (localStorage.getItem('cashout')) {
      this.getLocal();
    }
  }

  get info(): ICashout {
    return this._cashout;
  }

  set info(info: ICashout) {
    this._cashout = info;
    this.setLocal();
  }

  clear() {
    localStorage.removeItem('cashout');
  }



  decrypt(data: string): any {
    try {
      return JSON.parse(unescape(atob(atob(atob(data)))));
    } catch (e) {
      try {
        return atob(atob(atob(unescape(data))));
      } catch (e) {

      }
    }
  }

  encrypt(data: any): string {
    try {
      return btoa(btoa(btoa(escape(JSON.stringify(data)))));
    } catch (e) {
      try {
        return btoa(btoa(btoa(escape(data))));
      } catch (err) {
        return '';
      }
    }
  }

  getLocal() {
    this._cashout = this.decrypt(localStorage.getItem('cashout') || '');
  }

  setLocal() {
    localStorage.setItem('cashout', this.encrypt(this._cashout));
  }
}