import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { ICoupleInformation } from 'src/app/_interface/coupleInformation';
import { IAuthorization } from 'src/app/_interface/authorization';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Button } from './../../_models/data';
import { LayoutService } from 'src/app/services/layout.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { UserService } from 'src/app/services/user.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() buttons: Array<Button> = [];
  private muestAgregar: boolean = false;
  @Input() title: string;
  @Output() onClick: EventEmitter<boolean> = new EventEmitter();
  @Input() btnDisable: boolean = false;
  @Input() menuKey: string = '';
  @Input() checkboxValue: boolean = false;
  @Input() noActions: boolean;
  @Input() public: boolean;
  @Input() off: boolean = false;
  guest: boolean = false;
  couple: ICoupleInformation;
  auth: IAuthorization;
  showMenu: boolean = false;
  dataForm: FormGroup;
  menu: any;
  menus: any[];
  constructor(
    private layoutService: LayoutService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private menuService: MenuService,
    private spinner: NgxSpinnerService,
    private notification: ToastrService,
    private coupleService: AccountService,
  ) {
    if (!this.off) {
      this.guest = this.layoutService.isGuest();
      this.couple = this.layoutService.getCoupleInformation();
      this.layoutService.coupleEmitter.subscribe(data => {
        this.couple = this.layoutService.getCoupleInformation();
      });
    }
  }

  ngOnInit(): void {
    this.dataForm = this.formBuilder.group({
      active: [this.checkboxValue]
    });
    this.getInfo();
  }

  async getInfo() {
    try {
      if (this.couple && this.couple.id) {
        const menus = await this.userService.getMenu(this.couple.id);
        this.auth = await this.coupleService.getPlanPermisions(this.couple.id);
        this.menus = menus;
        this.menu = menus.find((data: { _key: string; }) => data._key === this.menuKey);
        this.layoutService.authorization.info = this.auth;
      }
    } catch (e) {

    }
  }

  changeMenu() {
    this.showMenu = !this.showMenu;
  }

  async onActive(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    try {
      this.spinner.show();
      if (this.menu && this.layoutService.authorization.verify(this.menuKey)) {
        await this.menuService.setActive(this.menu.id, this.dataForm.value.active)
        this.checkboxValue = this.dataForm.value.active;
      }
      this.onClick.emit(this.checkboxValue);
    } catch (e) {
      this.notification.error(<string>e);
    } finally {
      this.spinner.hide();
    }
  }

  get formActiveCheck() {
    return this.dataForm.value.active;
  }

  muestOcult() {
    if (this.dataForm.value.active == true && !this.layoutService.authorization.verify(this.menuKey)) {
      this.dataForm.patchValue({
        active: false
      });
      //this.openDialog();
    }
  }

  onAdd(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.muestAgregar = !this.muestAgregar;
    console.log(this.muestAgregar);
  }
  getTicket(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    alert('Obten Boletos');
  }
  creaRegalo(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    alert('Crear Regalo');
  }

}
