import { Component, OnInit } from '@angular/core';
import { ICoupleInformation } from 'src/app/_interface/coupleInformation';
import { Button, ImageSnippet } from 'src/app/_models/data';
import { TEMPLATES } from 'src/app/_const/templates';
import { SaveDate } from 'src/app/_models/saveDate';
import { ISaveDate } from 'src/app/_interface/sateDate';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from 'src/app/services/account.service';
import { LayoutService } from 'src/app/services/layout.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SaveDateService } from 'src/app/services/save-date.service';
import dayjs from "dayjs";
import { ImageCroppedEvent, LoadedImage, base64ToFile } from 'ngx-image-cropper';
//import * as htmlToImage from 'html-to-image';
import html2canvas from 'html2canvas';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { URL_RESOURCES } from 'src/environments/environment';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements OnInit {
  templateId: any = {};
  isOpenImg: boolean = false;
  classicMode: boolean = true;
  couple: ICoupleInformation;
  isOpenShare: boolean = false;
  isOpenImgLand: boolean = false;
  buttons: Array<Button> = [];
  previw: boolean = false;
  activeEdit = false;
  template: string = '';
  listBackround = TEMPLATES;
  selectedFile: any;
  cover: any;
  baseCover: string;
  proxy: boolean = false;
  saveDate: SaveDate;
  _saveDate: ISaveDate;
  image: any;
  templateObj: any;
  backgroundTemplate: any;
  color: any;
  isOpen: boolean = false;
  isShared: boolean = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageChooser: boolean = true;
  file: any;
  url: any;
  eventUrl: string = '';
  loadComplete: boolean = false;
  publicAssets = URL_RESOURCES.ASSETS;
  isGuest: boolean;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private coupleService: AccountService,
    private layoutService: LayoutService,
    private notification: NotificationService,
    private saveDateService: SaveDateService,
  ) {
    this.templateId = this.activatedRoute.snapshot.params["id"];
    this.eventUrl = this.activatedRoute.parent.snapshot.params["eventUrl"];
  }

  ngOnInit() {
    this.getCouple();
  }

  async ClientForEventInfo() {
    const { id } = this.layoutService.getCoupleInformation();
    return await this.layoutService.isGuest() ?
      this.coupleService.findOneRestrict(this.eventUrl) :
      this.coupleService.getPublic(id);
  }

  async getCouple() {
    try {
      this.isGuest = this.layoutService.isGuest();
      this.spinner.show();
      this.couple = await this.ClientForEventInfo();
      this.baseCover = `${this.publicAssets}/templates/foto` + this.templateId + this.getEventString() + '.jpg';
      this.cover = this.baseCover;
      this.getSaveDate();
    } catch (e) {
      this.notification.error("Hubo un error al cargar los datos.");
      console.log(e);
    } finally {
      this.loadComplete = true;
      this.spinner.hide();
    }
  }

  getEventString() {
    switch (this.couple.weddingData.type) {
      case 'BABY':
        return '_baby';
      case 'WEDDING':
      default:
        return '';
    }
  }

  setButtons() {
    const buttonSave = new Button(
      'Guardar plantilla',
      this.setTemplate,
      'fa-save',
      null,
      this
    );
    const buttonShared = new Button(
      'Compartir',
      this.screenShared,
      'fa-share-alt',
      null,
      this
    );
    const buttonScreen = new Button(
      'Descargar imagen',
      this.prepareScreen,
      'fa-download',
      null,
      this
    );
    const buttonChangeTemplate = new Button(
      'Ver plantillas',
      this.goToList,
      'fa-chevron-circle-left',
      null,
      this
    );

    if (!this._saveDate || this.templateId != this._saveDate.templateId) {
      this.buttons = [buttonShared, buttonScreen, buttonSave, buttonChangeTemplate];
    } else {
      this.buttons = [buttonShared, buttonScreen, buttonChangeTemplate];
    }

  }

  changeMode(event) {
    const _desktopMode = event;
    this.modeSave(_desktopMode);
  }

  get mode() {
    return this.templateObj ? this.templateObj.desktop : true;
  }

  setTemplateLayout() {
    console.log("TemplateId", this.templateId);
    const template = Object.assign({}, this.listBackround[this.templateId]);
    this.templateObj = JSON.parse(JSON.stringify(template));
    console.log("tmpObj", this.templateObj);
  }

  async getSaveDate(): Promise<void> {
    this._saveDate = await this.saveDateService.getSaveDate(this.couple.id);
    if (!this._saveDate && this.isGuest) {
      this.notification.warning('Este evento aun no cuenta con Save The Date');
      this.router.navigate(['/search']);
      return;
    }

    if (
      !!(this.saveDate) &&
      (this._saveDate.templateId == this.templateId && !this.isGuest) ||
      this.isGuest
    ) {
      this.templateId = this._saveDate.templateId;
    }

    /*if (!this._saveDate || (this._saveDate && this._saveDate.templateId != this.templateId)) {
      console.log("A");
      this.templateId = this.activatedRoute.snapshot.params['id'];
    } else {
      console.log("B");
      this.templateId = this._saveDate.templateId;
    }*/

    this.setTemplateLayout();
    this.setButtons();

    if (this._saveDate != null && String(this.templateId) === String(this._saveDate.templateId)) {
      console.log("Alpha", this._saveDate);
      //this.templateObj = this.listBackround[Number(this._saveDate.templateId)]
      if (this.templateId != 6) {
        this.templateObj.title = this._saveDate.title;
        this.templateObj.nametitle = this._saveDate.nametitle;
        this.templateObj.titleDate = this._saveDate.titleDate;
        this.templateObj.date = this._saveDate.date;
        this.templateObj.titleDescription = this._saveDate.titleDescription;
        this.templateObj.description = this._saveDate.description;
        this.selectColor(this._saveDate.colors.index);
        this.selectTemplateColor(this._saveDate.backgroundTemplate.index);
      }
      if (this._saveDate.cover) {
        this.proxy = true;
        this.cover = this._saveDate.cover;
      }
      this.templateObj.desktop = this._saveDate.desktop;
    } else if (this._saveDate) {
      if (this.templateId != 6) {
        this.templateObj.title.text = this._saveDate.title.text;
        this.templateObj.nametitle.text = this._saveDate.nametitle.text;
        this.templateObj.titleDate.text = this._saveDate.titleDate.text;
        this.templateObj.titleDescription.text = this._saveDate.titleDescription.text;
        this.templateObj.description.text = this._saveDate.description.text;
        this.templateObj.date.text = this._saveDate.date.text;
        this.templateObj.description.text = this._saveDate.description.text;
      }
      this.templateObj.desktop = this._saveDate.desktop;
      if (this._saveDate.cover) {
        this.proxy = true;
        this.cover = this._saveDate.cover;
      }
    } else {
      console.log("Gamma");
      if (this.templateId != 6) {
        //this.templateObj.title.text = this.saveDate.title.text;
        this.templateObj.nametitle.text = `${this.couple.weddingData.nameP1} ${this.couple.weddingData.nameP2}`;
        this.templateObj.titleDate.text = dayjs(this.couple.weddingData.date).format('dd . MMM . yyyy');
        //this.templateObj.titleDescription.text = 
        //this.templateObj.description.text = this.saveDate.description.text;
        this.templateObj.date.text = dayjs(this.couple.weddingData.date).format('h:mm a');
        //this.templateObj.description.text = this.saveDate.description.text;

        //create Object with eventID
        this._saveDate = this.templateObj;
        this._saveDate.coupleAccountId = this.couple.id;
        this._saveDate.template = this.templateObj.name,
          this._saveDate.templateId = this.templateId;
        this._saveDate.desktop = this.templateObj.desktop;
      }
    }
  }

  selectColor(index) {
    this.templateObj.colors = this.templateObj.colors.map(data => {
      if (data.index == index) {
        data.select = true;
        data.color = this._saveDate.colors.color;
      } else {
        data.select = false;
      }
      return data;
    });
  }
  selectTemplateColor(index) {
    this.templateObj.backgroundTemplate = this.templateObj.backgroundTemplate.map(data => {
      if (data.index == index) {
        data.select = true;
        data.color = this._saveDate.backgroundTemplate.color;
      } else {
        data.select = false;
      }
      return data;
    });
  }

  onUpload(event: Event, element: string) {
    event.stopPropagation();
    event.preventDefault();
    document.getElementById(element).click();
  }

  async upload(event, type: string) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async () => {
        //  await this.uploadCoverPhoto(url, file);
        // this.selectedFile = new ImageSnippet(url, file);
        this.isOpenImg = true;
        this.imageChangedEvent = event;
      };
    }
  }
  async uploadLandscape(event, type: string) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async () => {
        //  await this.uploadCoverPhoto(url, file);
        // this.selectedFile = new ImageSnippet(url, file);
        this.isOpenImgLand = true;
        this.imageChangedEvent = event;
      };
    }
  }
  async uploadCoverPhoto(): Promise<void> {
    try {
      this.spinner.show();
      this.selectedFile = new ImageSnippet('', this.file);
      const coverPhoto = await this.coupleService.addCoverPhotoSaveDate(
        this.layoutService.getCoupleInformation().id,
        this.selectedFile.file
      );
      this.cover = coverPhoto.url;
      await this.saveCover();
      this.isOpenImgLand = false;
      this.isOpenImg = false;
    } catch (e) {
      console.log(e);
      this.notification.error(e);
    } finally {
      this.spinner.hide();
    }
  }



  onClose() {
    this.isOpen = false;
  }

  onCloseShare() {
    this.isOpenShare = false;
  }

  goToList(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this['parent'].router.navigate(['/save-date']);
  }

  goToEdit() {
    this.router.navigate(['/', 'save-date', 'editor', this.templateId]);
  }

  screenShared(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this['parent'].isOpenShare = true;
  }

  setTemplate(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this["parent"].templateSave();
  }

  prepareScreen(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    //this['parent'].isOpen = true;
    this['parent'].screen();
    //this['parent'].imageChooser = true;
  }

  async screen() {
    try {
      this.spinner.show();
      /*const element = document.getElementById('screen-modal');
      document.getElementsByClassName('editarArt');
      this['parent'].isOpen = true;
      */
      /*let image = await htmlToImage.toPng(document.getElementById('screen'), { pixelRatio: 1 });
      let link = document.createElement('a');
      link.download = `${this.couple.url}.png`;
      link.href = image;
      link.click();*/
      //document.body.appendChild(img);
      html2canvas(document.getElementById('screen'), {
        logging: true,
        allowTaint: true,
        removeContainer: true,
        proxy: "https://proxy-image.uniko.co",
      }).then((canvas) => {
        canvas.style.width = 'auto';
        canvas.style.height = 'auto';
        this.image = canvas.toDataURL("image/png;base64");
        this.downloadCanvas();
        //document.body.appendChild(canvas);
        //console.log(this['parent'].image)
        // if (element.children.length) {
        //   element.children[0].remove();
        // }
        // element.appendChild(canvas);
      });
    } catch (e) {
      console.log(e);
      this.notification.error(e);
    } finally {
      this.spinner.hide();
    }
  }

  async templateSave() {
    try {
      this.spinner.show();

      let saveDate = this._saveDate;
      if (this.templateId != 6) {
        saveDate.opacity = this.templateObj.opacity,
          saveDate.backgroundTemplate = this.templateObj.backgroundTemplate.find(data => data.select === true);
        saveDate.colors = this.templateObj.colors.find(data => data.select === true);
        saveDate.title = this.templateObj.title;
        saveDate.nametitle = this.templateObj.nametitle;
        saveDate.titleDate = this.templateObj.titleDate;
        saveDate.titleDescription = this.templateObj.titleDescription;
        saveDate.description = this.templateObj.description;
        saveDate.date = this.templateObj.date;
      }
      saveDate.template = this.templateObj.name,
        saveDate.templateId = this.templateId;
      saveDate.desktop = this.templateObj.desktop;

      await this.saveDateService.setSaveDate(saveDate);
      this._saveDate = await this.saveDateService.getSaveDate(this.couple.id);
      this.setButtons();
      this.notification.success("Plantilla actualizada");
    } catch (e) {
      console.log(e);
      this.notification.error(e)
    } finally {
      this.spinner.hide();
    }
  }

  async modeSave(mode) {
    try {
      this.spinner.show();
      let saveDate = this._saveDate;
      saveDate.desktop = mode;
      await this.saveDateService.setSaveDate(saveDate);
      //this.notification.success("Plantilla actualizada");
    } catch (e) {
      console.log(e);
      this.notification.error(e)
    } finally {
      this.templateObj.desktop = mode;
      this.spinner.hide();
    }
  }

  async saveCover() {
    this._saveDate.cover = this.cover;
    await this.saveDateService.setSaveDate(this._saveDate);
  }

  baseB4ToBlob(b64Data, contentType, sliceSize?: any) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  downloadImage() {

  }

  downloadCanvas() {
    const contentType = 'image/png';
    const b64Data = this.image.split(',')[1];
    const blob = this.baseB4ToBlob(b64Data, contentType, b64Data.length);
    const url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    a.download = `${this.couple.url}.png`;
    a.click();
    window.URL.revokeObjectURL(url);
    this.isOpen = false;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.file = base64ToFile(this.croppedImage);
  }

  get cropperRatio() {
    return this.mode ? '16 / 9' : '9 / 16';
  }

  get cropperWidth() {
    return this.mode ? 1024 : 425;
  }

  imageLoaded(event: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
}
