import { Injectable, EventEmitter, Output } from '@angular/core';
import { Layout } from './../_models/layout';
import { CurrentUser, User, Products } from './../_models/data';
import { SaveDate } from '../_models/saveDate';
import { Order } from '../_models/order';
import { Plan } from '../_models/plan';
import { ICoupleInformation } from '../_interface/coupleInformation';
import { CoupleInformation } from '../_models/coupleInformation';
import { Cashout } from '../_models/cashout';
import { Credit } from '../_models/credit';
import { Authorization } from '../_models/authorization';
import { IAuthorization } from '../_interface/authorization';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private layout: Layout = new Layout();
  @Output() coupleEmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() onboardingEmitter: EventEmitter<string> = new EventEmitter();
  @Output() itemDeletionEmitter: EventEmitter<string> = new EventEmitter();

  constructor(
    private router: Router
  ) {
    let currenUser = localStorage.getItem('currentUser');
    if (currenUser) {
      currenUser = this.decrypt(currenUser);
      this.setCurrentUser(new CurrentUser(currenUser));
    }
  }

  get settings() {
    return this.layout.settings;
  }

  set currency(currency: string) {
    this.settings.currency = currency;
  }

  set currencyChange(currencyChange: number) {
    this.settings.currencyChange = currencyChange;
  }

  get sura() {
    return this.layout.sura;
  }

  get cart() {
    return this.layout.cart;
  }

  get cashout(): Cashout {
    return this.layout.cashout;
  }

  get credit(): Credit {
    return this.layout.credit;
  }


  activeMenuRedirect(menus: any[]) {
    if (menus.find((item: { _key: string; }) => item._key === 'WEB_SITE').active) {
      this.router.navigate(['/', this.layout.coupleInformation.info?.url, 'site']);
    } else if (menus.find((item: { _key: string; }) => item._key === 'GIFT_TABLE').active) {
      this.router.navigate(['/', this.layout.coupleInformation.info?.url, 'gift-table']);
    } else if (menus.find((item: { _key: string; }) => item._key === 'GUEST_BOOK').active) {
      this.router.navigate(['/', this.layout.coupleInformation.info?.url, 'guest-book']);
    } else if (menus.find((item: { _key: string; }) => item._key === 'QUEATIONS').active) {
      this.router.navigate(['/', this.layout.coupleInformation.info?.url, 'questions']);
    } else {
      this.router.navigate(['/search',]);
    }
  }

  redirect() {
    if (this.layout.authorization.verify('WEB_SITE')) {
      this.router.navigate(['/', this.layout.coupleInformation.info?.url, 'site']);
    } else if (this.layout.authorization.verify('GIFT_TABLE')) {
      this.router.navigate(['/', this.layout.coupleInformation.info?.url, 'gift-table']);
    } else if (this.layout.authorization.verify('GUEST_BOOK')) {
      this.router.navigate(['/', this.layout.coupleInformation.info?.url, 'guest-book']);
    } else if (this.layout.authorization.verify('SAVE_THE_DATE')) {
      this.router.navigate(['/', this.layout.coupleInformation.info?.url, 'guest-book']);
    } else if (this.layout.authorization.verify('QUEATIONS')) {
      this.router.navigate(['/', this.layout.coupleInformation.info?.url, 'questions']);
    } else {
      this.router.navigate(['/search',]);
    }
  }

  eventOnboarding(step: string) {
    this.onboardingEmitter.emit(step);
  }

  _setCurrentUser(currenUser: CurrentUser) {
    this.setCurrentUser(currenUser);
    localStorage.setItem('currentUser', this.encrypt(currenUser));
  }

  getCurrentUser(): CurrentUser {
    return this.layout.currentUser;
  }

  isGuest(): boolean {
    return this.getCurrentUser() && this.getCurrentUser().id ? false : true;
  }

  getUser(): any {
    return this.layout.currentUser ? this.layout.currentUser.user : this.layout.coupleInformation.info;
  }

  getProduct(idArticulo: string) {
    const itemList = this.layout.coupleInformation.info?.productsRegistryList;
    const item = itemList?.find(item => {
      return item.id == idArticulo;
    }) || null;
    return this.layout.currentUser && item ? item : new Products();
  }

  getProductos() {
    return this.layout.currentUser ? (this.layout.currentUser.user?.productsRegistryList || []) : new Products();
  }

  setCurrentUser(currentUser: CurrentUser) {
    this.layout.currentUser = currentUser;
  }

  setCoupleInformation(coupleInformation: ICoupleInformation | undefined) {
    this.layout.coupleInformation.info = coupleInformation;
    this.coupleEmitter.emit(true);
  }

  emitCoupleInformationUpdate() {
    this.coupleEmitter.emit(true);
  }

  getCoupleInformation(): ICoupleInformation | undefined {
    return this.layout.coupleInformation.info;
  }

  get coupleInformation(): CoupleInformation {
    return this.layout.coupleInformation;
  }

  get saveDate(): SaveDate {
    return this.layout.saveDate;
  }

  get order(): Order {
    return this.layout.order;
  }

  get plan(): Plan {
    return this.layout.plan;
  }

  get authorization(): Authorization {
    return this.layout.authorization;
  }

  decrypt(data: string): any {
    try {
      return JSON.parse(unescape(atob(atob(atob(data)))));
    } catch (e) {
      try {
        return atob(atob(atob(unescape(data))));
      } catch (e) {

      }
    }
  }

  encrypt(data: any): string {
    try {
      return btoa(btoa(btoa(escape(JSON.stringify(data)))));
    } catch (e) {
      try {
        return btoa(btoa(btoa(escape(data))));
      } catch (err) {
        return '';
      }
    }
  }

  clear() {
    this.layout.clear();
    this.layout = new Layout();
    localStorage.clear();
  }

  formatDateForSave(year,month,day,hour,minute,){
    const newDate = DateTime.now().toUTC().set({
      year: year,
      month: month,
      day: day,
      hour: hour,
      minute: minute,
      second:0,
      millisecond:0
    });
    return newDate.plus({hours:6});
  }

  formatDateForRead(stringDate, issue){ //issue flag shall only be shown, never used to save
    const gtmlDiff = issue?5:6;
    return DateTime.fromISO(stringDate).toUTC().minus({ hours: gtmlDiff });
  }

  formatJavaDateRead(stringDate, issue){ //issue flag shall only be shown, never used to save
    const gtmlDiff = issue?5:6;
    const baseIsoDate = DateTime.fromISO(stringDate).toUTC().minus({ hours: gtmlDiff });
    const {year,month, day, hour, minute} = baseIsoDate;
    const newDateTime = DateTime.now().set({year: year,month: month,day: day,hour: 6,minute: 0,second:0,millisecond:0});
    const preFormatJavaDate = new Date(newDateTime.toISO());
    const javaDate = new Date(preFormatJavaDate.setHours(hour,minute));

    return javaDate;
  }

  formatInputDatepicker(year,month, day, hour, minute){
    const newDateTime = DateTime.now().set({year: year,month: month,day: day,hour: 6,minute: 0,second:0,millisecond:0});
    const preFormatJavaDate = new Date(newDateTime.toISO());
    const javaDate = new Date(preFormatJavaDate.setHours(hour,minute));
    return javaDate;
  }

  emitItemDeletion(itemId) {
    this.itemDeletionEmitter.emit(itemId);
  }
}
