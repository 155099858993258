import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dynamic-steps',
  templateUrl: './dynamic-steps.component.html',
  styleUrls: ['./dynamic-steps.component.scss']
})
export class DynamicStepsComponent implements OnInit {
  @Input() step: number;
  @Input() totalSteps: number;
  arraySteps = Array;
  constructor() { }

  ngOnInit() {
  }

  getWidth() {
    return (100 / this.totalSteps) + '%';
  }

  getClass(index) {
    const _index = +index + 1;
    const _step = +this.step;
    if (_step == _index) {
      return 'active';
    } else
      if (_step > _index) {
        return 'done';
      } else {
        return 'active2';
      }
  }

}
