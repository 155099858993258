import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as Sentry from "@sentry/angular";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private toastyService: ToastrService
  ) {
  }

  info(title: string, message?: string) {
    this.toastyService.info(title, message);
  }

  success(title: string, message?: string) {
    this.toastyService.success(title, message);
  }

  error(title: string | unknown, message?: string) {
    Sentry.captureException({
      title,
      message
    });
    this.toastyService.error(<string>title, message);

  }

  warning(title: string, message?: string) {
    this.toastyService.warning(title, message);
  }
}
