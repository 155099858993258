<div class="container-fluid d-flex flex-column" style="min-height: 100vh;">
  <!--editor commands-->
  <div class="row">
    <div class="col-12 py-3 command-block">
      <div class="row justify-content-center">
        <div class="col-12 text-center commands hidden-mobile">
          <button class="back-button btn" (click)="prev()"><i class="fas fa-chevron-left"></i></button>
          <button class="txt-uniko btn" (click)="editDesing = false" [class.selected]='!editDesing'><i
              class="fas fa-align-justify"></i><span class="ml-3 hidden-mobile">Contenido</span></button>
          <button *ngIf="templateId != 1" class="txt-uniko btn" (click)="editDesing = true"
            [class.selected]='editDesing'><i class="fas fa-palette"></i><span
              class="ml-3 hidden-mobile">Diseño</span></button>
          <button class="txt-uniko btn" (click)="save()"><i class="fas fa-save"></i><span
              class="ml-3 hidden-mobile">Aplicar cambios</span></button>
        </div>
        <div class="col-12 text-center commands mobile show-mobile">
          <button class="back-button btn" (click)="prev()"><i class="fas fa-chevron-left"></i></button>
          <button class="txt-uniko btn" (click)="mobileActive = false" [class.selected]='!mobileActive'><i
              class="far fa-image"></i><span class="ml-3 hidden-mobile">Vista Previa</span></button>
          <button class="txt-uniko btn" (click)="editDesing = false; mobileActive = true;"
            [class.selected]='!editDesing && mobileActive'><i class="fas fa-align-justify"></i><span
              class="ml-3 hidden-mobile">Contenido</span></button>
          <button *ngIf="templateId != 1" class="txt-uniko btn" (click)="editDesing = true;  mobileActive = true;"
            [class.selected]='editDesing && mobileActive'><i class="fas fa-palette"></i><span
              class="ml-3 hidden-mobile">Diseño</span></button>
          <button class="txt-uniko btn" (click)="save()"><i class="fas fa-save"></i><span
              class="ml-3 hidden-mobile">Aplicar cambios</span></button>
        </div>
      </div>
    </div>
  </div>
  <!--Editor Content-->
  <div class="row" style="flex-grow: 1;">
    <!--Preview-->
    <div class="col-12 col-md-7">
      <div class="col-12">
        <app-tools class="my-3" [desktopMode]="templateObj.desktop" (modeEmiter)="changeMode($event)"></app-tools>
      </div>
      <div class="col-12 overflow-atuo p-0">
        <div class="editor-container" [class.mobile]="!mode">
          <!--Content-->
          <div class="content p-0 m-0">
            <app-templates [loadComplete]="loadComplete" [content]="templateObj" [templateId]="templateId">
            </app-templates>
          </div>
          <div class="editable p-3">
            <p class="pointer" [ngStyle]=" {
              'text-align': templateObj.title.textAlign,
              'color': templateObj.title.color,
              'font-family': templateObj.title.fontFamily,
              'font-weight': templateObj.title.fontWeight,
              'font-size': templateObj.title.fontSize,
              'text-transform': templateObj.title.textTransform,
              'font-style': templateObj.title.fontStyle,
              'letter-spacing': templateObj.title.latterSpace
            }" [class.selected]="templateObj.select == 'title'" (click)="changeInput('title')">
              {{ templateObj.title.text }}</p>
            <h1 class="pointer" [ngStyle]=" {
              'text-align': templateObj.nametitle.textAlign,
              'color': templateObj.nametitle.color,
              'font-family': templateObj.nametitle.fontFamily,
              'font-weight': templateObj.nametitle.fontWeight,
              'font-size': templateObj.nametitle.fontSize,
              'text-transform': templateObj.nametitle.textTransform,
              'font-style': templateObj.nametitle.fontStyle,
              'letter-spacing': templateObj.nametitle.latterSpace
            }" [class.selected]="templateObj.select == 'nametitle'" (click)="changeInput('nametitle')">
              {{ templateObj.nametitle.text }}</h1>
            <p class="mt-5 mb-3 pointer" [ngStyle]=" {
              'text-align': templateObj.titleDate.textAlign,
              'color': templateObj.titleDate.color,
              'font-family': templateObj.titleDate.fontFamily,
              'font-weight': templateObj.titleDate.fontWeight,
              'font-size': templateObj.titleDate.fontSize,
              'text-transform': templateObj.titleDate.textTransform,
              'font-style': templateObj.titleDate.fontStyle,
              'letter-spacing': templateObj.titleDate.latterSpace
            }" [class.selected]="templateObj.select == 'titleDate'" (click)="changeInput('titleDate')">
              {{ templateObj.titleDate.text }}</p>
            <h1 class="pointer" [ngStyle]=" {
              'text-align': templateObj.date.textAlign,
              'color': templateObj.date.color,
              'font-family': templateObj.date.fontFamily,
              'font-weight': templateObj.date.fontWeight,
              'font-size': templateObj.date.fontSize,
              'text-transform': templateObj.date.textTransform,
              'font-style': templateObj.date.fontStyle,
              'letter-spacing': templateObj.date.latterSpace
            }" [class.selected]="templateObj.select == 'date'" (click)="changeInput('date')">
              {{ templateObj.date.text }}</h1>
            <p class="m-0 mt-4 mb-5 pointer" [ngStyle]=" {
              'text-align': templateObj.titleDescription.textAlign,
              'color': templateObj.titleDescription.color,
              'font-family': templateObj.titleDescription.fontFamily,
              'font-weight': templateObj.titleDescription.fontWeight,
              'font-size': templateObj.titleDescription.fontSize,
              'text-transform': templateObj.titleDescription.textTransform,
              'font-style': templateObj.titleDescription.fontStyle,
              'letter-spacing': templateObj.titleDescription.latterSpace
            }" [class.selected]="templateObj.select == 'titleDescription'" (click)="changeInput('titleDescription')">
              {{ templateObj.titleDescription.text }}</p>
            <!-- <p class="m-0 p-0 mt-4"
            >Breve descripción</p> -->
            <p class="m-0 mx-auto pointer" [ngStyle]=" {
                'text-align': templateObj.description.textAlign,
                'color': templateObj.description.color,
                'font-family': templateObj.description.fontFamily,
                'font-weight': templateObj.description.fontWeight,
                'font-size': templateObj.description.fontSize,
                'text-transform': templateObj.description.textTransform,
                'font-style': templateObj.description.fontStyle,
                'letter-spacing': templateObj.description.latterSpace
              }" [class.selected]="templateObj.select == 'description'" (click)="changeInput('description')">
              {{ templateObj.description.text }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--Editor Tools-->
    <div class="col-12 col-md-5 content-edition pt-3" [class.mobile-active]="mobileActive">
      <div class="row">
        <!--Content Edition-->
        <div class="col-12 content" [class.hidden]="editDesing">
          <div class="content-edit editor-border">
            <div class="form-group text-left">
              <label class="label-control">Texto</label>
              <textarea type="name" style="min-height: 128px;" id="text" [(ngModel)]="model"
                (keyup)="changeText($event)" class="form-control b-none"
                placeholder="{{ templateObj[templateObj.select].text }}">
              </textarea>
            </div>
          </div>
          <div class="content-edit editor-border">
            <div class="row">
              <div class="col-12">
                <div class="form-group text-left">
                  <label class="label-control">Caracteristicas de texto</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label class="label-control labels-fonts">ESTILOS</label>
                <div
                  (click)="setEditText('fontWeight', templateObj[templateObj.select].fontWeight == 'bold' ? '200' :'bold')"
                  [class.active]="templateObj[templateObj.select].fontWeight == 'bold'" class="btn-text">
                  <i class="fas fa-bold"></i>
                </div>
                <div
                  (click)="setEditText('fontStyle', templateObj[templateObj.select].fontStyle == 'italic' ? 'normal' :'italic')"
                  [class.active]="templateObj[templateObj.select].fontStyle == 'italic'" class="btn-text">
                  <i class="fas fa-italic"></i>
                </div>
              </div>
              <div class="col-md-4">
                <label class="label-control labels-fonts">ALINEACIÓN</label>
                <div (click)="setEditText('textAlign', 'left')"
                  [class.active]="templateObj[templateObj.select].textAlign == 'left'" class="btn-text">
                  <i class="fas fa-align-left"></i>
                </div>
                <div (click)="setEditText('textAlign', 'center')"
                  [class.active]="templateObj[templateObj.select].textAlign == 'center'" class="btn-text">
                  <i class="fas fa-align-center"></i>
                </div>
                <div (click)="setEditText('textAlign', 'right')"
                  [class.active]="templateObj[templateObj.select].textAlign == 'right'" class="btn-text">
                  <i class="fas fa-align-right"></i>
                </div>
                <div (click)="setEditText('textAlign', 'justify')"
                  [class.active]="templateObj[templateObj.select].textAlign == 'justify'" class="btn-text">
                  <i class="fas fa-align-justify"></i>
                </div>
              </div>
              <div class="col-md-4">
                <label class="label-control labels-fonts">TIPO DE CAJA</label>
                <div (click)="setEditText('textTransform', 'Capitalize')"
                  [class.active]="templateObj[templateObj.select].textTransform == 'Capitalize'" class="btn-text">
                  <i>Aa</i>
                </div>
                <div (click)="setEditText('textTransform', 'uppercase')"
                  [class.active]="templateObj[templateObj.select].textTransform == 'uppercase'" class="btn-text">
                  <i>AA</i>
                </div>
                <div (click)="setEditText('textTransform', 'lowercase')"
                  [class.active]="templateObj[templateObj.select].textTransform == 'lowercase'" class="btn-text">
                  <i>aa</i>
                </div>
              </div>
            </div>
            <div class="row m-t-10">
              <div class="col-md-4">
                <label class="label-control labels-fonts black">Fuente</label>
                <select name="" [(ngModel)]="fontModel" (change)="setEditText('fontFamily', fontModel)"
                  class="form-control">
                  <option value="'Cardo', serif">Cardo</option>
                  <option value="'Sofia', cursive">Sofia cursive</option>
                  <option value="'Damion', cursive">Damion cursive</option>
                  <option value="'Norican', cursive">Norican cursive</option>
                  <option value="'Montserrat', sans-serif">Montserrat</option>
                </select>
              </div>
              <div class="col-md-4">
                <label class="label-control labels-fonts black">Tamaño</label>
                <select name="" [(ngModel)]="sizesModel" id="" (change)="setEditText('fontSize', sizesModel)"
                  class="form-control">
                  <option [value]="size+'pt'" *ngFor="let size of sizes">{{ size }}</option>
                </select>
              </div>
              <div class="col-md-4">
                <label class="label-control labels-fonts black">Letter spacing</label>
                <select name="" [(ngModel)]="latterSpaceModel" class="form-control"
                  (change)="setEditText('latterSpace', latterSpaceModel)">
                  <option [value]="letter+'px'" *ngFor="let letter of latterSpace">{{ letter }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="content-edit">
            <label class="label-control">COLORES DE TEXTO</label>
            <ul class="color-options">
              <li [style.background-color]="color.color" [class.hidden]="color.index == 5"
                *ngFor="let color of templateObj.backgroundTemplate; let i = index"
                (click)="setEditText('color',color.color)"></li>
              <li [style.background-color]="templateObj[templateObj.select].color"
                (click)="openColorLetter = !openColorLetter">
                <i class="fas fa-broom" style="margin-top: 5px;margin-left: 5px;"></i>
              </li>
              <div class="color-sketch" *ngIf="openColorLetter">
                <div class="close-sketch text-right" (click)="openColorLetter = false"><i class="fa fa-times"></i></div>
                <color-sketch color="{{templateObj[templateObj.select].color}}"
                  (onChangeComplete)="handleChangeCompleteLatter($event)"></color-sketch>
              </div>
            </ul>
          </div>
        </div>
        <!--Design Edition-->
        <div class="col-12 desing" [class.hidden]="!editDesing">
          <div class="row desing-option">
            <div class="col-12">
              <label>COLOR DE FONTO</label>
              <ul class="color-options">
                <li [class.option-selected]="selectedColor == _color.index" [style.background-color]="_color.color"
                  [class.hidden]="_color.index == 5" *ngFor="let _color of templateObj.colors; let i = index"
                  (click)="setColorBackground(_color.index)"></li>
                <li [class.option-selected]="selectedColor == 5" [style.background-color]="colorBackground"
                  (click)="openColor = !openColor">
                  <i class="fas fa-broom" style="margin-top: 5px;margin-left: 5px;"></i>
                </li>
                <div class="color-sketch" *ngIf="openColor">
                  <div class="close-sketch text-right" (click)="openColor = false"><i class="fa fa-times"></i></div>
                  <color-sketch [color]="colorBackground" (onChangeComplete)="handleChangeComplete($event)">
                  </color-sketch>
                </div>
              </ul>
            </div>
          </div>
          <div class="row desing-option">
            <div class="col-12">
              <label>COLOR DE DETALLE</label>
              <ul class="color-options">
                <li [class.option-selected]="selectedOrnate == color.index" [style.background-color]="color.color"
                  [class.hidden]="color.index == 5" *ngFor="let color of templateObj.backgroundTemplate; let i = index"
                  (click)="setColorOrnate(color.index)"></li>
                <li [class.option-selected]="selectedOrnate == 5" [style.background-color]="ornateColor"
                  (click)="openColorOrnate = !openColorOrnate">
                  <i class="fas fa-broom" style="margin-top: 5px;margin-left: 5px;"></i>
                </li>
                <div class="color-sketch" *ngIf="openColorOrnate">
                  <div class="close-sketch text-right" (click)="openColorOrnate = false"><i class="fa fa-times"></i>
                  </div>
                  <color-sketch [color]="ornateColor" (onChangeComplete)="handleChangeCompleteOrnate($event)">
                  </color-sketch>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>