<div class="nav" *ngIf="!off">
  <div class="container-fluid p-0 hidden-mobile">
    <div class="row justify-content-between m-0">
      <nav class="navbar col-12 col-lg-auto">
        <a *ngIf="!guest && !public" class="navbar-brand" routerLink="/site" routerLinkActive="active">
          <img src="{{publicAssets}}/uniko.png" class="d-inline-block align-top" alt="" style="height:30px;">
        </a>
        <a *ngIf="guest || public" class="navbar-brand" href="{{global}}">
          <img src="{{publicAssets}}/uniko.png" class="d-inline-block align-top" alt="" style="height:30px;">
        </a>
        <ul *ngIf="public" class="nav hidden-mobile align-items-center">
          <li class="nav-item nav-menu p-t-8">
            <a class="nav-link" [class.selected]="mapMenuSelected('SEARCH')" routerLink="/search"
              routerLinkActive="active">
              Busca una boda
            </a>
          </li>
          <li class="nav-item nav-menu p-t-8">
            <a class="nav-link " [class.selected]="mapMenuSelected('GUEST_PLANS')" href="{{global}}/precios/">
              Planes
            </a>
          </li>
          <li class="nav-item nav-menu p-t-8">
            <a class="nav-link" [class.selected]="mapMenuSelected('ONBOARDING')" routerLink="/onboarding/login"
              routerLinkActive="active">
              Iniciar Sesión
            </a>
          </li>
        </ul>
        <ul *ngIf="!guest && !public" class="nav hidden-mobile align-items-center">
          <li class="nav-item nav-menu p-t-8">
            <a class="nav-link" [class.inactive]="mapMenuInactive('WEB_SITE')"
              [class.selected]="mapMenuSelected('WEB_SITE')" routerLink="/site" routerLinkActive="active">
              {{couple.weddingData.type == 'WEDDING'?'Nuestro sitio':'Mi sitio'}}
            </a>
          </li>
          <li class="nav-item nav-menu p-t-8">
            <a class="nav-link inactive" [class.inactive]="mapMenuInactive('GIFT_TABLE')"
              [class.selected]="mapMenuSelected('GIFT_TABLE')" routerLink="/gift-table" routerLinkActive="active">
              Mesa de regalos
            </a>
          </li>
          <li class="nav-item nav-menu p-t-8" routerLinkActive="active">
            <a class="nav-link inactive" [class.inactive]="mapMenuInactive('GUEST_BOOK')"
              [class.selected]="mapMenuSelected('GUEST_BOOK')" routerLink="/guest-book" routerLinkActive="active">
              Guestbook
            </a>
          </li>
          <li class="nav-item nav-menu p-t-8" routerLinkActive="active">
            <a class="nav-link inactive" [class.inactive]="mapMenuInactive('QUESTIONS')"
              [class.selected]="mapMenuSelected('QUESTIONS')" routerLink="/questions" routerLinkActive="active">
              Preguntas frecuentes
            </a>
          </li>
        </ul>
        <ul *ngIf="guest && !public && couple" class="nav hidden-mobile align-items-center">
          <li class="nav-item special-item nav-menu p-t-8">
            <a *ngIf="isActive('WEB_SITE')" class="nav-link" [class.selected]="mapMenuSelected('WEB_SITE')"
              routerLink="/{{couple.url}}/site" routerLinkActive="active">
              {{title}}
            </a>
            <a *ngIf="!isActive('WEB_SITE')" class="nav-link inactive">
              {{title}}
            </a>
          </li>
          <li class="nav-item nav-menu p-t-8">
            <a *ngIf="isActive('GIFT_TABLE')" class="nav-link" [class.selected]="mapMenuSelected('GIFT_TABLE')"
              routerLink="/{{couple.url}}/gift-table">
              Mesa de regalos
            </a>
            <a *ngIf="!isActive('GIFT_TABLE')" class="nav-link inactive">
              Mesa de regalos
            </a>
          </li>
          <li class="nav-item nav-menu p-t-8" routerLinkActive="active">
            <a *ngIf="isActive('GUEST_BOOK')" class="nav-link" [class.selected]="mapMenuSelected('GUEST_BOOK')"
              routerLink="/{{couple.url}}/guest-book">
              Guestbook
            </a>
            <a *ngIf="!isActive('GUEST_BOOK')" class="nav-link inactive">
              Guestbook
            </a>
          </li>
          <li class="nav-item nav-menu p-t-8" routerLinkActive="active">
            <a *ngIf="isActive('QUESTIONS')" class="nav-link" [class.selected]="mapMenuSelected('QUESTIONS')"
              routerLink="/{{couple.url}}/questions">
              Preguntas frecuentes
            </a>
            <a *ngIf="!isActive('QUESTIONS')" class="nav-link inactive">
              Preguntas frecuentes
            </a>
          </li>
          <li class="nav-item nav-menu p-t-8" routerLinkActive="active">
            <a class="nav-link" routerLink="/search" routerLinkActive="active">
              Buscar otra boda
            </a>
          </li>
        </ul>
      </nav>
      <nav class="navbar col-12 col-lg-auto justify-content-end">
        <ul *ngIf="!guest && !public" class="nav hidden-mobile align-items-center">
          <li class="nav-item nav-menu p-t-8" routerLinkActive="active">
            <a class="nav-link" href="/save-date" [class.inactive]="mapMenuInactive('SAVE_DATE')"
              [class.selected]="mapMenuSelected('SAVE_DATE')" routerLink="/save-date" routerLinkActive="active">
              Save the date
            </a>
          </li>
          <li class="nav-item nav-menu dropdown drowp p-t-8" *ngIf="couple.weddingData.type == 'WEDDING'">
            <a class="nav-link dropdown-toggle" id="navbarDropdown" routerLinkActive="active" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              Wedding Tools
              <div class="dropdown-menu dropsub" style="right: 0 !important;left: initial"
                aria-labelledby="navbarDropdown">
                <a class="dropdown-item" [class.inactive]="mapMenuInactive('GUEST_CONFIRMATION')"
                  [class.selected]="mapMenuSelected('GUEST_CONFIRMATION')"
                  (click)="goUrlTools($event, 'GUEST_CONFIRMATION')">Confirmación de invitados</a>
                <a class="dropdown-item" [class.inactive]="mapMenuInactive('DOMINE')"
                  [class.selected]="mapMenuSelected('DOMINE')" (click)="goUrlTools($event, 'DOMINE')">Solicitud de
                  dominio</a>
                <a class="dropdown-item" target="_blank" href="https://www.youtube.com/@unikomesaderegalos458/videos">Wedding Academy</a>
                <a class="dropdown-item" [class.inactive]="mapMenuInactive('TICKET')"
                  [class.selected]="mapMenuSelected('TICKET')" (click)="goUrlTools($event, 'TICKET')">Boletos para
                  invitaciones</a>
              </div>
            </a>
          </li>
          <i class="nav-item nav-menu dropdown drowp">
            <button class="nav-link" [class.selected]="mapMenuSelected('PROFILE')"
              style="border:0px; outline:0px !important; font-size: 14px;background: white;">
              <i class="fas fa-user-alt bg-uniko userIco"></i> {{ title }}
              <i class="fas fa-angle-down"></i>
            </button>
            <div class="dropdown-menu dropsub" style="right: 0 !important;left: initial"
              aria-labelledby="navbarDropdown">
              <a class="dropdown-item" routerLink="/profile/datos-generales" routerLinkActive="active"
                class="dropdown-item">
                {{couple.weddingData.type == 'WEDDING'?'Nuestros datos':'Mis datos'}}</a>
              <a class="dropdown-item" routerLink="/profile/regalos-recibidos" routerLinkActive="active">
                Regalos recibidos</a>
              <a class="dropdown-item" routerLink="/profile/retiro-fondos" routerLinkActive="active">
                Retiro de fondos</a>
              <a class="dropdown-item" routerLink="/profile/recomendar-amigo" routerLinkActive="active"
                *ngIf="couple.weddingData.type == 'WEDDING'">
                Recomendar a un amigo</a>
              <a class="dropdown-item" routerLink="/profile/planes" routerLinkActive="active">
                Cuenta</a>
              <a class="dropdown-item" (click)="logout($event)">
                Cerrar sesión</a>
            </div>
          </i>
        </ul>
        <ul *ngIf="guest && !public" class="nav hidden-mobile align-items-center">
          <li class="nav-item active">
            <button *ngIf="!bolsaCompra" class="nav-link" mat-button
              style="border:0px; outline:0px !important; font-size: 14px;">
              <i class="fas fa-shopping-bag text-dark userIco" style="margin-right: 10px;"></i>
            </button>
            <button (click)="isOpenCart = !isOpenCart" *ngIf="bolsaCompra" class="nav-link" mat-button
              style="border:0px; outline:0px !important; font-size: 14px;">
              <i class="fas fa-shopping-bag text-dark bg-unikoClaro userIco" style="margin-right: 10px;"></i>
              <span class="txt-uniko" style="font-size: 13px;">{{ totalItems }}</span>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <div class="container-fluid p-0 show-mobile">
    <div class="row justify-content-between m-0" style="align-items: stretch;">
      <nav class="navbar col-12">
        <div class="col-auto">
          <a *ngIf="!guest && !public" class="navbar-brand" routerLink="/site" routerLinkActive="active">
            <img src="{{publicAssets}}/logo.png" width="120" height="30" alt="" style="height:30px;">
          </a>
          <a *ngIf="guest || public" class="navbar-brand" href="{{global}}">
            <img src="{{publicAssets}}/logo.png" width="120" height="30" alt="" style="height:30px;">
          </a>
        </div>
        <div class="col-auto align-self-center">
          <i (click)="isOpenMenu = true" class="material-icons" style="vertical-align: middle;">
            dehaze
          </i>
        </div>
      </nav>
    </div>
    <div class="menu-white p-0" *ngIf="isOpenMenu">
      <div class="row justify-content-between m-0">
        <nav class="navbar col-12">
          <div class="col-auto">
            <a *ngIf="!guest && !public" class="navbar-brand" routerLink="/site" routerLinkActive="active">
              <img src="{{publicAssets}}/logo.png" width="120" height="30" alt="" style="height:30px;">
            </a>
            <a *ngIf="guest || public" class="navbar-brand" href="{{global}}">
              <img src="{{publicAssets}}/logo.png" width="120" height="30" alt="" style="height:30px;">
            </a>
          </div>
          <div class="col-auto align-self-center">
            <button (click)="isOpenMenu = false" class="btn m-0 p-0" type="button">
              <i class="material-icons" style="vertical-align: middle;">
                clear
              </i>
            </button>
          </div>
        </nav>
      </div>
      <div class="row">
        <div class="col-12 p-0" *ngIf="public">
          <nav class="navbar navbar-light m-content">
            <a href="" (click)="isOpen = false" routerLink="/search" class="nav-link color-b"
              [class.selected]="mapMenuSelected('SEARCH')">
              Buscar boda
            </a>
          </nav>
          <nav class="navbar navbar-light">
            <a href="{{global}}/precios" class="nav-link color-b">
              Planes
            </a>
          </nav>
          <nav class="navbar navbar-light">
            <a href="" (click)="isOpen = false" routerLink="/onboarding/login" class="nav-link color-b">
              Iniciar Sesión
            </a>
          </nav>
        </div>
        <div class="col-12 p-0" *ngIf="!guest && !public">
          <nav class="navbar navbar-light m-content">
            <a href="" (click)="isOpen = false" routerLink="/site" class="nav-link color-b"
              [class.inactive]="mapMenuInactive('WEB_SITE')" [class.selected]="mapMenuSelected('WEB_SITE')">
              {{couple.weddingData.type == 'WEDDING'?'Nuestro sitio':'Mi sitio'}}
            </a>
          </nav>
          <nav class="navbar navbar-light">
            <a href="" (click)="isOpen = false" routerLink="/gift-table" class="nav-link color-b inactive"
              [class.inactive]="mapMenuInactive('GIFT_TABLE')" [class.selected]="mapMenuSelected('GIFT_TABLE')">
              Mesa de regalos
            </a>
          </nav>
          <nav class="navbar navbar-light">
            <a href="" (click)="isOpen = false" routerLink="/guest-book" class="nav-link color-b inactive"
              [class.inactive]="mapMenuInactive('GUEST_BOOK')" [class.selected]="mapMenuSelected('GUEST_BOOK')">
              Guestbook
            </a>
          </nav>
          <nav class="navbar navbar-light">
            <a href="" (click)="isOpen = false" routerLink="/questions" class="nav-link color-b inactive"
              [class.inactive]="mapMenuInactive('QUESTIONS')" [class.selected]="mapMenuSelected('QUESTIONS')">
              Preguntas frecuentes
            </a>
          </nav>
          <div class="menu-divition"></div>
          <nav class="navbar navbar-light">
            <a href="" (click)="isOpen = false" routerLink="/save-date" class="nav-link color-b"
              [class.inactive]="mapMenuInactive('SAVE_DATE')" [class.selected]="mapMenuSelected('SAVE_DATE')">
              Save the date
            </a>
          </nav>
          <div class="menu-divition"></div>
          <nav class="navbar navbar-light" *ngIf="couple.weddingData.type == 'WEDDING'">
            <div class="p-t-8">
              <a class="nav-link color-b" (click)="goUrl($event, 'GUEST_CONFIRMATION')"
                  [class.inactive]="mapMenuInactive('GUEST_CONFIRMATION')"
                  [class.selected]="mapMenuSelected('GUEST_CONFIRMATION')">
                  Confirmación de invitados
                </a>
                <a class="nav-link color-b" (click)="goUrl($event, 'DOMINE')"
                  [class.inactive]="mapMenuInactive('DOMINE')" [class.selected]="mapMenuSelected('DOMINE')">
                  Solicitud de dominio
                </a>
                <a class="nav-link color-b" target="_blank" href="https://www.youtube.com/@unikomesaderegalos458/videos">
                  Wedding Academy
                </a>
                <a class="nav-link color-b" (click)="goUrl($event, 'TICKET')"
                  [class.inactive]="mapMenuInactive('TICKET')" [class.selected]="mapMenuSelected('TICKET')">
                  Boletos para invitaciones
                </a>
            </div>
          </nav>
          <div class="menu-divition"></div>
          <nav class="navbar navbar-light" *ngIf="couple.weddingData.type == 'WEDDING'">
            <div class="p-t-8">
              <button class="nav-link btn-mobile" [class.selected]="mapMenuSelected('PROFILE')">
                <i class="fas fa-user-alt bg-uniko userIco m-r-10"></i> {{ title }}
              </button>
              <a (click)="isOpenMenu = false" class="nav-link color-b" routerLink="/profile/datos-generales"
                routerLinkActive="active">Nuestros datos</a>
              <a (click)="isOpenMenu = false" class="nav-link color-b" routerLink="/profile/regalos-recibidos"
                routerLinkActive="active">Regalos recibidos</a>
              <a (click)="isOpenMenu = false" class="nav-link color-b" routerLink="/profile/retiro-fondos"
                routerLinkActive="active">Retiro de fondos</a>
              <a (click)="isOpenMenu = false" class="nav-link color-b" routerLink="/profile/planes"
                routerLinkActive="active">Cuenta</a>
              <button style="border:0px; outline:0px !important; background: white;" class="nav-link color-b" (click)="logout($event)">Cerrar sesión</button>
            </div>
          </nav>
        </div>
        <div class="col-12 p-0" *ngIf="guest && !public">
          <nav class="navbar navbar-light m-content special-item">
            <a *ngIf="isActive('WEB_SITE')" href="" (click)="isOpen = false" routerLink="/{{couple.url}}/site"
              class="nav-link color-b" [class.selected]="mapMenuSelected('WEB_SITE')">
              {{title}}
            </a>
            <a *ngIf="!isActive('WEB_SITE')" class="nav-link color-b special-item inactive"
              [class.selected]="mapMenuSelected('WEB_SITE')">
              {{title}}
            </a>
          </nav>
          <nav class="navbar navbar-light">
            <a *ngIf="isActive('GIFT_TABLE')" href="" (click)="isOpen = false" routerLink="/{{couple.url}}/gift-table"
              class="nav-link color-b" [class.selected]="mapMenuSelected('GIFT_TABLE')">
              Mesa de regalos
            </a>
            <a *ngIf="!isActive('GIFT_TABLE')" class="nav-link color-b inactive"
              [class.selected]="mapMenuSelected('GIFT_TABLE')">
              Mesa de regalos
            </a>
          </nav>
          <nav class="navbar navbar-light">
            <a *ngIf="isActive('GUEST_BOOK')" href="" (click)="isOpen = false" routerLink="/{{couple.url}}/guest-book"
              class="nav-link color-b" [class.selected]="mapMenuSelected('GUEST_BOOK')">
              Guestbook
            </a>
            <a *ngIf="!isActive('GUEST_BOOK')" class="nav-link color-b inactive"
              [class.selected]="mapMenuSelected('GUEST_BOOK')">
              Guestbook
            </a>
          </nav>
          <nav class="navbar navbar-light">
            <a *ngIf="isActive('QUESTIONS')" href="" (click)="isOpen = false" routerLink="/{{couple.url}}/questions"
              class="nav-link color-b" [class.selected]="mapMenuSelected('QUESTIONS')">
              Preguntas frecuentes
            </a>
            <a *ngIf="!isActive('QUESTIONS')" class="nav-link color-b inactive"
              [class.selected]="mapMenuSelected('QUESTIONS')">
              Preguntas frecuentes
            </a>
          </nav>
          <div class="menu-divition"></div>
          <nav class="navbar navbar-light">
            <button *ngIf="!bolsaCompra" class="nav-link" mat-button
              style="border:0px; outline:0px !important; font-size: 14px;">
              <i class="fas fa-shopping-bag text-dark userIco" style="margin-right: 10px;"></i>
            </button>
            <button (click)="isOpenCart = !isOpenCart; isOpenMenu=false;" *ngIf="bolsaCompra" class="nav-link"
              mat-button style="border:0px; outline:0px !important; font-size: 14px;">
              <i class="fas fa-shopping-bag text-dark bg-unikoClaro userIco" style="margin-right: 10px;"></i>
              <span class="txt-uniko" style="font-size: 13px;">{{ totalItems }}</span>
            </button>
          </nav>
          <div class="menu-divition"></div>
          <nav class="navbar navbar-light">
            <a class="nav-link color-b" routerLink="/search" routerLinkActive="active">
              Buscar otra boda
            </a>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="nav" *ngIf="off">
  <div class="container-fluid p-0">
    <div class="row justify-content-between m-0" style="align-items: stretch;">
      <nav class="navbar col-12">
        <div class="col-auto">
          <a class="navbar-brand" routerLink="/site" routerLinkActive="active">
            <img src="{{publicAssets}}/uniko.png" class="d-inline-block align-top" alt="" style="height:30px;">
          </a>
        </div>
      </nav>
    </div>
  </div>
</div>
<app-modal-cart *ngIf="guest && !public && !off" (onAction)="onClose()" [isOpen]="isOpenCart">
  <div class="" *ngIf="!emptyProduct()">
    <div class="row title-cart mb-3">
      Agregado al carrito
    </div>
    <div class="row art">
      <div class="image-cart col-12 col-lg-4">
        <img class="image-cart-content" [src]="cart.product.image" alt="">
      </div>
      <div class="text-description col">
        <div class="descrip">
          <b>{{ cart?.product?.name }}</b>
        </div>
        <hr>
        <div class="description mb-3">
          {{ cart?.product?.description }}
        </div>
        <div class="price notranslate">
          {{ formatPrice(cart?.product) | currency:currency }}
        </div>
      </div>
    </div>
    <div class="row btn-modal-cart">
      <div class="hidden-md col-12 col-lg-4"></div>
      <div class="col-12 col-lg mt-4 text-center">
        <button class="btn btn-uniko w-100" routerLink="/{{couple.url}}/checkout/cart">
          Ver carrito de compras
        </button>
      </div>
    </div>
  </div>
  <div class="" *ngIf="emptyProduct() && !emptyProductList()">
    <div class="row title-cart">
      Tu carrito
    </div>
    <div class="row art" *ngFor="let item of cart.products">
      <div class="image-cart col-12 col-lg-4">
        <img class="image-cart-content" [src]="item.image" alt="">
      </div>
      <div class="text-description">
        <div class="descrip">
          <b>{{ item.name }}</b>
        </div>
        <hr>
        <div class="description mb-3">
          {{ item.description }}
        </div>
        <div class="price notranslate">
          {{ formatPrice(item) | currency:currency }}
        </div>
      </div>
    </div>
    <div class="row total">
      <div class="price text-center notranslate">
        <b>Total: {{ totalAmmount | currency:currency }} </b>
      </div>
    </div>
    <div class="row btn-modal-cart">
      <div class="hidden-md col-12 col-lg-4"></div>
      <div class="col-12 col-lg mt-4 text-center">
        <button class="btn btn-uniko w-100" routerLink="/{{couple.url}}/checkout/cart">
          Ver carrito de compras
        </button>
      </div>
    </div>
  </div>
  <div class="" *ngIf="emptyProduct() && emptyProductList()">
    <div class="">
      Tu carrito esta vacío
    </div>
  </div>
</app-modal-cart>