<nav class="navbar navbar-light bg-white m-conten">
  <a class="navbar-brand" href="#">
    <img src="./../../../assets/img/uniko.png" class="d-inline-block align-top" alt="" style="height:30px;">
  </a>
</nav>
<div *ngIf="!complete && plan">
  <div class="container pt-4">
    <div class="row justify-content-center mb-4">
      <div class="col-12 col-md-8 text-center">
        <h3 class="info">¡Estas a un paso de ser parte de la experiencia Uniko!</h3>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <div class="row">
          <div class="col-12 col-sm-4 offset-lg-1 text-center text-md-right mb-4">
            <h5 class="title block">Van a comprar</h5>
            <h5 class="small info block">Plan {{plan.name}}</h5>
            <h5 class="info block lowercase discount" *ngIf="couponActive" [class.discount]="couponActive">
              {{oldAmount | currency:'MXN' }} {{plan.currency}}</h5>
            <h4 class="title block lowercase">{{getPaymentLabel() | currency:'MXN' }}
              {{plan.currency}}<span>{{extraLabel}}</span></h4>
          </div>
          <div class="col-12 col-md-8 col-lg-6">
            <form [formGroup]="accountForm" *ngIf="!isLogged">
              <div class="row">
                <div class="col-12">
                  <h4 class="form-title info">1. Crear o entra a tu cuenta</h4>
                  <div class="form-group">
                    <label class="label-control">Email</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <i class="fas fa-envelope"></i>
                        </div>
                      </div>
                      <input formControlName="email" type="email" class="form-control"
                        placeholder="ejemplo@uniko.com.mx" (change)="verifyEmailRegistry()">
                    </div>
                    <div *ngIf="getEmailErrors() && (accountForm.touched || accountForm.dirty)"
                      class="alert mini-alert mini-danger">
                      {{getEmailErrors()}}
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="label-control">Password</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <i class="fas fa-lock"></i>
                        </div>
                      </div>
                      <input formControlName="password" type="password" class="form-control"
                        placeholder="Escribe tu contraseña">
                    </div>
                  </div>
                  <div class="form-group" *ngIf="!isRegistered">
                    <label class="label-control">Teléfono</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <i class="fas fa-phone"></i>
                        </div>
                      </div>
                      <input formControlName="phone" type="text" maxlength="10" minlength="10" class="form-control"
                        placeholder="0000000000">
                    </div>
                  </div>
                  <div class="form-group" *ngIf="!isRegistered">
                    <label class="label-control">País</label>
                    <div class="input-group">
                      <select class="form-control" formControlName="country">
                        <option *ngFor="let _country of countries" [value]="_country.phone">{{ _country.name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group text-center text-md-left">
                    <button class="btn btn-uniko col col-md-8" *ngIf="isRegistered" (click)="accountLogin()">Entrar a mi
                      cuenta</button>
                    <button class="btn btn-uniko col col-md-8" *ngIf="!isRegistered" (click)="accountRegister()">Crear
                      mi cuenta</button>
                  </div>
                </div>
                <div class="line-uniko"></div>
              </div>
            </form>
            <form [formGroup]="paymentOptionsForm" *ngIf="isLogged" class="row">
              <h4 class="form-title col-12 info">2. Paga tu cuenta</h4>
              <div class="form-group col-12">
                <label class="label-control">Ingresa un codigo de descuento</label>
                <div class="input-group">
                  <input formControlName="discountCode" type="text" class="form-control">
                  <div class="input-group-append">
                    <button [disabled]="couponActive || installmentsActive" class="btn btn-outline-uniko"
                      (click)="validateCode()" type="button"><i class="fas fa-percentage"></i></button>
                  </div>
                </div>
              </div>
              <div class="form-group m-0 col-12">
                <ngx-slider class="slider-uniko" formControlName="installments" [options]="sliderOptions"
                  (userChangeEnd)="onUserChangeEnd($event)"></ngx-slider>
                <div *ngIf="!couponActive" class="title text-uniko italic mb-2 mt-2">
                  {{sliderMessages[paymentOptionsForm.value.installments]}}</div>
                <div *ngIf="couponActive" class="info italic mb-2 mt-2">*No es posible diferir pagos con descuento</div>
              </div>
            </form>
            <form [formGroup]="paymentForm" (ngSubmit)="planRegister()" *ngIf="isLogged">
              <div class="row">
                <div class="col-12">
                  <div class="form-group text-left">
                    <label class="label-control">Nombre de la tarjeta</label>
                    <input type="text" class="form-control" formControlName="nameCard">
                  </div>
                  <div class="form-group text-left">
                    <label class="label-control">Número de la tarjeta</label>
                    <input (keyup)="validCard()" type="text" class="form-control" formControlName="numberCard">
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-8">
                      <label class="label-control">Fecha de vencimiento</label>
                    </div>
                    <div class="col-4">
                      <label class="label-control">CVC</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <div class="form-group text-left">
                        <select formControlName="exp_month" class="form-control small-input" name="" id="">
                          <option value="">Mes</option>
                          <option [value]="_month" *ngFor="let _month of months">{{ _month }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group text-left">
                        <select formControlName="exp_year" class="form-control small-input" name="" id="">
                          <option value="">Año</option>
                          <option [value]="_year" *ngFor="let _year of years">{{ _year }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group text-left">
                        <input [maxlength]="type.cvc" [minlength]="type.cvc" formControlName="cvc" type="password"
                          class="form-control">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group text-left text-uniko underline-uniko">
                    <mat-checkbox formControlName="acceptTerms" disableRipple='true' class="check-uniko">Acepto términos
                      y condiciones</mat-checkbox>
                  </div>
                </div>
              </div>
              <div class="row pr-2">
                <div class="col-12">
                  <div class="form-group text-center text-md-left">
                    <button class="btn btn-uniko col col-md-8">Pagar</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="complete">
  <div class="container pb-4">
    <div class="row justify-content-center text-center mt-4 pt-4">
      <div class="col-12 col-md-8 mb-2">
        <h2 class="info">Pago exitoso</h2>
        <div class="success-icon">
          <i class="fas fa-check-circle"></i>
        </div>
      </div>
      <div class="col-12 col-md-8 mb-4">
        <h3 class="text-uniko">Mi plan contratado</h3>
        <h4 class="info mb-2">{{plan.name}}</h4>
        <h5 class="mb-2">Monto pagado: {{plan.amount | currency:'MXN' }} {{plan.currency}}</h5>
        <h4 class="info mb-2">{{boughtPlan.type}}: ***{{boughtPlan.lastCard}}</h4>
      </div>
      <div class="col-12 col-md-8 text-center">
        <button class="btn btn-uniko pl-4 pr-4" (click)="goToAccount()">Ir a mi cuenta</button>
      </div>
    </div>
  </div>
</div>