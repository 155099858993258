import { Component, OnInit, Input } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from 'src/app/services/account.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ICoupleInformation } from 'src/app/_interface/coupleInformation';


@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  dataForm: FormGroup;
  @Input() couple: ICoupleInformation;
  isOpen: boolean = false;
  submitted: boolean = false;
  quickLock: boolean = false;
  @Input() isGuest: boolean = false;
  constructor(
    private layoutService: LayoutService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private coupleService: AccountService,
    private notification: NotificationService,
  ) {
  }

  ngOnInit() {
    this.isGuest = this.layoutService.isGuest();
    this.couple = this.layoutService.getCoupleInformation();
    this.layoutService.coupleEmitter.subscribe(data => {
      this.couple = this.layoutService.getCoupleInformation();
      this.defaultValues();
    });
    this.onForm();
    this.defaultValues();
  }

  async delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async updateView() {
    try {
      this.spinner.show();
      this.quickLock = true;
      let consult = await this.coupleService.updateSiteView(this.couple.id, { viewHistory: 1 });
      if (consult == "ok") {
        this.couple.viewHistory = !this.couple.viewHistory;
        this.layoutService.coupleInformation.info = this.couple;
      }
      this.quickLock = false;
    } catch (e) {
      this.notification.error(e);
    } finally {
      this.spinner.hide();
    }
  }

  onForm() {
    this.dataForm = this.formBuilder.group({
      detail: [this.couple.weddingData.detailHistory, Validators.required],
      title: [this.couple.weddingData.titleHistory, Validators.required],
      messageHistory: [this.couple.weddingData.messageHistory, Validators.required]
    })
  }

  defaultValues() {
    let title = '';
    let history = '';
    let message = '';
    switch (this.couple.weddingData.type) {
      case 'BABY':
        title = 'Título de tu historia';
        history = 'Mi Historia';
        message = ' Tus invitados amarán saber todos los detalles acerca de tu nuevo bebé.';
        break;
      default: 
        title = 'Título de su historia';
        history = 'Nuestra historia';
        message = ' Sus invitados amarán saber todos los detalles acerca de su LOVE STORY y como llegaron hasta este día.';
    }
    this.couple.weddingData.detailHistory = this.couple.weddingData.detailHistory ?
      this.couple.weddingData.detailHistory : history;
    this.couple.weddingData.titleHistory = this.couple.weddingData.titleHistory ?
      this.couple.weddingData.titleHistory : title;
    this.couple.weddingData.messageHistory = this.couple.weddingData.messageHistory ?
      this.couple.weddingData.messageHistory : message;
  }

  edit() {
    this.isOpen = true;
  }

  async update(success?: string): Promise<void> {
    try {
      this.submitted = false;
      if (this.dataForm.invalid) {
        return;
      }
      this.spinner.show();
      const couple = this.layoutService.getCoupleInformation();
      couple.weddingData.titleHistory = this.dataForm.value.title;
      couple.weddingData.detailHistory = this.dataForm.value.detail;
      couple.weddingData.messageHistory = this.dataForm.value.messageHistory;
      console.log(couple.weddingData);
      this.couple = await this.coupleService.updateRegister(this.couple);
      this.layoutService.setCoupleInformation(this.couple);
      this.isOpen = false;
      this.notification.success('Historia actualizada');
    } catch (e) {
      this.notification.error(e);
    } finally {
      this.spinner.hide();
    }
  }

  async updateLite(): Promise<void> {
    try {
      this.couple = await this.coupleService.updateRegister(this.couple);
      this.layoutService.setCoupleInformation(this.couple);
    } catch (e) {
      console.log(e);
    } finally {
    }
  }

}
