<div class="nav">
  <div class="row justify-content-between m-0">
    <nav class="navbar col-12 col-lg-auto">
      <a class="navbar-brand" href="https://global.uniko.co">
        <img src="./../../../assets/img/uniko.png" class="d-inline-block align-top" alt="" style="height:30px;">
      </a>
      <ul class="nav hidden-mobile align-items-center">
        <li class="nav-item nav-menu p-t-8">
          <a class="nav-link" [class.selected]="mapMenuSelected('SEARCH')" routerLink="/search"
            routerLinkActive="active">
            Busca una boda
          </a>
        </li>
        <li class="nav-item nav-menu p-t-8">
          <a class="nav-link " [class.selected]="mapMenuSelected('GUEST_PLANS')"
            href="https://global.uniko.co/precios/">
            Planes
          </a>
        </li>
        <li class="nav-item nav-menu p-t-8">
          <a class="nav-link" [class.selected]="mapMenuSelected('ONBOARDING')" routerLink="/onboarding/login"
            routerLinkActive="active">
            Iniciar Sesión
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>
<img src="./../../assets/img/foto.jpg" width="100%" alt="">
<div class="text-info-referal">
  <p>
    {{ fullname }}
  </p>
  <p>
    te acaba de regalar tu primer regalo de boda por $500
  </p>
  <p class="lowercase">
    Para recibirlos solo tienes que abrir tu mesa de regalos en www.uniko.co y convertir tus regalos en dinero en
    efectivo
  </p>
  <button class='btn btn-uniko' [queryParams]="{referido: name}" routerLink="/onboarding/register">REGISTRATE</button>
</div>